import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const fetchNavigatorList = (navigatorInstance: string, client: AxiosInstance): any =>
    client
        .get(`/Client?navigatorInstance=${navigatorInstance}`)
        .then((response: AxiosResponse<CONTRACTS.navigator[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const searchNavigator = (parameters: string, navigatorInstance: string, client: AxiosInstance): any =>
    client
        .get(`/Navigator/SearchForContacts?${objectToQuerystring(parameters)}`, {
            headers: { navigatorInstance: navigatorInstance }
        })
        .then((response: AxiosResponse<CONTRACTS.navigator[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const searchOtherNavigator = (parameters: string, navigatorInstance: string, client: AxiosInstance): any =>
    client
        .get(`/Navigator/SearchForContacts?${objectToQuerystring(parameters)}`, {
            headers: { navigatorInstance: navigatorInstance }
        })
        .then((response: AxiosResponse<CONTRACTS.navigator[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchNavigatorbyNHI = (id: string | number, navigatorInstance: string, client: AxiosInstance): any =>
    client
        .get(`/Navigator/GetByIdentifier?nhi=${id}`, {
            headers: { navigatorInstance: navigatorInstance }
        })
        .then((response: AxiosResponse<CONTRACTS.navigator>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeNavigator = (
    item: Partial<CONTRACTS.navigator>,
    navigatorInstance: string,
    client: AxiosInstance
): any => {
    if (item.contactId) {
        return client
            .put(`Navigator/Contact/${item.contactId}`, item, {
                headers: { navigatorInstance: navigatorInstance }
            })
            .then((response: AxiosResponse<CONTRACTS.navigator>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/Navigator/Contact`, item, {
                headers: { navigatorInstance: navigatorInstance }
            })
            .then((response: AxiosResponse<CONTRACTS.navigator>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};
export const getNavigatorInstanceList = (client: AxiosInstance): any =>
    client
        .get(`/Navigator/InstanceList`)
        .then(response => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

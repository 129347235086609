export const GREY_50 = 'rgba(0,0,0,0.5)';
export const GREY_30 = 'rgba(0,0,0,0.3)';
export const GREY_20 = 'rgba(0,0,0,0.2)';
export const GREY_10 = 'rgba(0,0,0,0.1)';

export const WHITE = 'rgb(255, 255,255)';
export const WHITE_50 = 'rgba(255, 255,255,0.5)';
export const WHITE_25 = 'rgba(255, 255,255,0.25)';

export const PRIMARY = '#219653';
export const BKGD = '#ffffff';

import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesInventory = (state: any): Partial<CONTRACTS.DeliveriesInventory> => {
    return state.deliveries.inventory.item || {};
};

export const getDeliveriesInventoryList = (state: any): CONTRACTS.DeliveriesInventory[] => {
    return state.deliveries.inventory.list || [];
};

export const getDeliveriesInventoryFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesInventoryFilters> => {
    return state.deliveries.inventory.filters || {};
};

export const getInventorySuccess = (state: any): boolean => {
    return state.deliveries.inventory.success || false;
};

import produce from 'immer';

import { updateArrayObjectWithObject } from '../../utils/update-arrayObject-with-object';
import { ChildHubState } from './';
import { ChildHubActionsUnion } from './ChildHub.actions';
import { OtherActionTypes } from './Other/actions';
import { State1ActionTypes } from './State-1/actions';
import { State2ActionTypes } from './State-2/actions';
import { State3ActionTypes } from './State-3/actions';
import { State4ActionTypes } from './State-4/actions';

export const INITIAL_State1_PROPERTIES: any = {
    item: {
        additionalNeed: '',
        childAge: '',
        citizenResident: '',
        area: '',
        moreServices: ''
    }
};
export const INITIAL_State2_PROPERTIES: any = {
    item: {
        additionalNeedSummary: ''
    },
    Wairua: {
        Wairua: '',
        WairuaDetails: ''
    },
    Hinengaro: {
        HinengaroSocial: '',
        HinengaroSocialDetail: '',
        HinengaroBehavior: '',
        HinengaroBehaviorDetail: '',
        HinengaroCommunication: '',
        HinengaroCommunicationDetail: ''
    },
    Tinana: {
        TinanaPhysical: '',
        TinanaPhysicalDetail: '',
        TinanaDevelopment: '',
        TinanaDevelopementDetail: '',
        TinanaLearning: '',
        TinanaLearningDetail: ''
    }
};
export const INITIAL_State3_PROPERTIES: any = {
    item: {
        Name: '',
        Phone: '',
        Email: '',
        Agency: '',
        Role: '',
        FirstName: '',
        LastName: '',
        DOB: '',
        Gender: '',
        Address1: '',
        ChildInterpreter: false,
        Living: '',
        Parent1Name: '',
        Parent1Relationship: '',
        Parent1Contact: '',
        Parent1Email: '',
        Parent1Address: '',
        Parent1Interpreter: false,
        Parent2Name: '',
        Parent2Relationship: '',
        Parent2Contact: '',
        Parent2Email: '',
        Parent2Address: '',
        Parent2Interpreter: false,
        NHI: '',
        language: '',
        culture: '',
        GP: '',
        NSN: '',
        OtherWhanau: '',
        EthnicityDetail: '',
        ChildInterpreterDetail: '',
        LivingDetail: '',
        SchoolName: '',
        SchoolContactName: '',
        SchoolContactNumber: '',
        SchoolContactEmail: '',
        SchoolAddress: '',
        Parent1InterpreterDetail: '',
        Parent2InterpreterDetail: ''
    }
};
export const INITIAL_State4_PROPERTIES: any = {
    item: {
        HealthService: false,
        Education: false,
        Community: false,
        Iwi: false,
        Other: false,
        Strength: '',
        Outcomes: '',
        Health: '',
        Barriers: '',
        Goals3: '',
        Goals6: '',
        Goals9: ''
    }
};
export const INITIAL_Other_PROPERTIES: any = {
    key: '',
    terms: false,
    activeStep: null,
    success: false
};

export const ChildHub_REDUCER_DEFAULT = {
    State_1: INITIAL_State1_PROPERTIES,
    State_2: INITIAL_State2_PROPERTIES,
    State_3: INITIAL_State3_PROPERTIES,
    State_4: INITIAL_State4_PROPERTIES,
    Other: INITIAL_Other_PROPERTIES
};

export const ChildHubReducer = (state: ChildHubState, action: ChildHubActionsUnion): ChildHubState =>
    produce(state || ChildHub_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case State1ActionTypes.SetState1Item:
                draft.State_1.item = action.payload.item;
                break;
            case State1ActionTypes.StoreItem:
                draft.State_1.item = action.payload;
                break;
            case State1ActionTypes.SetState1Image:
                draft.State_1.Img = action.payload;
                break;
            case State1ActionTypes.FetchItem:
                draft.State_1.item = action.payload.item;
                break;
            case State2ActionTypes.SetState2Item:
                draft.State_2.item = action.payload.item;
                break;
            case State2ActionTypes.SetState2_2Item:
                draft.State_2.Wairua = action.payload.item;
                break;
            case State2ActionTypes.SetState2_21Item:
                draft.State_2.Hinengaro = action.payload.item;
                break;
            case State2ActionTypes.SetState2_22Item:
                draft.State_2.Tinana = action.payload.item;
                break;
            case State2ActionTypes.SetState2Image:
                draft.State_2.Img = action.payload;
                break;
            case State2ActionTypes.SetState2_2Image:
                draft.State_2.Img2 = action.payload;
                break;
            case State2ActionTypes.SetState2_21Image:
                draft.State_2.Img21 = action.payload;
                break;
            case State2ActionTypes.SetState2_22Image:
                draft.State_2.Img22 = action.payload;
                break;
            case State2ActionTypes.StoreItem:
                draft.State_2.item = action.payload;
                break;
            case State2ActionTypes.FetchItem:
                draft.State_2.item = action.payload.item;
                break;
            case State3ActionTypes.SetState3Item:
                draft.State_3.item = action.payload.item;
                break;
            case State3ActionTypes.StoreItem:
                draft.State_3.item = action.payload;
                break;
            case State3ActionTypes.FetchItem:
                draft.State_3.item = action.payload.item;
                break;
            case State3ActionTypes.SetState3Image:
                draft.State_3.Img = action.payload;
                break;
            case State4ActionTypes.SetState4Item:
                draft.State_4.item = action.payload.item;
                break;
            case State4ActionTypes.SetState22ItemInState4:
                draft.State_4.WairuaItem = action.payload.item;
                break;
            case State4ActionTypes.SetState221ItemInState4:
                draft.State_4.HinengaroItem = action.payload.item;
                break;
            case State4ActionTypes.SetState222ItemInState4:
                draft.State_4.TinanaItem = action.payload.item;
                break;
            case State4ActionTypes.StoreReferralItem:
                draft.State_4.ReferralItem = action.payload;
                break;
            case State4ActionTypes.SetReferralItem:
                draft.State_4.ReferralItem = action.payload.item;
                break;
            case State4ActionTypes.SetImage:
                draft.State_4.Img = action.payload;
                break;
            case OtherActionTypes.StoreItem:
                draft.Other.item = action.payload;
                break;
            case OtherActionTypes.SetTerms:
                draft.Other.terms = action.payload.terms;
                break;
            case OtherActionTypes.SetActiveStep:
                draft.Other.activeStep = action.payload.activeStep;
                break;
            case OtherActionTypes.SetSuccess:
                draft.Other.success = action.payload.success;
                break;
            case OtherActionTypes.SetItem:
                draft.Other.key = action.payload.key;
                break;
            default:
                return draft;
        }
    });

import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesProvider = (state: any): Partial<CONTRACTS.DeliveriesProvider> => {
    return state.deliveries.provider.item || {};
};

export const getDeliveriesProviderList = (state: any): CONTRACTS.DeliveriesProvider[] => {
    return state.deliveries.provider.list || [];
};

export const getDeliveriesProviderFilters = (state: any): CONTRACTS.AllowedDeliveriesProviderFilters => {
    return state.deliveries.provider.filters || {};
};

export const getDeliveriesNavigatorInstanceListByProvider = (state: any): CONTRACTS.DeliveriesNavigatorInstance[] => {
    return state.deliveries.provider.item.instances || [];
};

export const getProviderSuccess = (state: any): boolean => {
    return state.deliveries.provider.success || false;
};

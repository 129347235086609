import { Action, ActionsUnion, ActionWithPayload, createAction } from '../../utils/action.helpers';
import * as CONTRACTS from './attachment.contracts';

/**
 * Actions Types
 */
export enum AttachmentActionTypes {
    FetchAttachmentList = 'attachment/fetchAttachmentList',
    SetAttachmentList = 'attachment/setAttachmentList',
    ClearAttachmentList = 'attachment/clearAttachmentList',
    FetchAttachment = 'attachment/fetchAttachment',
    SetAttachment = 'attachment/setAttachment',
    DeleteAttachment = 'attachment/deleteAttachment',
    StoreAttachment = 'attachment/storeAttachment',
    SetAttachmentFilters = 'attachment/setAttachmentFilters',
    StoreAttachmentFilters = 'attachment/storeAttachmentFilters',
    ClearAttachmentFilters = 'attachment/clearAttachmentFilters'
}

/**
 * Actions
 */
export const attachmentActions = {
    // retrieve Attachment items
    fetchAttachmentList: (): Action<AttachmentActionTypes.FetchAttachmentList> =>
        createAction(AttachmentActionTypes.FetchAttachmentList),

    // populate Attachment items
    setAttachmentList: (
        list: CONTRACTS.Attachment[]
    ): ActionWithPayload<AttachmentActionTypes.SetAttachmentList, { list: CONTRACTS.Attachment[] }> =>
        createAction(AttachmentActionTypes.SetAttachmentList, { list }),

    // retrieve Attachment item
    fetchAttachment: (id): any => createAction(AttachmentActionTypes.FetchAttachment, id),

    // populate Attachment item
    setAttachment: (item): ActionWithPayload<AttachmentActionTypes.SetAttachment, { item: CONTRACTS.Attachment }> =>
        createAction(AttachmentActionTypes.SetAttachment, { item }),

    // update Attachment item
    storeAttachment: (
        item: CONTRACTS.Attachment
    ): ActionWithPayload<AttachmentActionTypes.StoreAttachment, CONTRACTS.Attachment> =>
        createAction(AttachmentActionTypes.StoreAttachment, item),

    // clear Attachment list
    clearAttachmentList: (): Action<AttachmentActionTypes.ClearAttachmentList> =>
        createAction(AttachmentActionTypes.ClearAttachmentList),

    // populate Attachment filters
    setAttachmentFilters: (
        filters: Partial<CONTRACTS.AllowedAttachmentFilters>
    ): ActionWithPayload<AttachmentActionTypes.SetAttachmentFilters, CONTRACTS.AllowedAttachmentFilters> =>
        createAction(AttachmentActionTypes.SetAttachmentFilters, filters),

    storeAttachmentFilters: (
        filters: Partial<CONTRACTS.AllowedAttachmentFilters>
    ): ActionWithPayload<AttachmentActionTypes.StoreAttachmentFilters, CONTRACTS.AllowedAttachmentFilters> =>
        createAction(AttachmentActionTypes.StoreAttachmentFilters, filters),

    // clear Attachment filters
    clearAttachmentFilters: (): Action<AttachmentActionTypes.ClearAttachmentFilters> =>
        createAction(AttachmentActionTypes.ClearAttachmentFilters),

    //delete Attachment
    deleteAttachment: (id): any => createAction(AttachmentActionTypes.DeleteAttachment, id)
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type AttachmentActionsUnion = ActionsUnion<typeof attachmentActions>;

import * as CONTRACTS from '../deliveries.contracts';

/**
 * DeliveryType Selectors
 */
export const getDeliveriesDeliveryType = (state: any): Partial<CONTRACTS.DeliveriesDeliveryType> => {
    return state.deliveries.deliveryType.item || {};
};

export const getDeliveriesDeliveryTypeList = (state: any): CONTRACTS.DeliveriesDeliveryType[] => {
    return state.deliveries.deliveryType.list || [];
};

export const getDeliveriesDeliveryTypeFilters = (
    state: any
): Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters> => {
    return state.deliveries.deliveryType.filters || {};
};

export const getDeliveryTypeSuccess = (state: any): boolean => {
    return state.deliveries.deliveryType.success || false;
};

/**
 * Transform a single dimension object to a URL compatible query string
 */
export const objectToQuerystring = (values): string =>
    Object.entries(values)
        // if entry is not `null` or an empty string, let's use it
        .reduce((acc, x: any) => {
            // index not `total`
            x[0] !== 'total' &&
                // value not null
                x[1] &&
                // value not empty
                x[1] !== '' &&
                // push into accumulator
                acc.push(`${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`);
            return acc;
        }, [])
        .join('&');

import { IEffects, IFontStyles } from '@fluentui/react';
import { IPartialTheme } from '@uifabric/styling';

import Effects from './effects';
import Fonts from './fonts';
import Palette, { WDAPalette } from './palette';
import Spacing, { WDASpacing } from './spacing';

export interface WDATheme {
    palette: Partial<WDAPalette>;
    spacing: Partial<WDASpacing>;
    effects: Partial<IEffects>;
    fonts: Partial<IFontStyles>;
}

export const WhanauDeliveryTheme: WDATheme = {
    fonts: Fonts,
    palette: Palette,
    spacing: Spacing,
    effects: Effects
};

export default WhanauDeliveryTheme;

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

export const fetchDeliveryList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/delivery?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesDeliverySummary[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchDelivery = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/delivery/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesDelivery>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeDelivery = (item: Partial<CONTRACTS.DeliveriesDelivery>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/delivery/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesDelivery>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/delivery`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesDelivery>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteDelivery = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/delivery/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

export const syncWithRemoteSource = (
    filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters>,
    client: AxiosInstance
): any => {
    return client
        .post(`/deliveries/todo`, filters)
        .then((response: AxiosResponse<any>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });
};

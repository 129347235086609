// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
    production: false,
    api: {
        baseUrl: 'https://iwiregistrationtestapi.azurewebsites.net/api/v1'
    },
    analytics: {
        aiKey: '8800454c-125d-42b9-a7c7-1285b26826a5'
    }
};

export interface SerializerValue {
    _type_: SerializerValueType;
    _value_: any;
}

export enum SerializerValueType {
    Date = 'Date',
    Set = 'Set',
    Map = 'Map'
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const isSerializerValueObject = (object: object): object is SerializerValue => {
    return '_type_' in object && '_value_' in object;
};

import { all } from 'redux-saga/effects';

import { planCaseloadSagas } from './caseload/saga';
import { planCasenoteSagas } from './casenote/saga';

/**
 * Public Sagas
 */
export function* rootPlanSaga(): any {
    yield all([...planCaseloadSagas, ...planCasenoteSagas]);
}

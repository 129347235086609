import { IPalette } from '@uifabric/styling';

const palette: IPalette = {
    /**
     * Color code for themeDarker.
     */
    themeDarker: '#13542f',
    /**
     * Color code for themeDark.
     */
    themeDark: '#197240',
    /**
     * Color code for themeDarkAlt.
     */
    themeDarkAlt: '#1e874c',
    /**
     * Color code for themePrimary.
     */
    themePrimary: '#219653',
    /**
     * Color code for themeSecondary.
     */
    themeSecondary: '#33a364',
    /**
     * Color code for themeTertiary.
     */
    themeTertiary: '#66c08d',
    /**
     * Color code for themeLight.
     */
    themeLight: '#abe0c2',
    /**
     * Color code for themeLighter.
     */
    themeLighter: '#d1eedd',
    /**
     * Color code for themeLighterAlt.
     */
    themeLighterAlt: '#f3fbf6',
    /**
     * Color code for the strongest color, which is black in the default theme. This is a very light color in inverted themes.
     */
    black: '#332e27',
    /**
     * Color code for blackTranslucent40.
     */
    blackTranslucent40: '#b0aaa1',
    /**
     * Color code for neutralDark.
     */
    neutralDark: '#49433a',
    /**
     * Color code for neutralPrimary.
     */
    neutralPrimary: '#60584e',
    /**
     * Color code for neutralPrimaryAlt.
     */
    neutralPrimaryAlt: '#8d857a',
    /**
     * Color code for neutralSecondary.
     */
    neutralSecondary: '#a39c92',
    /**
     * Color code for neutralSecondaryAlt.
     */
    neutralSecondaryAlt: '#a39c92',
    /**
     * Color code for neutralTertiary.
     */
    neutralTertiary: '#bab3aa',
    /**
     * Color code for neutralTertiaryAlt.
     */
    neutralTertiaryAlt: '#c8c8c8',
    /**
     * Color code for neutralQuaternary.
     */
    neutralQuaternary: '#d0d0d0',
    /**
     * Color code for neutralQuaternaryAlt.
     */
    neutralQuaternaryAlt: '#dadada',
    /**
     * Color code for neutralLight.
     */
    neutralLight: '#eaeaea',
    /**
     * Color code for neutralLighter.
     */
    neutralLighter: '#f4f4f4',
    /**
     * Color code for neutralLighterAlt.
     */
    neutralLighterAlt: '#f8f8f8',
    /**
     * Color code for the accent.
     */
    accent: '#ff00fb',
    /**
     * Color code for the softest color, which is white in the default theme. This is a very dark color in dark themes.
     * This is the page background.
     */
    white: '#ffffff',
    /**
     * Color code for whiteTranslucent40
     */
    whiteTranslucent40: 'rgba(255, 255, 255, .4)',
    /**
     * Color code for yellowDark.
     */
    yellowDark: '#dabe05',
    /**
     * Color code for yellow.
     */
    yellow: '#f5d924',
    /**
     * Color code for yellowLight.
     */
    yellowLight: '#fce759',
    /**
     * Color code for orange.
     */
    orange: '#f5b523',
    /**
     * Color code for orangeLight.
     */
    orangeLight: '#f8cb65',
    /**
     * Color code for orangeLighter.
     */
    orangeLighter: '#fce8bd',
    /**
     * Color code for redDark.
     */
    redDark: '#972e1a',
    /**
     * Color code for red.
     */
    red: '#d94226',
    /**
     * Color code for magentaDark.
     */
    magentaDark: '#8f008c',
    /**
     * Color code for magenta.
     */
    magenta: '#ff00fb',
    /**
     * Color code for magentaLight.
     */
    magentaLight: '#ff9efd',
    /**
     * Color code for purpleDark.
     */
    purpleDark: '#3d1030',
    /**
     * Color code for purple.
     */
    purple: '#581845',
    /**
     * Color code for purpleLight.
     */
    purpleLight: '#8a5d7c',
    /**
     * Color code for blueDark.
     */
    blueDark: '#142846',
    /**
     * Color code for blueMid.
     */
    blueMid: '#1c3862',
    /**
     * Color code for blue.
     */
    blue: '#29518C',
    /**
     * Color code for blueLight.
     */
    blueLight: '#6985ae',
    /**
     * Color code for tealDark.
     */
    tealDark: '#0b647a',
    /**
     * Color code for teal.
     */
    teal: '#1190af',
    /**
     * Color code for tealLight.
     */
    tealLight: '#58b1c7',
    /**
     * Color code for greenDark.
     */
    greenDark: '#69800e',
    /**
     * Color code for green.
     */
    green: '#97b715',
    /**
     * Color code for greenLight.
     */
    greenLight: '#b6cc5b'
};

export default palette;

// docs picker export here
export const pickerColorList = [];
Object.keys(palette).forEach(key => {
    pickerColorList.push({
        id: key,
        label: key,
        color: palette[key]
    });
});

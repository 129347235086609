import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const fetchNhiList = (parameters: Partial<CONTRACTS.nhiSearchParameters>, client: AxiosInstance): any =>
    client
        .get(`/NHILookup/Search?${objectToQuerystring(parameters)}`)
        .then((response: AxiosResponse<CONTRACTS.nhi[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchNhi = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/NHILookup?nhi=${id}`)
        .then((response: AxiosResponse<CONTRACTS.nhi>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

// export const storeNhi = (item: Partial<CONTRACTS.nhi>, client: AxiosInstance): any => {
//     if (item.id) {
//         return client
//             .put(`/Nhi/${item.id}`, item)
//             .then((response: AxiosResponse<CONTRACTS.nhi>) => response.data)
//             .catch(error => {
//                 throw error.response.data || error.response;
//             });
//     } else {
//         return client
//             .post(`/Nhi`, item)
//             .then((response: AxiosResponse<CONTRACTS.nhi>) => response.data)
//             .catch(error => {
//                 throw error.response.data || error.response;
//             });
//     }
// };

// export const deleteNhi = (id: string | number, client: AxiosInstance): any =>
//     client.delete(`/Nhi/${id}`).catch(error => {
//         throw error.response.data || error.response;
//     });

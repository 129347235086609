export const AUTH_USER_TOKEN = 'AuthUserToken';
export const AUTH_USER_REFRESH_TOKEN = 'AuthUserRefreshToken';
export const AUTH_USER_SELECTED_INSTANCE_URL = 'AuthUserSelectedInstanceUrl';

export interface UserCredentials {
    grant_type: string;
    username: string;
    password: string;
    client_id?: string;
}

export interface RefreshTokenCredentials {
    grant_type: string;
    refresh_token: string;
    client_id?: string;
}

export interface UserProfile {
    id: string;
    kaimahiId?: string;
    providerId?: string;
    firstName: string;
    lastName: string;
    zoneIds?: string[];
    systemId?: string;
    instances?: Instance[];
    adminAccess: boolean;
}

export interface AuthResponse {
    accessToken: string | null;
    instanceNameUrl: string | null;
    hasDefaultInstance: boolean | null;
    navigatorId: string | null;
    instances: Instance[];
    showModalForInstance: boolean | null;
    fromModal: boolean | null;
}

export interface AuthState extends AuthResponse {
    user?: UserProfile;
}

export interface Instance {
    navigatorInstanceId: string;
    urlName: string;
    friendlyName: string;
    lastSelected: boolean;
    url: string;
    default: boolean;
    kaimahiId: string;
    kaimahiAccess: boolean;
}

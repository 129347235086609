import { AxiosInstance, AxiosResponse } from 'axios';

import { Patient } from './patient.contracts';

export const loadList = (client: AxiosInstance): any => {
    return client
        .get('/patients?_start=0&_end=20')
        .then((response: AxiosResponse<Patient[]>) => response)
        .catch(error => ({ error }));
};

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../services';
import { systemActions, SystemStatus } from '../_system';
import { ClientActions, ClientActionTypes } from './client.actions';
import * as API from './client.api';
import { getClientSummaryListFilters } from './client.selectors';

export function* searchClients(): any {
    // get filters
    const filters = yield select(getClientSummaryListFilters);

    if (filters !== {}) {
        // Set loading status
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try log in the user
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.searchList, filters, restService.client);
            yield put(
                ClientActions.setClientSummaryList(Array.isArray(response.data.clientsFound) ? response.data : [])
            );
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* getClientDetail(action): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try fetch client with given ID
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.fetchDetail, action.payload, restService.client);
        // set selected client detail page
        yield put(ClientActions.setClientDetail(response.data.clientEDTO));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* updateClientDetail(action): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try updating client
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.updateClient, action.payload.detail, restService.client);
        // set updated client detail page
        yield put(ClientActions.setClientDetail(response.data.clientEDTO));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* createClient(action): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try creating client
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.createClient, action.payload.detail, restService.client);
        // set created client detail page
        yield put(ClientActions.setClientDetail(response.data.clientEDTO));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

/**
 * Public Sagas
 */
export function* rootClientSaga(): any {
    yield takeEvery(ClientActionTypes.SetClientSummaryListFilters, searchClients);
    yield takeEvery(ClientActionTypes.FetchClientDetail, getClientDetail);
    yield takeEvery(ClientActionTypes.UpdateClientDetail, updateClientDetail);
}

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
//import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

export const fetchProviderList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/provider?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesProvider[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchProvider = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/provider/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesProvider>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchNavigatorInstanceListByProvider = (id: number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/NavigatorInstance/provider/${id}`)
        .then((response: any) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeProvider = (item: Partial<CONTRACTS.DeliveriesProvider>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/provider/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesProvider>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/provider`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesProvider>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const storeProviderNavigatorInstance = (
    item: Partial<CONTRACTS.ProviderNavigatorInstance>[],
    client: AxiosInstance
): any => {
    // if (item.id) {
    //     return client
    //         .put(`/deliveries/navigatorInstance/provider`, item)
    //         .then((response: AxiosResponse<CONTRACTS.ProviderNavigatorInstance>) => response.data)
    //         .catch(error => {
    //             throw error.response.data || error.response;
    //         });
    // } else {
    return client
        .post(`/deliveries/navigatorInstance/provider`, item)
        .then((response: AxiosResponse<CONTRACTS.ProviderNavigatorInstance>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });
    // }
};

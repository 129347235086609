import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesZoneActions, DeliveriesZoneActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesZoneFilters } from './selectors';

export function* fetchZoneList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters> = yield select(getDeliveriesZoneFilters);

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchZoneList, filters, restService.client);
        // Update store
        yield put(deliveriesZoneActions.setDeliveriesZoneList(Array.isArray(response.items) ? response.items : []));

        yield put(deliveriesZoneActions.setTotalInDeliveriesZoneFilters(response.total));

        yield put(deliveriesZoneActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchZone(action): any {
    const zoneId = action.payload;
    if (zoneId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to fetch the zone
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchBulkZone, zoneId, restService.client);
            yield put(deliveriesZoneActions.setDeliveriesZone(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeZone(action): any {
    let response;
    const zone: CONTRACTS.DeliveriesZone = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the Zone
    try {
        //Call update api
        response = yield call(API.storeZone, zone, restService.client);
        yield put(deliveriesZoneActions.setDeliveriesZone(response));

        yield* fetchZoneList(action);
        //update the list after update or create
        yield put(deliveriesZoneActions.updateDeliveriesZoneList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesZoneActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesZoneActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteZone(action): any {
    const zoneId = action.payload;
    if (zoneId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the Zone
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteZone, zoneId, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setZoneFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters> = yield select(getDeliveriesZoneFilters);

    //Update filters
    yield put(deliveriesZoneActions.storeDeliveriesZoneFilters({ ...existingFilters, ...filters }));

    yield* fetchZoneList(action);
}

export const deliveriesZoneSagas = [
    takeEvery(DeliveriesZoneActionTypes.FetchZoneList, fetchZoneList),
    takeEvery(DeliveriesZoneActionTypes.SetZoneFilters, setZoneFilters),
    takeEvery(DeliveriesZoneActionTypes.FetchZone, fetchZone),
    takeEvery(DeliveriesZoneActionTypes.StoreZone, storeZone),
    takeEvery(DeliveriesZoneActionTypes.DeleteZone, deleteZone)
];

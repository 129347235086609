import { IPartialTheme } from '@uifabric/styling';

import Effects from './effects';
import Fonts from './fonts';
import Palette from './palette';
import Spacing from './spacing';

export const TakaheTheme: IPartialTheme = {
    fonts: Fonts,
    palette: Palette,
    spacing: Spacing,
    effects: Effects
};

export default TakaheTheme;

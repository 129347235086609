import React, { ReactElement } from 'react';

import {
    getTheme,
    IFontStyles,
    IPalette,
    ITextFieldStyleProps,
    ITextProps,
    ITextSlots,
    ITextStyles,
    ITextTokens,
    Text
} from '@fluentui/react';
import { IStylesFunctionOrObject } from '@uifabric/foundation';

import { KATheme } from '../../themes';

export type HeadingSizeRange = 0 | 1 | 2 | 3 | 4 | 5;

export interface HeadingProps {
    size?: HeadingSizeRange;
    color?: keyof IPalette;
    subheading?: boolean;
}

const HEADINGS = [
    {
        variant: 'mega',
        as: 'h1'
    },
    {
        variant: 'superLarge',
        as: 'h1'
    },
    {
        variant: 'xxLarge',
        as: 'h2'
    },
    {
        variant: 'xLarge',
        as: 'h3'
    },
    {
        variant: 'large',
        as: 'h4'
    },
    {
        variant: 'mediumPlus',
        as: 'h5'
    }
];

export const Heading = (props: React.PropsWithChildren<HeadingProps> & ITextProps): ReactElement => {
    const { size = 1, color = 'black', subheading, children, style, className } = props;
    const theme = getTheme();

    const font = theme.fonts[HEADINGS[size].variant];

    return (
        <Text
            variant={HEADINGS[size].variant as keyof IFontStyles}
            as={HEADINGS[size].as as React.ReactType<React.HTMLAttributes<HTMLElement>>}
            styles={
                {
                    root: {
                        fontWeight: subheading && size !== 0 ? 400 : font.weight,
                        color: theme.palette[color],
                        // merge with the styles coming from the parent object, if any
                        ...(props.styles ? (props.styles as any).root : {})
                    }
                } as IStylesFunctionOrObject<ITextProps, ITextTokens, ITextStyles>
            }
            block
            style={style}
            className={className}
        >
            {children}
        </Text>
    );
};

export default Heading;

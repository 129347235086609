import produce from 'immer';

import { ClientActionsUnion, ClientActionTypes } from './client.actions';
import { ClientState } from './client.contracts';

/**
 * Reducer
 */
export const CLIENT_REDUCER_DEFAULT: ClientState = {
    summaryList: { clientsFound: [], totalRecordCount: 0 },
    summaryListFilters: {},
    detail: {}
};

export const clientReducer = (state: ClientState, action: ClientActionsUnion): ClientState =>
    produce(state || CLIENT_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case ClientActionTypes.SetClientSummaryList:
                draft.summaryList = action.payload.list;
                break;
            case ClientActionTypes.ClearClientSummaryList:
                draft.summaryList = { clientsFound: [], totalRecordCount: 0 };
                break;
            case ClientActionTypes.SetClientSummaryListFilters:
                draft.summaryListFilters = action.payload.filters;
                break;
            case ClientActionTypes.ClearClientSummaryListFilters:
                draft.summaryListFilters = {};
                break;
            case ClientActionTypes.SetClientDetail:
                draft.detail = action.payload.detail;
                break;
            default:
                return draft;
        }
    });

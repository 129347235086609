import { IFontStyles, IRawStyle } from '@uifabric/styling';

const DEFAULT = {
    fontFamily: '"Inter", sans-serif'
} as IRawStyle;

const fonts: IFontStyles = {
    tiny: { ...DEFAULT, fontSize: 8 },
    xSmall: { ...DEFAULT, fontSize: 10 },
    small: { ...DEFAULT, fontSize: 12 },
    smallPlus: { ...DEFAULT, fontSize: 14 },
    medium: { ...DEFAULT, fontSize: 16 },
    mediumPlus: { ...DEFAULT, fontSize: 18 },
    large: { ...DEFAULT, fontSize: 20 },
    xLarge: { ...DEFAULT, fontSize: 24 },
    xLargePlus: { ...DEFAULT, fontSize: 26 },
    xxLarge: { ...DEFAULT, fontSize: 28 },
    xxLargePlus: { ...DEFAULT, fontSize: 30 },
    superLarge: { ...DEFAULT, fontSize: 32 },
    mega: { ...DEFAULT, fontSize: 48 }
};

export default fonts;

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesInventoryActions, DeliveriesInventoryActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesInventoryFilters } from './selectors';

/**
 * Inventory Sagas
 */
export function* fetchInventoryList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters> = yield select(
            getDeliveriesInventoryFilters
        );

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchInventoryList, filters, restService.client);

        // Update store
        yield put(
            deliveriesInventoryActions.setDeliveriesInventoryList(Array.isArray(response.items) ? response.items : [])
        );

        yield put(deliveriesInventoryActions.setTotalInDeliveriesInventoryFilters(response.total));

        yield put(deliveriesInventoryActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchInventory(action): any {
    const inventoryId = action.payload;
    if (inventoryId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the Inventory
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchInventory, inventoryId, restService.client);
            yield put(deliveriesInventoryActions.setDeliveriesInventory(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeInventory(action): any {
    let response;

    const inventory: CONTRACTS.DeliveriesInventory = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the Inventory
    try {
        //Call update api
        response = yield call(API.storeInventory, inventory, restService.client);
        yield put(deliveriesInventoryActions.setDeliveriesInventory(response));
        //update the list after update or create
        yield put(deliveriesInventoryActions.updateDeliveriesInventoryList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesInventoryActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesInventoryActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteInventory(action): any {
    const inventoryId = action.payload;
    if (inventoryId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the Inventory
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteInventory, inventoryId, restService.client);
            // Update message
            yield* fetchInventoryList(action);
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setInventoryFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters> = yield select(
        getDeliveriesInventoryFilters
    );

    //Update filters
    yield put(deliveriesInventoryActions.storeDeliveriesInventoryFilters({ ...existingFilters, ...filters }));

    yield* fetchInventoryList(action);
}

export const deliveriesInventorySagas = [
    takeEvery(DeliveriesInventoryActionTypes.FetchInventoryList, fetchInventoryList),
    takeEvery(DeliveriesInventoryActionTypes.SetInventoryFilters, setInventoryFilters),
    takeEvery(DeliveriesInventoryActionTypes.FetchInventory, fetchInventory),
    takeEvery(DeliveriesInventoryActionTypes.StoreInventory, storeInventory),
    takeEvery(DeliveriesInventoryActionTypes.DeleteInventory, deleteInventory)
];

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../utils/object-to-querystring';
import { AllowedDeliveryFilters, Delivery, DeliveryResponse } from './delivery.contracts';

// TODO: review return types when done

export const fetchList = (filters: Partial<AllowedDeliveryFilters>, client: AxiosInstance): any =>
    client
        .get(`/delivery?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<DeliveryResponse>) => response)
        .catch(error => ({ error }));

export const fetchItem = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/delivery/${id}`)
        .then((response: AxiosResponse<Delivery>) => response)
        .catch(error => ({ error }));

export const storeItem = (item: Delivery, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/delivery/${item.id}`, item)
            .then((response: AxiosResponse<Delivery>) => response)
            .catch(error => ({ error }));
    } else {
        return client
            .post(`/delivery`, item)
            .then((response: AxiosResponse<Delivery>) => response)
            .catch(error => ({ error }));
    }
};

export const fetchZoneListByProvider = (id: string, client: AxiosInstance): any =>
    client
        .get(`/zone?kaimahiId=&keywords=&providerId=${encodeURIComponent(id)}`)
        .then((response: AxiosResponse<DeliveryResponse>) => response)
        .catch(error => ({ error }));

export const fetchKaimahiListByProvider = (id: string, client: AxiosInstance): any =>
    client
        .get(`/kaimahi?kaimahiId=&keywords=&providerId=${encodeURIComponent(id)}`)
        .then((response: AxiosResponse<DeliveryResponse>) => response)
        .catch(error => ({ error }));

export const fetchKaimahiById = (id: string, client: AxiosInstance): any =>
    client
        .get(`/kaimahi?kaimahiId=${encodeURIComponent(id)}&providerId=&keywords=`)
        .then((response: AxiosResponse<DeliveryResponse>) => response)
        .catch(error => ({ error }));

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const fetchCasenoteList = (filters: Partial<CONTRACTS.AllowedCasenoteFilters>, client: AxiosInstance): any =>
    client
        .get(`/casenote?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.Casenote[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchCasenote = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/casenote/${id}`)
        .then((response: AxiosResponse<CONTRACTS.Casenote>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeCasenote = (item: Partial<CONTRACTS.Casenote>, client: AxiosInstance): any => {
    if (item.casenoteId) {
        return client
            .put(`/casenote/${item.casenoteId}`, item)
            .then((response: AxiosResponse<CONTRACTS.Casenote>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/casenote`, item)
            .then((response: AxiosResponse<CONTRACTS.Casenote>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteCasenote = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/casenote/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

let storageObject = {};

export const memoryStorage: Storage = {
    get length() {
        return Object.keys(storageObject).length;
    },
    clear: () => (storageObject = {}),
    getItem: (key: string) => storageObject[key] || null,
    key: (index: number) => {
        return index >= 0 && index < memoryStorage.length ? Object.keys(storageObject)[index] : null;
    },
    removeItem: (key: string) => delete storageObject[key],
    setItem: (key: string, value: string) => (storageObject[key] = value)
};

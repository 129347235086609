import { Instance, UserProfile } from './auth.contracts';

export const isAuthenticated = (state: any): boolean => {
    return state._auth ? !!state._auth.accessToken : false;
};

export const getAccessToken = (state: any): string => {
    return state._auth ? state._auth.accessToken : null;
};

export const getInstanceNameUrl = (state: any): string => {
    return state._auth ? state._auth.instanceNameUrl : null;
};

export const getUserProfile = (state: any): UserProfile => {
    return state._auth ? state._auth.user : null;
};

export const hasDefaultInstance = (state: any): boolean => {
    return state._auth ? !!state._auth.hasDefaultInstance : false;
};

export const getNavigatorId = (state: any): string => {
    return state._auth ? state._auth.navigatorId : null;
};

export const getInstanceList = (state: any): Instance[] => {
    return state._auth ? state._auth.instances : [];
};

export const getShowModalForInstance = (state: any): boolean => {
    return state._auth ? state._auth.showModalForInstance : false;
};

export const getModal = (state: any): boolean => {
    return state._auth ? state._auth.fromModal : false;
};

/**
 * Auth selectors object
 */
export const AuthSelectors = {
    isAuthenticated,
    getAccessToken,
    getInstanceNameUrl,
    getUserProfile,
    hasDefaultInstance,
    getInstanceList,
    getNavigatorId,
    getShowModalForInstance,
    getModal
};

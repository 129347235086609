import { all, call, put, takeEvery } from 'redux-saga/effects';

import { restService } from '../../services';
//import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemStatus } from '../_system';
import { attachmentActions, AttachmentActionTypes } from './attachment.actions';
import * as API from './attachment.api';
import * as CONTRACTS from './attachment.contracts';

/**
 * Attachment Sagas
 */
// export function* fetchAttachmentList(action): any {
//     // set filters from action
//     const filters: Partial<CONTRACTS.AllowedAttachmentFilters> = action.payload;

//     // Set loading status
//     yield put(systemActions.setStatus(SystemStatus.loading));

//     try {
//         // Call API (asynchronous, so yield)
//         const response = yield call(API.fetchAttachmentList, filters, restService.client);
//         yield put(attachmentActions.setAttachmentList(Array.isArray(response) ? response : []));
//     } catch (error) {
//         // If we get an error, set the message
//         // yield put({type: REQUEST_ERROR, error: error.message})
//         return false;
//     } finally {
//         // Back to idle
//         yield put(systemActions.setStatus(SystemStatus.idle));
//     }
// }

// export function* fetchAttachment(action): any {
//     const attachmentId = action.payload;
//     if (attachmentId) {
//         yield put(systemActions.setStatus(SystemStatus.loading));

//         // Try to delete the delevery Type
//         try {
//             // Call Auth API (asynchronous, so yield)
//             const response = yield call(API.fetchAttachment, attachmentId, restService.client);
//             yield put(attachmentActions.SetDeliveryType(response));
//         } catch (error) {
//             // If we get an error, set the message
//             // yield put({type: REQUEST_ERROR, error: error.message})
//             return false;
//         } finally {
//             // Back to idle
//             yield put(systemActions.setStatus(SystemStatus.idle));
//         }
//     }
// }

export function* storeAttachment(action): any {
    let response;

    const attachment: CONTRACTS.Attachment = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try to update or create the delevery Type
    try {
        //Call update api
        response = yield call(API.storeAttachment, attachment, restService.client);
        yield put(attachmentActions.setAttachment(response));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
        // yield all([
        //     // Back to idle
        //     put(systemActions.setStatus(SystemStatus.idle))
        // ]);

        //TO DO
        // check if new
        //Redirect
    }
}

export function* deleteAttachment(action): any {
    const attachmentId = action.payload;
    if (attachmentId) {
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try to delete the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteAttachment, attachmentId, restService.client);
            //return type is void
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export default function* rootAttachmentSagas(): any {
    yield all([
        takeEvery(AttachmentActionTypes.StoreAttachment, storeAttachment),
        takeEvery(AttachmentActionTypes.DeleteAttachment, deleteAttachment)
    ]);
}

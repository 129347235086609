import { IEffects, IFontStyles } from '@fluentui/react';

import Effects from './effects';
import Fonts from './fonts';
import Palette, { KAPalette } from './palette';
import Spacing, { KASpacing } from './spacing';

export interface KATheme {
    palette: Partial<KAPalette>;
    spacing: Partial<KASpacing>;
    effects: Partial<IEffects>;
    fonts: Partial<IFontStyles>;
}

export const KaimahiAppTheme: KATheme = {
    fonts: Fonts,
    palette: Palette,
    spacing: Spacing,
    effects: Effects
};

export default KaimahiAppTheme;

import axios, { AxiosError, AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios';
import { container, singleton } from 'tsyringe';

import { Logger } from '@whanau/diagnostics';

import * as API from '../modules/_auth/auth.api';
import { AUTH_USER_REFRESH_TOKEN, AUTH_USER_TOKEN } from '../modules/_auth/auth.contracts';
import { RestConfiguration } from './configuration.service';
import { storageService } from './storage.service';

/**
 * Rest Client Service
 */
@singleton()
export class RestClientService {
    private _client: AxiosInstance;
    private _requestInterceptor: AxiosInterceptorManager<AxiosRequestConfig>;
    private _responseInterceptor: AxiosInterceptorManager<AxiosResponse>;
    // _subscribers is the list of waiting requests that will retry after the JWT refresh complete
    private _subscribers = [];

    constructor() {
        // Instance Axios client
        this._client = axios.create({});
        // Register intecteptor for request data serialization
        // TODO: Seems not to work, investigate
        // this._requestInterceptor = this._client.interceptors.request;
        // this._requestInterceptor.use(
        //     (config: AxiosRequestConfig): AxiosRequestConfig => {
        //         config.paramsSerializer = params =>
        //             qs.stringify(params, {
        //                 serializeDate: (d: Date) => {
        //                     console.log(
        //                         'parsing date',
        //                         `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}T${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}Z`
        //                     );
        //                     return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}T${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}Z`;
        //                 }
        //             });
        //         return config;
        //     }
        // );
        // Register interceptor for response exception handling
        this._responseInterceptor = this._client.interceptors.response;
        this._responseInterceptor.use(
            // If the request succeeds just return the response
            (response: AxiosResponse) => response,
            // Request fals
            (error: AxiosError) => {
                // Logout user if token refresh didn't work
                if (error.config.url.indexOf('token#refreshtoken') !== -1) {
                    console.log('should be here');
                    //TODO : Logout user
                    storageService.remove(AUTH_USER_TOKEN);
                    storageService.remove(AUTH_USER_REFRESH_TOKEN);
                    this.setAuthHeaders(' ');
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                // Return any error which is not due to authentication back to the calling service
                if (error.response.status !== 401) {
                    Logger.warn('rest client interceptor exception handling', error.response);
                    return Promise.reject(error);
                }

                //Try to reset and retry request
                return this.resetTokenAndReattemptRequest(error);
            }
        );
    }

    /**
     * Merges custom Rest configurations with existing values
     */
    public configure(configuration?: Partial<RestConfiguration>): void {
        // assign configuration to axios defaults
        this._client.defaults.baseURL = configuration.baseURL || this._client.defaults.baseURL;
        this._client.defaults.timeout = configuration.timeout || this._client.defaults.timeout;
    }

    /**
     * Returns curent instance of Axios client
     */
    public get client(): AxiosInstance {
        return this._client;
    }

    /**
     * Sets auth tokem to the 'Authorization' header
     */
    public setAuthHeaders(authToken: string, instanceUrlName?: string, navigatorId?: string): void {
        authToken && (this._client.defaults.headers.common['Authorization'] = `Bearer ${authToken}`);
        instanceUrlName && (this._client.defaults.headers.common['instanceUrlName'] = `${instanceUrlName}`);
        navigatorId && (this._client.defaults.headers.common['navigatorId'] = `${navigatorId}`);
    }

    /**
     * Removes auth tokem from the 'Authorization' header
     */
    public clearAuthHeaders(): void {
        delete this._client.defaults.headers.common['Authorization'];
    }

    /**
     * Handle token efresh token and re-play of request(s) that have failed because of that
     */
    private resetTokenAndReattemptRequest(error: AxiosError): Promise<any> {
        let isAlreadyFetchingAccessToken = false;

        try {
            // Your own mechanism to get the refresh token to refresh the JWT token
            const refreshToken: string = storageService.get(AUTH_USER_REFRESH_TOKEN) || null;

            if (!refreshToken) {
                // We can't refresh, throw the error anyway
                return Promise.reject(error);
            }

            /* Proceed to the token refresh procedure
            We create a new Promise that will retry the request,
            clone all the request configuration from the failed
            request in the error object. */
            const retryOriginalRequest = new Promise(resolve => {
                /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
                this.addSubscriber(accessToken => {
                    error.config.headers.Authorization = 'Bearer ' + accessToken;
                    resolve(this._client(error.config));
                });
            });

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                API.refreshToken(refreshToken, this._client)
                    .then(res => {
                        if (!res) {
                            return Promise.reject(error);
                        }

                        // save the newly refreshed token for other requests to use
                        this.saveNewToken(res.data);
                        isAlreadyFetchingAccessToken = false;
                        this.onAccessTokenFetched(res.data.access_token);
                        return retryOriginalRequest;
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            return retryOriginalRequest;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    private onAccessTokenFetched(accessToken): void {
        // When the refresh is successful, we start retrying the requests one by one and empty the queue
        this._subscribers.forEach(callback => callback(accessToken));
        this._subscribers = [];
    }

    private addSubscriber(callback): void {
        this._subscribers.push(callback);
    }

    private saveNewToken(data): void {
        storageService.set(AUTH_USER_TOKEN, data.access_token);
        storageService.set(AUTH_USER_REFRESH_TOKEN, data.refresh_token);
        this.setAuthHeaders(data.access_token);
    }
}

/**
 * Global container hosted, singleton instance of RestClientService
 * Use this preferably, unless specific need to create a secondary logger arises
 */
export const restService = container.resolve(RestClientService);

import * as CONTRACTS from './contracts';

export const getTerms = (state: any): boolean => {
    return state.ChildHub.Other.terms || false;
};
export const getActiveStep = (state: any): number => {
    return state.ChildHub.Other.activeStep || null;
    // return 3;
};
export const getSuccess = (state: any): boolean => {
    return state.ChildHub.Other.success || false;
};
export const getResponse = (state: any): string => {
    return state.ChildHub.Other.response || '';
};
export const getItem = (state: any): string => {
    return state.ChildHub.Other.key || '';
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { mapValues } from '../map-values';
import { getTypeOfValue, ValueType } from './get-type-of-value';
import { isSerializerValueObject, SerializerValue, SerializerValueType } from './serializer-value';

const deSerializeDate = (value: string): Date => {
    return new Date(value);
};

const deSerializeSerializerValueObject = (object: SerializerValue): any => {
    switch (object._type_) {
        case SerializerValueType.Date:
            return deSerializeDate(object._value_);
        case SerializerValueType.Set:
            return deSerializeSet(object._value_);
        case SerializerValueType.Map:
            return deSerializeMap(object._value_);
        default:
            throw new Error(`Serializer value type '${object._type_}' unknown. Failed to parse value.`);
    }
};

const deSerializeValue = (value: any): any => {
    const type = getTypeOfValue(value);
    switch (type) {
        case ValueType.Object:
            return deSerializeObject(value);
        case ValueType.Array:
            return deSerializeArray(value);
        default:
            return value;
    }
};

const deSerializeArray = (array: any[]): any[] => {
    return array.map(element => deSerializeValue(element));
};

const deSerializeObject = (object: Record<string, unknown>): Record<string, unknown> => {
    if (isSerializerValueObject(object)) return deSerializeSerializerValueObject(object);
    return mapValues(object, deSerializeValue);
};

const deSerializeSet = (valueArray: any[]): Set<any> => {
    return new Set(deSerializeArray(valueArray));
};

const deSerializeMap = (valueArray: any[]): Map<any, any> => {
    return new Map(deSerializeArray(valueArray));
};

/**
 * De-serializes a stringified value back to it's original type.
 */
export const parse = (value: string | undefined): any => {
    if (!value) return value; // null/undefined

    let parsedValue;
    try {
        parsedValue = JSON.parse(value);
    } catch (e) {
        // If we can't parse, it's probably because it isn't an object
        parsedValue = value;
    }

    return deSerializeValue(parsedValue);
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { mapValues } from '../map-values';
import { getTypeOfValue, ValueType } from './get-type-of-value';
import { SerializerValue, SerializerValueType } from './serializer-value';

const serializeObject = (object: Record<string, unknown>): Record<string, unknown> => {
    return mapValues(object, serializeValue);
};

const serializeArray = (array: any[]): any[] => {
    return array.map(element => serializeValue(element));
};

const serializeDate = (date: Date): SerializerValue => {
    return {
        _type_: SerializerValueType.Date,
        _value_: date.toISOString()
    };
};

const serializeSet = (set: Set<any>): SerializerValue => {
    return {
        _type_: SerializerValueType.Set,
        _value_: serializeArray(Array.from(set))
    };
};

const serializeMap = (map: Map<any, any>): SerializerValue => {
    return {
        _type_: SerializerValueType.Map,
        _value_: serializeArray(Array.from(map))
    };
};

const serializeValue = (value: any): any => {
    const type = getTypeOfValue(value);
    switch (type) {
        case ValueType.Object:
            return serializeObject(value);
        case ValueType.Array:
            return serializeArray(value);
        case ValueType.Date:
            return serializeDate(value);
        case ValueType.Set:
            return serializeSet(value);
        case ValueType.Map:
            return serializeMap(value);
        case ValueType.Unsupported:
            throw new Error(`Unsupported value ${value} could not be serialized`);
        default:
            return value;
    }
};

/**
 * Serializes a given value to a string that can be de-serialized back to it's original type.
 */
export const stringify = (value: any): string => {
    if (typeof value === 'string') return value; // Strings do not need to be stringified
    const serializedValue = serializeValue(value); // Some types need to be serialized before stringifying
    return JSON.stringify(serializedValue);
};

import {
    systemActions,
    SystemActionTypes,
    SystemMessage,
    SystemMessageSeverity,
    SystemMessageType
} from '../modules/_system';
import { ActionWithPayload } from './action.helpers';

/**
 * Shorthand action to create a success message
 * Returns setMessage action with `success` type
 */
export function setSuccessMessage(
    type: SystemMessageType,
    message?: string,
    body?: any
): ActionWithPayload<SystemActionTypes.SetMessage, { message: SystemMessage }> {
    return systemActions.setMessage({
        severity: SystemMessageSeverity.success,
        type: type,
        message: message,
        body: body
    });
}

/**
 * Shorthand action to create an error message
 * Returns setMessage action with `error` type
 */
export function setErrorMessage(
    type: SystemMessageType,
    message?: string,
    body?: any
): ActionWithPayload<SystemActionTypes.SetMessage, { message: SystemMessage }> {
    return systemActions.setMessage({
        severity: SystemMessageSeverity.error,
        type: type,
        message: message,
        body: body
    });
}

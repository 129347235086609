import { parse } from './parse';
import { stringify } from './stringify';

export interface Serializer {
    stringify: (value: any) => string;
    parse: (value: string) => any;
}

export const serializer: Serializer = {
    stringify: stringify,
    parse: parse
};

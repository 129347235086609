import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { CaseloadActionTypes, planCaseloadActions } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';
import { getCaseloadFilters } from './selectors';

/**
 * Caseload Sagas
 */
export function* fetchCaseloadList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedCaseloadFilters> = yield select(getCaseloadFilters);
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchCaseloadList, filters, restService.client);
        // Update store
        yield put(planCaseloadActions.setCaseloadList(Array.isArray(response.items) ? response.items : []));

        yield put(planCaseloadActions.setTotalInCaseloadFilters(response.total));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchCaseload(action): any {
    const caseloadId = action.payload;
    if (caseloadId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the delevery
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchCaseload, caseloadId, restService.client);
            yield put(planCaseloadActions.setCaseload(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeCaseload(action): any {
    let response;

    const caseload: CONTRACTS.Caseload = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the delevery
    try {
        //Call update api
        response = yield call(API.storeCaseload, caseload, restService.client);
        yield put(planCaseloadActions.setCaseload(response));
        //update the list after update or create
        yield put(planCaseloadActions.updateCaseloadList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage, response.id));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteCaseload(action): any {
    const caseloadId = action.payload;
    if (caseloadId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteCaseload, caseloadId, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setCaseloadFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedCaseloadFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedCaseloadFilters> = yield select(getCaseloadFilters);

    //Update filters
    yield put(planCaseloadActions.storeCaseloadFilters({ ...existingFilters, ...filters }));

    yield* fetchCaseloadList(action);
}

export const planCaseloadSagas = [
    takeEvery(CaseloadActionTypes.FetchCaseloadList, fetchCaseloadList),
    takeEvery(CaseloadActionTypes.SetCaseloadFilters, setCaseloadFilters),
    takeEvery(CaseloadActionTypes.FetchCaseload, fetchCaseload),
    takeEvery(CaseloadActionTypes.StoreCaseload, storeCaseload),
    takeEvery(CaseloadActionTypes.DeleteCaseload, deleteCaseload)
];

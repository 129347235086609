type UpdateArrayObjectWithObject = (
    target: Array<Record<string, unknown>>,
    source: Record<string, unknown>
) => Array<Record<string, unknown>>;

export const updateArrayObjectWithObject: UpdateArrayObjectWithObject = (
    target: Array<Record<string, unknown>>,
    source: Record<string, unknown>
): Array<Record<string, unknown>> => {
    let isUpdateRequest = false;
    const newTarget = target.map(x => {
        if (x.id === source.id) {
            isUpdateRequest = true;
            return source;
        }
        return x;
    });
    if (!isUpdateRequest) {
        //push new item to the list
        newTarget.push(source);
    }

    return newTarget;
};

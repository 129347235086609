import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesBulkAssign = (state: any): Partial<CONTRACTS.DeliveriesDeliveryBulkAssignDTOVerbose> => {
    return state.deliveries.deliveryBulkAssign || {};
};

export const getDeliveryTypeBulkAssign = (state: any): any[] => {
    return state.deliveries.deliveryBulkAssign.deliveryType || [];
};

export const getKaimahiBulkAssign = (state: any): any[] => {
    return state.deliveries.deliveryBulkAssign.kaimahi || [];
};

export const getHouseHoldBulkAssign = (state: any): any[] => {
    return state.deliveries.deliveryBulkAssign.household || [];
};

export const getRemoteSourceList = (state: any): CONTRACTS.DeliveriesRemoteSourceQueries => {
    return state.deliveries.deliveryBulkAssign.remoteSourceQueryList || [];
};

export const getDeliveriesHouseholdFromQueryList = (state: any): CONTRACTS.DeliveriesHouseholdSummary[] => {
    return state.deliveries.householdFromQuery.list || [];
};

export const getDeliveriesHouseholdFromQueryFilters = (
    state: any
): Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> => {
    return state.deliveries.householdFromQuery.filters || {};
};

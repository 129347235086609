import * as Yup from 'yup';

export const deliveryValidationSchema = (): any => {
    return Yup.object().shape({
        firstName: Yup.string().nullable().required('Required'),
        lastName: Yup.string().nullable().required('Required')
        // TODO: improve validation for address
        // street: Yup.string()
        //     .nullable()
        //     .required('Required'),
        // postalCode: Yup.string()
        //     .nullable()
        //     .required('Required'),
        // city: Yup.string()
        //     .nullable()
        //     .required('Required')
    });
};

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

// Delivery type
export const fetchNavigatorInstanceList = (
    filters: Partial<CONTRACTS.AllowedNavigatorInstanceFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/NavigatorInstance?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesNavigatorInstance[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeNavigatorInstance = (
    item: Partial<CONTRACTS.DeliveriesNavigatorInstance>,
    client: AxiosInstance
): any => {
    if (item.id) {
        return client
            .put(`/deliveries/navigatorInstance/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesNavigatorInstance>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/navigatorInstance`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesNavigatorInstance>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const fetchNavigatorInstance = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/NavigatorInstance/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesNavigatorInstance>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const deleteNavigatorInstance = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/navigatorInstance/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

export const fetchInstanceListForKaimahi = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/NavigatorInstance/kaimahi/${id}`)
        .then((response: AxiosResponse<CONTRACTS.KaimahiNavigatorInstance>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

//to do : delete after move to provider successful
export const fetchNavigatorInstanceListByProvider = (id: number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/NavigatorInstance/provider/${id}`)
        .then((response: any) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

import { IFontStyles, IRawStyle } from '@uifabric/styling';

const DEFAULT = {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 300,
    letterSpacing: '-0.03em'
} as IRawStyle;

const fonts: IFontStyles = {
    tiny: { ...DEFAULT, fontSize: 8 },
    xSmall: { ...DEFAULT, fontSize: 10 },
    small: { ...DEFAULT, fontSize: 12 },
    smallPlus: { ...DEFAULT, fontSize: 13 },
    medium: { ...DEFAULT, fontSize: 14 },
    mediumPlus: { ...DEFAULT, fontSize: 16 },
    large: { ...DEFAULT, fontSize: 20, fontWeight: 600 },
    xLarge: { ...DEFAULT, fontSize: 24, fontWeight: 600 },
    xLargePlus: { ...DEFAULT, fontSize: 26, fontWeight: 600 },
    xxLarge: { ...DEFAULT, fontSize: 28, fontWeight: 600 },
    xxLargePlus: { ...DEFAULT, fontSize: 30, fontWeight: 600 },
    superLarge: { ...DEFAULT, fontSize: 32, fontWeight: 600 },
    mega: { ...DEFAULT, fontSize: 60, fontWeight: 200, letterSpacing: '-0.09em' }
};

export default fonts;

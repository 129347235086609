import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

//IncludeDeleted=true&
export const fetchZoneList = (filters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters>, client: AxiosInstance): any =>
    client
        .get(`/deliveries/zone?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesZone[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchZone = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/zone/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesZone>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchBulkZone = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/Actions/bulk-assign-zone/${id}`)
        .then((response: AxiosResponse<CONTRACTS.ZoneBulkAssign>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeZone = (item: Partial<CONTRACTS.DeliveriesZone>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/zone/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesZone>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/zone`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesZone>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteZone = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/zone/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

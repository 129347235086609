import produce from 'immer';

import { AttachmentActionsUnion, AttachmentActionTypes } from './attachment.actions';
import { AttachmentState } from './attachment.contracts';

/**
 * Reducer
 */
export const ATTACHMENT_REDUCER_DEFAULT: AttachmentState = { list: [], item: {}, filters: { limit: 25, offset: 0 } };

export const attachmentReducer = (state: AttachmentState, action: AttachmentActionsUnion): AttachmentState =>
    produce(state || ATTACHMENT_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case AttachmentActionTypes.SetAttachmentList:
                draft.list = action.payload.list;
                break;
            case AttachmentActionTypes.ClearAttachmentList:
                draft.list = [];
                break;
            case AttachmentActionTypes.SetAttachment:
                draft.item = action.payload.item;
                break;
            case AttachmentActionTypes.StoreAttachmentFilters:
                draft.filters = action.payload;
                break;
            case AttachmentActionTypes.ClearAttachmentFilters:
                draft.filters = {};
                break;
            default:
                return draft;
        }
    });

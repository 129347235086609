import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesDeliveryTypeActionTypes {
    FetchDeliveryTypeList = 'deleveries/fetchDeliveryTypeList',
    SetDeliveryTypeList = 'deleveries/setDeliveryTypeList',
    ClearDeliveryTypeList = 'deleveries/clearDeliveryTypeList',
    UpdateDeliveryTypeList = 'deliveries/updateDeliveryTypeList',
    FetchDeliveryType = 'deleveries/fetchDeliveryType',
    SetDeliveryType = 'deleveries/setDeliveryType',
    DeleteDeliveryType = 'deliveries/deleteDeliveryType',
    StoreDeliveryType = 'deliveries/storeDeliveryType',
    SetDeliveryTypeFilters = 'deliveries/setDeliveryTypeFilters',
    StoreDeliveryTypeFilters = 'deliveries/storeDeliveryTypeFilters',
    SetTotalInDeliveriesDeliveryTypeFilters = 'deliveries/setTotalInDeliveriesDeliveryTypeFilters',
    ClearDeliveryTypeFilters = 'deliveries/clearDeliveryTypeFilters',
    SetSubmitSuccess = 'deliveries/setDeliverytypeSubmitSuccess'
}

/**
 * DeliveryType Actions
 */
export const deliveriesDeliveryTypeActions = {
    // retrieve DeliveryType items
    fetchDeliveriesDeliveryTypeList: (): Action<DeliveriesDeliveryTypeActionTypes.FetchDeliveryTypeList> =>
        createAction(DeliveriesDeliveryTypeActionTypes.FetchDeliveryTypeList),

    // populate DeliveryType items
    setDeliveriesDeliveryTypeList: (
        list
    ): ActionWithPayload<
        DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeList,
        { list: CONTRACTS.DeliveriesDeliveryType[] }
    > => createAction(DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeList, { list }),

    // retrieve DeliveryType item
    fetchDeliveriesDeliveryType: (id): any => createAction(DeliveriesDeliveryTypeActionTypes.FetchDeliveryType, id),

    // populate DeliveryType item
    setDeliveriesDeliveryType: (
        item
    ): ActionWithPayload<
        DeliveriesDeliveryTypeActionTypes.SetDeliveryType,
        { item: CONTRACTS.DeliveriesDeliveryType }
    > => createAction(DeliveriesDeliveryTypeActionTypes.SetDeliveryType, { item }),

    // Update DeliveryType list after create and update
    updateDeliveriesDeliveryTypeList: (
        item
    ): ActionWithPayload<DeliveriesDeliveryTypeActionTypes.UpdateDeliveryTypeList, CONTRACTS.DeliveriesDeliveryType> =>
        createAction(DeliveriesDeliveryTypeActionTypes.UpdateDeliveryTypeList, item),

    // update DeliveryType item
    storeDeliveriesDeliveryType: (
        item: CONTRACTS.DeliveriesDeliveryType
    ): ActionWithPayload<DeliveriesDeliveryTypeActionTypes.StoreDeliveryType, CONTRACTS.DeliveriesDeliveryType> =>
        createAction(DeliveriesDeliveryTypeActionTypes.StoreDeliveryType, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesDeliveryTypeActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesDeliveryTypeActionTypes.SetSubmitSuccess, { success }),

    // clear DeliveryType list
    clearDeliveriesDeliveryTypeList: (): Action<DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeList> =>
        createAction(DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeList),

    // populate DeliveryType filters
    setDeliveriesDeliveryTypeFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeFilters,
        CONTRACTS.AllowedDeliveriesDeliveryTypeFilters
    > => createAction(DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeFilters, filters),

    storeDeliveriesDeliveryTypeFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryTypeActionTypes.StoreDeliveryTypeFilters,
        CONTRACTS.AllowedDeliveriesDeliveryTypeFilters
    > => createAction(DeliveriesDeliveryTypeActionTypes.StoreDeliveryTypeFilters, filters),

    setTotalInDeliveriesDeliveryTypeFilters: (
        total: number
    ): ActionWithPayload<DeliveriesDeliveryTypeActionTypes.SetTotalInDeliveriesDeliveryTypeFilters, number> =>
        createAction(DeliveriesDeliveryTypeActionTypes.SetTotalInDeliveriesDeliveryTypeFilters, total),

    // clear DeliveryType filters
    clearDeliveriesDeliveryTypeFilters: (): Action<DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeFilters> =>
        createAction(DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeFilters),

    //delete DeliveryType
    deleteDeliveriesDeliveryType: (id): any => createAction(DeliveriesDeliveryTypeActionTypes.DeleteDeliveryType, id)
};

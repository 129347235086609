import { ISpacing } from '@uifabric/styling';

export interface Spacing extends ISpacing {
    s: string;
    xl: string;
}

const spacing: Spacing = {
    s2: '4px',
    s1: '8px',
    s: '12px',
    m: '16px',
    l1: '20px',
    l2: '32px',
    xl: '48px'
};

export default spacing;

import { Configuration } from './models/configuration';
import { ThresholdLevel } from './models/threshold';

/**
 * Configuration defaults for Diagnostics Log class
 */
export const CONFIGURATION_DEFAULTS: Configuration = {
    console: {
        thresholdLevel: ThresholdLevel.INFO,
        messageColor: '#262122', // raising black
        messagePrefix: '🧪',
        messagePrefixColor: '#00B294' // cool black (blue, actually)
    }
};

/**
 * Supported logging methods
 */
export const SUPPORTED_METHODS: string[] = ['trace', 'debug', 'info', 'warn', 'error'];

/**
 * Pattern string used to format date
 */
export const DATE_FORMAT_PATTERN = 'HH:mm:ss:SS';

/**
 * Household DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/household  returns list of household.
 *                               will accept querystring to filter by: kaimahiId, providerId and a freeform of (firstname, lastname, city, suburb, street)
 * GET    /deliveries/household/:id  returns list of household belonging to given id
 * PUT    /deliveries/household/:id  updates a household record for a given id, returns updated record
 * POST   /deliveries/household      inserts a new household record, return inserted record and its assigned Id
 * DELETE /deliveries/household/:id   deletes household record for a given id.
 */

interface DeliveriesHouseholdBase {
    // identifiers
    id: string;

    // main contact details
    firstName: string;
    lastName: string;
    phone: string;

    // main address
    street: string;
    suburb?: string;
    city: string;
    postalCode: string;

    // admin
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
}

// Used by GET list only
export interface DeliveriesHouseholdSummary extends DeliveriesHouseholdBase {
    address?: string;
    pendingDeliveries: number;
    completedDeliveries: number;
}

export interface DeliveriesHousehold extends DeliveriesHouseholdBase {
    // identifiers
    systemId: string;
    systemIdRecordType: systemIdRecordType;

    // ownership
    providerId: string; // Provider.id

    // main contact details
    middleName: string;
    dateOfBirth: string; // ISO8601 date representation
    email: string;
    instagram?: string;
    facebook?: string;

    // main address
    country?: string;
    apartment?: string;
    meshblock?: string;
    latitude?: number; //double
    longitude?: number; //double
    address: string;

    // household composition
    occupants: {
        infants: number;
        teens: number;
        adults: number;
        elderly: number;
    };

    // children models
    deliveries?: Array<DeliveriesDelivery>;

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    isDeleted: boolean;
    isArchived: boolean;
}

export interface AllowedDeliveriesHouseholdFilters {
    providerId?: string;
    kaimahiId?: string;
    keywords?: string;
    zoneId?: string;
    pending?: boolean;
    limit?: number;
    offset?: number;
    total?: number;
    queryId?: string;
}

/**
 * Deliveries DTO
 *
 * API Endpoints:
 *
 * PUT   /deliveries/delivery/:id    updates a Delivery record for a given id, returns updated record
 * POST  /deliveries/delivery        inserts a new Delivery record, return inserted record and its assigned Id
 * DELETE  /deliveries/delivery/:id  deletes Delivery record for a given id.
 */
interface DeliveriesDeliveryBase {
    // identifiers
    id?: string;

    // details
    deliveryType: string; // DeliveryType label
    status: DeliveriesDeliveryStatus;
    numberOfItems: number; // Amount of items delivered

    // household
    household?: {
        label: string;
        address: string;
    };

    // ownership
    deliveredOn?: string; // ISO8601 date representation
    deliveredBy?: string; // Kaimahi.id
    zone: string; //Zone label

    // admin
    createdOn?: string; // ISO8601 date representation
}

export interface DeliveriesDelivery extends DeliveriesDeliveryBase {
    // identifiers
    systemId?: string;

    // details
    deliveryTypeId: string; // DeliveryType.id
    icon: number;
    notes: string; // Any comments regarding delivery
    rapidResponse: boolean; // Flag to indicate Rapid Response
    attachments?: Array<DeliveriesAttachment>; // Array of attachments/Images

    // ownership
    householdId: string; // Household.Id link
    zoneId: string; // Zone.id

    // admin
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
    isDeleted: boolean;
    isArchived: boolean;
}

export interface DeliveriesDeliverySummary extends DeliveriesDeliveryBase {
    //household
    firstName: string;
    lastName: string;
    city: string;
    street: string;
    suburb: string;
}

export interface AllowedDeliveriesDeliveryFilters {
    householdId?: string;
    kaimahiId?: string;
    zoneId?: string;
    deliveryTypeId?: string;
    status?: DeliveriesDeliveryStatus;
    pending?: boolean;
    keywords?: string;
    limit?: number;
    offset?: number;
    total?: number;
    orderBy?: string;
}

/**
 * DeliveryType DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/delivery-type  returns list of delivery type
 * GET    /deliveries/delivery-type/:id  returns list of delivery type belonging to given id
 * PUT    /deliveries/delivery-type/:id  updates a delivery type record for a given id, returns updated record
 * POST   /deliveries/delivery-type      inserts a new delivery type record, return inserted record and its assigned Id
 * DELETE /deliveries/delivery-type/:id   deletes delivery type record for a given id.
 */
export interface DeliveriesDeliveryType {
    isDeleted: boolean;
    // identifiers
    id: string;

    // details
    label: string;
    description?: string; // long
    icon: number; // 0-9

    // ownership
    providerId: string; // Provider.id

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
    isArchived: boolean;
    numberOfItems: string;
}

export interface AllowedDeliveriesDeliveryTypeFilters {
    keywords?: string;
    providerId?: string;
    limit?: number;
    offset?: number;
    total?: number;
    includeArchived?: boolean;
}

/**
 * Zone DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/zone  returns list of zone
 *                          will accept querystring to filter by: kaimahiId and providerId
 * GET    /deliveries/zone/:id  returns list of zone belonging to given id
 * PUT    /deliveries/zone/:id  updates a zone record for a given id, returns updated record
 * POST   /deliveries/zone      inserts a new zone record, return inserted record and its assigned Id
 * DELETE /deliveries/zone/:id   deletes zone record for a given id.
 */
export interface DeliveriesZone {
    // identifiers
    id: string;

    // ownership
    providerId: string; // Provider.id

    // details
    label: string;
    description?: string; // long

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
    isDeleted: boolean;
    isArchived: boolean;
}

export interface AllowedDeliveriesZoneFilters {
    providerId?: string;
    kaimahiId?: string;
    keywords?: string;
    limit?: number;
    offset?: number;
    total?: number;
}

/**
 * Kaimahi DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/kaimahi  returns list of kaimahi
 *                             will accept querystring to filter by: providerId
 * GET    /deliveries/kaimahi/:id  returns list of kaimahi belonging to given id
 * PUT    /deliveries/kaimahi/:id  updates a kaimahi record for a given id, returns updated record
 * POST   /deliveries/kaimahi      inserts a new kaimahi record, return inserted record and its assigned Id
 * DELETE /deliveries/kaimahi/:id   deletes kaimahi record for a given id.
 */
export interface DeliveriesKaimahi {
    // identifiers
    id: string;
    kaimahiId: string; // unique identifier (email?)
    systemId: string;

    // details
    firstName: string;
    lastName: string;

    // ownership
    providerId: string; // Provider.id
    zoneIds: Array<string>; // Array<Zone.id>

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id

    isDeleted?: boolean;
    isArchived?: boolean;

    defaultInstance: number;
    instances: any[];
    adminAccess: boolean;
    availableInstances: Array<string>;
}

export interface AllowedDeliveriesKaimahiFilters {
    providerId?: string;
    keywords?: string;
    limit?: number;
    offset?: number;
    total?: number;
}

/**
 * Provider DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/kaimahi/:id  returns list of kaimahi belonging to given id
 */
export interface DeliveriesProvider {
    // identifiers
    id: string;
    systemId: string;

    // details
    name: string;
    providerId: string; // Provider.id when has a parent

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id

    instances: Array<ProviderNavigatorInstance>;
}
export interface AllowedDeliveriesProviderFilters {
    providerId?: string;
    keywords?: string;
    limit?: number;
    offset?: number;
    total?: number;
}

/**
 * Attachment DTO
 *
 * API endpoints:
 *
 * POST   /deliveries/attachment      inserts a new attachment record
 * DELETE /deliveries/attachment/:id   deletes attachment record for a given id.
 */
export interface DeliveriesAttachment {
    // identifiers
    id: string;

    // ownership
    links: DeliveriesAttachmentLinks[]; // Delivery.id

    // details
    fileName: string;
    format: string; // mime-type?
    size: number; // bytes
    path: string;
    file?: string; //base64

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
}

export interface DeliveriesAttachmentLinks {
    entityId: string;
    linkType: 'Delivery'; // enum?
}

export interface AllowedDeliveriesAttachmentFilters {
    keywords?: string;
    limit?: number;
    offset?: number;
}

/**
 * DeliveriesInventory DTO
 *
 * API endpoints:
 *
 * GET    /deliveries/Inventory  returns list of Inventory.
 *                               will accept querystring to filter by: providerId
 * GET    /deliveries/Inventory/:id  returns list of Inventory belonging to given id
 * PUT    /deliveries/Inventory/:id  updates a Inventory record for a given id, returns updated record
 * POST   /deliveries/Inventory      inserts a new Inventory record, return inserted record and its assigned Id
 * DELETE /deliveries/Inventory/:id   deletes Inventory record for a given id.
 */
export interface DeliveriesInventory {
    // identifier
    id: string;

    // details
    providerId: string; // Provider.id
    provider: string; // Provider display Name
    numberOfItems: number; // Number of items received (can be negative)
    deliveryTypeId: string; // DeliveryType.Id
    deliveryType: string; // Display Name of DeliveryType
    notes: string; // Any comments about this item
    date: string; // Record of Inventory date
    modifiedOn: string;
    modifiedBy: string;
}

export interface AllowedDeliveriesInventoryFilters {
    keywords?: string;
    providerId?: string;
    limit?: number;
    offset?: number;
    total?: number;
}

export enum systemIdRecordType {
    individual = 'individual',
    family = 'family'
}

export enum DeliveriesDeliveryStatus {
    pending = 'Pending',
    delivered = 'Delivered',
    cancelled = 'Cancelled'
}

export interface ZoneBulkAssign {
    deliveryZone: DeliveryZone;
    deliveryTypes: any[];
    household: any[];
    kaimahi: any[];
    householdList?: any[];
    kaimahiList?: any[];
}

export interface DeliveryZone {
    id: string | number;
    label: string;
    description: string;
    providerId: number;
    private: boolean;
}

export interface DeliveryType {
    deliveryTypeId: number;
    numberOfItems: number;
}

export interface DeliveriesDeliveryBulkAssignDTOVerbose {
    // Delivery detail
    deliveryType: DeliveriesDeliveryType[];
    numberOfItems: string;
    deliveryTypeIds: any[];

    kaimahi: DeliveriesKaimahi[];

    // Ownership
    // groups: Array<DeliveriesDeliveryGroupDTOVerbose>;
    household: any[];
    remoteSourceQueryList: Array<DeliveriesRemoteSourceQueries>;
}

// export interface DeliveriesDeliveryGroupDTOVerbose {
//     zone: DeliveriesZone;
//     houseHolds: Array<DeliveriesHousehold>;
// }

export interface DeliveriesDeliveryBulkAssignDTO {
    // Delivery detail
    deliveryTypeId: string;
    numberOfItems: string;

    // Ownership
    groups: Array<DeliveriesDeliveryGroupDTO>;
}

export interface DeliveriesDeliveryGroupDTO {
    zoneId: string;
    houseHolds: Array<string>;
}

export interface DeliveriesRemoteSourceQuery {
    savedqueryid: string;
    name: string;
    description: string;
}

export interface DeliveriesRemoteSourceQueries {
    items: DeliveriesRemoteSourceQuery[];
}

export interface DeliveriesContacts {
    firstName: string;
    lastName: string;
    address: string;
}

export interface DeliveriesState {
    //Household
    household: {
        item: Partial<DeliveriesHousehold>;
        list: DeliveriesHousehold[];
        success: false;
        filters: AllowedDeliveriesHouseholdFilters;
    };

    householdFromQuery: {
        item: Partial<DeliveriesHousehold>;
        list: DeliveriesHousehold[];
        success: false;
        filters: AllowedDeliveriesHouseholdFilters;
    };

    //Delivery
    delivery: {
        item: Partial<DeliveriesDelivery>;
        list: DeliveriesDelivery[];
        success: false;
        filters: AllowedDeliveriesDeliveryFilters;
    };

    //DeliveryType
    deliveryType: {
        item: Partial<DeliveriesDeliveryType>;
        list: DeliveriesDeliveryType[];
        success: false;
        filters: AllowedDeliveriesDeliveryTypeFilters;
    };

    //Zone
    zone: {
        item: Partial<DeliveriesZone>;
        list: DeliveriesZone[];
        success: false;
        filters: AllowedDeliveriesZoneFilters;
    };

    //Kaimahi
    kaimahi: {
        item: Partial<DeliveriesKaimahi>;
        list: DeliveriesKaimahi[];
        success: false;
        filters: AllowedDeliveriesKaimahiFilters;
    };

    //Provider
    provider: {
        item: Partial<DeliveriesProvider>;
        list: DeliveriesProvider[];
        success: false;
        filters: AllowedDeliveriesProviderFilters;
    };

    //Attachment
    attachment: {
        item: Partial<DeliveriesAttachment>;
        list: DeliveriesAttachment[];
        success: false;
        filters: AllowedDeliveriesAttachmentFilters;
    };

    //Inventory
    inventory: {
        item: Partial<DeliveriesInventory>;
        list: DeliveriesInventory[];
        success: false;
        filters: AllowedDeliveriesInventoryFilters;
    };

    deliveryBulkAssign: DeliveriesDeliveryBulkAssignDTOVerbose;

    //DeliveryType
    navigatorInstance: {
        item: Partial<DeliveriesNavigatorInstance>;
        list: DeliveriesNavigatorInstance[];
        success: false;
        filters: AllowedNavigatorInstanceFilters;
    };

    kaimahiNavigatorInstance: {
        item: Partial<KaimahiNavigatorInstance>;
        list: KaimahiNavigatorInstance[];
        success: false;
        filters: AllowedNavigatorInstanceFilters;
    };

    providerNavigatorInstance: {
        item: Partial<KaimahiNavigatorInstance>;
        list: KaimahiNavigatorInstance[];
        success: false;
        filters: AllowedNavigatorInstanceFilters;
    };
}

export interface DeliveriesInstance {
    instance: [];
}

export interface instance {
    kaimahiId: number;
    kaimahiAccess: boolean;
    default: boolean;
    id: number;
}

export interface AllowedNavigatorInstanceFilters {
    keywords?: string;
    providerId?: string;
    limit?: number;
    offset?: number;
    total?: number;
    includeArchived?: boolean;
}

export interface DeliveriesNavigatorInstance {
    modifiedBy: any;
    modifiedOn: string;
    // identifiers
    id: string;

    // details
    navigatorId: string;
    uniqueName: string; // long
    urlName: string;
    url: string;
    friendlyName: string;

    // // ownership
    providerId: number; // Provider.id

    // // admin
    // createdOn: string; // ISO8601 date representation
    // createdBy: string; // Kaimahi.id or custom import source identifier
    // modifiedOn?: string; // ISO8601 date representation
    // modifiedBy?: string; // Kaimahi.id
    // isArchived: boolean;
}

export interface KaimahiNavigatorInstance {
    navigatorInstance: DeliveriesNavigatorInstance;
    kaimahiId: string;
    default: boolean;
    kaimahiAccess: boolean;
}

export interface ProviderNavigatorInstance {
    navigatorInstanceId: number;
    // providerId: number;
    providerAccess: boolean;
    default: boolean;
}

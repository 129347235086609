/**
 * Transform a URL compatible query string in a single dimension object
 */
export const querystringToObject = (value: string): { [key: string]: string } => {
    const query = value.substr(1);
    const result = {};

    query.split('&').forEach(function (part) {
        const item = part.split('=');
        result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
};

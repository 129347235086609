import { container, singleton } from 'tsyringe';

import { memoryStorage } from '../utils/memory-storage';
import { createUUID } from '../utils/uuid-utils';
import { StorageService, storageService } from './storage.service';

/**
 * NOTICE
 * localStorage and sessionStorage should never be used to store sensitive or personally identifiable data.
 * See the Browser Storage document in the OCC Sharepoint Developer Portal for more information.
 */

const browserSessionKey = 'WTUserSessionId';
const userSessionStorageEngine = typeof window !== 'undefined' ? localStorage : memoryStorage;

/**
 * Browser Session Service
 *
 * Basic handling of a user's browser session.
 * Auto starts by default
 */
@singleton()
export class BrowserSessionService {
    storageService: StorageService;

    constructor() {
        this.storageService = storageService;
    }

    /**
     * Retrieves active browser session id
     */
    public get sessionId(): string | null {
        return userSessionStorageEngine.getItem(browserSessionKey);
    }

    /**
     * Starts tracking a browser session and enable persistence of data stores
     *
     * Example:
     * ```js
     * var mySessionId = browserSessionService.start()
     * ```
     */
    public start(): void {
        // Do we have a session?
        if (this.sessionId === null) {
            userSessionStorageEngine.setItem(browserSessionKey, createUUID());
        }
        // Set the bucket name with the first portion of UUID
        this.storageService.setBucket(this.sessionId);
    }

    /**
     * Stops tracking a browser session,
     * disable persistence of data stores
     *
     * Example:
     * ```js
     * browserSessionService.stop()
     * ```
     */
    public stop(): void {
        userSessionStorageEngine.removeItem(browserSessionKey);
        this.storageService.resetBucket();
        this.reset();
    }

    /**
     * Resets local storage and flush cached data
     *
     * Example:
     * ```js
     * browserSessionService.reset()
     * ```
     */
    public reset(): void {
        this.storageService.flush();
    }

    /**
     * Wrapper for storage service setStorageEngine
     * @param engine
     */
    public setStorageEngine(engine: Storage): void {
        this.storageService.setStorageEngine(engine);
    }
}

/**
 * Global container hosted, singleton instance of BrowserSessionService
 * Use this preferably, unless specific need to create a secondary logger arises
 */
export const browserSessionService = container.resolve(BrowserSessionService);

import { AxiosInstance, AxiosResponse } from 'axios';

import * as CONTRACTS from './attachment.contracts';

export const storeAttachment = (item: Partial<CONTRACTS.Attachment>, client: AxiosInstance): any => {
    return client
        .post(`/attachment`, item)
        .then((response: AxiosResponse<CONTRACTS.Attachment>) => response.data)
        .catch(error => {
            throw error.response.data ? error.response.data.title : error.response.statusText;
        });
};

export const deleteAttachment = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/attachment/${id}`).catch(error => {
        throw error.response.data ? error.response.data.title : error.response.statusText;
    });

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State4ActionTypes {
    FetchItem = 'State4/fetchItem',
    SetState4NTWItem = 'State4/setState4NTWItem',
    SetState4_1NTWItem = 'State4/setState4_1NTWItem',
    SetState4_2NTWItem = 'State4/setState4_2NTWItem',
    SetState4_3NTWItem = 'State4/setState4_3NTWItem',
    SetState4_4NTWItem = 'State4/setState4_4NTWItem'
}
/**
 * Actions
 */
export const State4Actions = {
    setState4NTWItem: (item): ActionWithPayload<State4ActionTypes.SetState4NTWItem, { item }> =>
        createAction(State4ActionTypes.SetState4NTWItem, { item }),
    setState4_1NTWItem: (item): ActionWithPayload<State4ActionTypes.SetState4_1NTWItem, { item }> =>
        createAction(State4ActionTypes.SetState4_1NTWItem, { item }),
    setState4_2NTWItem: (item): ActionWithPayload<State4ActionTypes.SetState4_2NTWItem, { item }> =>
        createAction(State4ActionTypes.SetState4_2NTWItem, { item }),
    setState4_3NTWItem: (item): ActionWithPayload<State4ActionTypes.SetState4_3NTWItem, { item }> =>
        createAction(State4ActionTypes.SetState4_3NTWItem, { item }),
    setState4_4NTWItem: (item): ActionWithPayload<State4ActionTypes.SetState4_4NTWItem, { item }> =>
        createAction(State4ActionTypes.SetState4_4NTWItem, { item })
};

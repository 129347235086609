import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesAttachment = (state: any): Partial<CONTRACTS.DeliveriesAttachment> => {
    return state.deliveries.attachment.item || {};
};

export const getDeliveriesAttachmentList = (state: any): CONTRACTS.DeliveriesAttachment[] => {
    return state.deliveries.attachment.list || [];
};

export const getDeliveriesAttachmentFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesAttachmentFilters> => {
    return state.deliveries.attachment.filters || {};
};

export const getDeliveriesAttachmentSuccess = (state: any): boolean => {
    return state.deliveries.attachment.success || false;
};

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesKaimahiActionTypes {
    FetchKaimahiList = 'deleveries/fetchKaimahiList',
    SetKaimahiList = 'deleveries/setKaimahiList',
    ClearKaimahiList = 'deleveries/clearKaimahiList',
    UpdateKaimahiList = 'deliveries/updateKaimahiList',
    FetchKaimahi = 'deleveries/fetchKaimahi',
    FetchKaimahiAsUser = 'deleveries/fetchKaimahiAsUser',
    SetKaimahiSelectedInstance = 'deleveries/setKaimahiSelectedInstance',
    SetKaimahi = 'deleveries/setKaimahi',
    DeleteKaimahi = 'deliveries/deleteKaimahi',
    StoreKaimahi = 'deliveries/storeKaimahi',
    SetKaimahiFilters = 'deliveries/setKaimahiFilters',
    StoreKaimahiFilters = 'deliveries/storeKaimahiFilters',
    SetTotalInDeliveriesKaimahiFilters = 'deliveries/setTotalInDeliveriesKaimahiFilters',
    ClearKaimahiFilters = 'deliveries/clearKaimahiFilters',
    StoreDefaultInstance = 'deliveries/storeDefaultInstance',
    SetSubmitSuccess = 'deliveries/SetKaimahiSubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesKaimahiActions = {
    // retrieve Kaimahi items
    fetchDeliveriesKaimahiList: (): Action<DeliveriesKaimahiActionTypes.FetchKaimahiList> =>
        createAction(DeliveriesKaimahiActionTypes.FetchKaimahiList),

    // populate Kaimahi items
    setDeliveriesKaimahiList: (
        list
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.SetKaimahiList, { list: CONTRACTS.DeliveriesKaimahi[] }> =>
        createAction(DeliveriesKaimahiActionTypes.SetKaimahiList, { list }),

    // retrieve Kaimahi item
    fetchDeliveriesKaimahi: (id): any => createAction(DeliveriesKaimahiActionTypes.FetchKaimahi, id),

    // retrieve Kaimahi as user (based on token)
    fetchDeliveriesKaimahiAsUser: (): Action<DeliveriesKaimahiActionTypes.FetchKaimahiAsUser> =>
        createAction(DeliveriesKaimahiActionTypes.FetchKaimahiAsUser),

    // retrieve Kaimahi item
    setKaimahiSelectedInstance: (url): any =>
        createAction(DeliveriesKaimahiActionTypes.SetKaimahiSelectedInstance, url),

    // Update list after create and update
    updateDeliveriesKaimahiList: (
        item
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.UpdateKaimahiList, CONTRACTS.DeliveriesKaimahi> =>
        createAction(DeliveriesKaimahiActionTypes.UpdateKaimahiList, item),

    // populate Kaimahi item
    setDeliveriesKaimahi: (
        item
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.SetKaimahi, { item: CONTRACTS.DeliveriesKaimahi }> =>
        createAction(DeliveriesKaimahiActionTypes.SetKaimahi, { item }),

    // update Kaimahi item
    storeDeliveriesKaimahi: (
        item: CONTRACTS.DeliveriesKaimahi
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.StoreKaimahi, CONTRACTS.DeliveriesKaimahi> =>
        createAction(DeliveriesKaimahiActionTypes.StoreKaimahi, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesKaimahiActionTypes.SetSubmitSuccess, { success }),

    // clear Kaimahi list
    clearDeliveriesKaimahiList: (): Action<DeliveriesKaimahiActionTypes.ClearKaimahiList> =>
        createAction(DeliveriesKaimahiActionTypes.ClearKaimahiList),

    // populate Kaimahi filters
    setDeliveriesKaimahiFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters>
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.SetKaimahiFilters, CONTRACTS.AllowedDeliveriesKaimahiFilters> =>
        createAction(DeliveriesKaimahiActionTypes.SetKaimahiFilters, filters),

    storeDeliveriesKaimahiFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters>
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.StoreKaimahiFilters, CONTRACTS.AllowedDeliveriesKaimahiFilters> =>
        createAction(DeliveriesKaimahiActionTypes.StoreKaimahiFilters, filters),

    setTotalInDeliveriesKaimahiFilters: (
        total: number
    ): ActionWithPayload<DeliveriesKaimahiActionTypes.SetTotalInDeliveriesKaimahiFilters, number> =>
        createAction(DeliveriesKaimahiActionTypes.SetTotalInDeliveriesKaimahiFilters, total),

    // clear Kaimahi filters
    clearDeliveriesKaimahiFilters: (): Action<DeliveriesKaimahiActionTypes.ClearKaimahiFilters> =>
        createAction(DeliveriesKaimahiActionTypes.ClearKaimahiFilters),

    //delete Kaimahi
    deleteDeliveriesKaimahi: (id): any => createAction(DeliveriesKaimahiActionTypes.DeleteKaimahi, id),

    storeDefaultInstance: (item: any): ActionWithPayload<DeliveriesKaimahiActionTypes.StoreDefaultInstance, any> =>
        createAction(DeliveriesKaimahiActionTypes.StoreDefaultInstance, item)
};

export enum ValueType {
    Undefined,
    Null,
    String,
    Array,
    Object,
    Boolean,
    Number,
    Set,
    Map,
    Date,
    Unsupported
}

/**
 * Returns the type of a given value
 */
export const getTypeOfValue = (value: any): ValueType => {
    if (value === null) return ValueType.Null;
    if (Array.isArray(value)) return ValueType.Array;
    if (value instanceof Date) return ValueType.Date;
    if (value instanceof Set) return ValueType.Set;
    if (value instanceof Map) return ValueType.Map;

    switch (typeof value) {
        case 'undefined':
            return ValueType.Undefined;
        case 'string':
            return ValueType.String;
        case 'boolean':
            return ValueType.Boolean;
        case 'number':
            return ValueType.Number;
        case 'object':
            return ValueType.Object;
        default:
            return ValueType.Unsupported;
    }
};

import { all, call, put, takeEvery } from 'redux-saga/effects';

import { restService } from '../../services';
import { authActions } from '../_auth';
import { systemActions, SystemStatus } from '../_system';
import { deliveryActions, DeliveryActionTypes } from './delivery.actions';
import * as API from './delivery.api';
import { DeliveryStatus } from './delivery.contracts';
import { fixNullable } from './delivery.helpers';

// Little helper function to abstract going to different pages
// TODO: move somewhere else, clearly reusable
export function forwardTo(history: any, location: string, replace?: boolean): void {
    replace ? history.replace(location) : history.push(location);
}

export function* fetchDelivery(action): any {
    // set filters from action
    const filters =
        action.payload.filters.zoneId && action.payload.filters.zoneId !== ''
            ? {
                  // zone overrules kaimahi
                  ...action.payload.filters,
                  kaimahiId: ''
              }
            : action.payload.filters;

    if (filters !== {}) {
        // Set loading status
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try log in the user
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchList, filters, restService.client);
            yield put(deliveryActions.setDeliveryList(Array.isArray(response.data) ? response.data : []));
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* fetchKaimahi(action): any {
    if (action.payload) {
        // Set loading status
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try log in the user
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchKaimahiListByProvider, action.payload, restService.client);
            yield put(deliveryActions.setKaimahiList(Array.isArray(response.data) ? response.data : []));
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* fetchZones(action): any {
    if (action.payload) {
        // Set loading status
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try log in the user
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchZoneListByProvider, action.payload, restService.client);
            yield put(deliveryActions.setZoneList(Array.isArray(response.data) ? response.data : []));
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* getDeliveryItem(action): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try fetch client with given ID
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.fetchItem, action.payload, restService.client);
        yield put(deliveryActions.setDeliveryItem(fixNullable(response.data)));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* storeDeliveryItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        response = yield call(API.storeItem, action.payload.item, restService.client);
        yield put(deliveryActions.setDeliveryItem(response.data));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        return false;
    } finally {
        yield all([
            // Back to idle
            put(systemActions.setStatus(SystemStatus.idle))
        ]);
        // check if new
        if (action.payload.item.id !== response.data.id) {
            // Redirect
            yield call(forwardTo, action.payload.history, `/deliveries/item/${response.data.id}`, true);
        }
        if (response.data.status !== DeliveryStatus.pending) {
            // Redirect
            yield call(forwardTo, action.payload.history, `/deliveries`);
        }
    }
}

export function* fetchUser(action): any {
    if (action.payload) {
        // Set loading status
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try log in the user
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchKaimahiById, action.payload, restService.client);
            yield all([
                put(authActions.setUserProfile(Array.isArray(response.data) ? response.data[0] : null)),
                put(
                    // deliveryActions.fetchKaimahiListByProvider(
                    //     Array.isArray(response.data) ? response.data[0].providerId : null
                    // )
                    deliveryActions.fetchZoneListByProvider(
                        Array.isArray(response.data) ? response.data[0].providerId : null
                    )
                ),
                put(
                    deliveryActions.setDeliveryFilters({
                        kaimahiId: action.payload,
                        providerId: Array.isArray(response.data) ? response.data[0].providerId : null,
                        keywords: ''
                    })
                )
            ]);
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

/**
 * Public Sagas
 */
export function* rootDeliverySaga(): any {
    yield takeEvery(DeliveryActionTypes.FetchKaimahiById, fetchUser);
    yield takeEvery(DeliveryActionTypes.SetDeliveryFilters, fetchDelivery);
    yield takeEvery(DeliveryActionTypes.FetchZoneListByProvider, fetchZones);
    yield takeEvery(DeliveryActionTypes.FetchKaimahiListByProvider, fetchKaimahi);
    yield takeEvery(DeliveryActionTypes.FetchDeliveryItem, getDeliveryItem);
    yield takeEvery(DeliveryActionTypes.StoreDeliveryItem, storeDeliveryItem);
}

import { ActionsUnion } from '../../utils/action.helpers';
import { OtherActions } from './Other/actions';
import { State1Actions } from './State-1/actions';
import { State2Actions } from './State-2/actions';
import { State3Actions } from './State-3/actions';
import { State4Actions } from './State-4/actions';
import { State5Actions } from './State-5/actions';
import { State6Actions } from './State-6/actions';
import { State7Actions } from './State-7/actions';

/**
 * Actions
 */
export const NTWReportActions = {
    ...State1Actions,
    ...State2Actions,
    ...State3Actions,
    ...State4Actions,
    ...State5Actions,
    ...State6Actions,
    ...State7Actions,
    ...OtherActions
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type NTWReportActionsUnion = ActionsUnion<typeof NTWReportActions>;

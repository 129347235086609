import * as CONTRACTS from './contracts';

export const getCasenote = (state: any): Partial<CONTRACTS.Casenote> => {
    return state.casenote.item || {};
};

export const getCasenoteList = (state: any): CONTRACTS.Casenote[] => {
    return state.casenote.list || [];
};

export const getCasenoteFilters = (state: any): Partial<CONTRACTS.AllowedCasenoteFilters> => {
    return state.casenote.filters || {};
};

import * as CONTRACTS from '../deliveries.contracts';

/**
 * DeliveryType Selectors
 */
export const getDeliveriesNavigatorInstance = (state: any): Partial<CONTRACTS.DeliveriesNavigatorInstance> => {
    return state.deliveries.navigatorInstance.item || {};
};

export const getDeliveriesNavigatorInstanceList = (state: any): CONTRACTS.DeliveriesNavigatorInstance[] => {
    return state.deliveries.navigatorInstance.list || [];
};

export const getDeliveriesNavigatorInstanceFilters = (
    state: any
): Partial<CONTRACTS.AllowedNavigatorInstanceFilters> => {
    return state.deliveries.navigatorInstance.filters || {};
};

export const getKaimahiNavigatorInstanceList = (state: any): any => {
    return state.deliveries.kaimahiNavigatorInstance.list || [];
};

export const getNavigatorInstanceSuccess = (state: any): boolean => {
    return state.deliveries.navigatorInstance.success || false;
};

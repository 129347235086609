import produce from 'immer';

import { DeliveryActionsUnion, DeliveryActionTypes } from './delivery.actions';
import { DeliveryState } from './delivery.contracts';

/**
 * Reducer
 */
export const DELVIERY_REDUCER_DEFAULT: DeliveryState = { list: [], item: {}, filters: {}, zones: [] };

export const deliveryReducer = (state: DeliveryState, action: DeliveryActionsUnion): DeliveryState =>
    produce(state || DELVIERY_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case DeliveryActionTypes.SetDeliveryList:
                draft.list = action.payload.list;
                break;
            case DeliveryActionTypes.ClearDeliveryList:
                draft.list = [];
                break;
            case DeliveryActionTypes.SetDeliveryItem:
                draft.item = action.payload.item;
                break;
            case DeliveryActionTypes.SetDeliveryFilters:
                // clean null values
                // Object.keys(action.payload.filters).forEach(
                //     key => action.payload.filters[key] == null && delete action.payload.filters[key]
                // );
                draft.filters = action.payload.filters;
                break;
            case DeliveryActionTypes.ClearDeliveryFilters:
                draft.filters = {};
                break;
            case DeliveryActionTypes.SetZoneList:
                draft.zones = action.payload.list;
                break;
            default:
                return draft;
        }
    });

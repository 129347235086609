import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum CaseloadActionTypes {
    FetchCaseloadList = 'caseload/fetchCaseloadList',
    SetCaseloadList = 'caseload/setCaseloadList',
    ClearCaseloadList = 'caseload/clearCaseloadList',
    UpdateCaseloadList = 'caseload/updateCaseloadList',
    FetchCaseload = 'caseload/fetchCaseload',
    SetCaseload = 'caseload/setCaseload',
    DeleteCaseload = 'caseload/deleteCaseload',
    StoreCaseload = 'caseload/storeCaseload',
    SetCaseloadFilters = 'caseload/setCaseloadFilters',
    StoreCaseloadFilters = 'caseload/storeCaseloadFilters',
    SetTotalInCaseloadFilters = 'caseload/setTotalInCaseloadFilters',
    ClearCaseloadFilters = 'caseload/clearCaseloadFilters'
}

/**
 * Actions
 */
export const planCaseloadActions = {
    // retrieve Caseload items
    fetchCaseloadList: (): Action<CaseloadActionTypes.FetchCaseloadList> =>
        createAction(CaseloadActionTypes.FetchCaseloadList),

    // populate Caseload items
    setCaseloadList: (list): ActionWithPayload<CaseloadActionTypes.SetCaseloadList, { list: CONTRACTS.Caseload[] }> =>
        createAction(CaseloadActionTypes.SetCaseloadList, { list }),

    // Update list after create and update
    updateCaseloadList: (item): ActionWithPayload<CaseloadActionTypes.UpdateCaseloadList, CONTRACTS.Caseload> =>
        createAction(CaseloadActionTypes.UpdateCaseloadList, item),

    // retrieve Caseload item
    fetchCaseload: (id): any => createAction(CaseloadActionTypes.FetchCaseload, id),

    // populate Caseload item
    setCaseload: (item): ActionWithPayload<CaseloadActionTypes.SetCaseload, { item: CONTRACTS.Caseload }> =>
        createAction(CaseloadActionTypes.SetCaseload, { item }),

    // update Caseload item
    storeCaseload: (
        item: CONTRACTS.Caseload
    ): ActionWithPayload<CaseloadActionTypes.StoreCaseload, CONTRACTS.Caseload> =>
        createAction(CaseloadActionTypes.StoreCaseload, item),

    // clear Caseload list
    clearCaseloadList: (): Action<CaseloadActionTypes.ClearCaseloadList> =>
        createAction(CaseloadActionTypes.ClearCaseloadList),

    // populate Caseload filters
    setCaseloadFilters: (
        filters: Partial<CONTRACTS.AllowedCaseloadFilters>
    ): ActionWithPayload<CaseloadActionTypes.SetCaseloadFilters, CONTRACTS.AllowedCaseloadFilters> =>
        createAction(CaseloadActionTypes.SetCaseloadFilters, filters),

    storeCaseloadFilters: (
        filters: Partial<CONTRACTS.AllowedCaseloadFilters>
    ): ActionWithPayload<CaseloadActionTypes.StoreCaseloadFilters, CONTRACTS.AllowedCaseloadFilters> =>
        createAction(CaseloadActionTypes.StoreCaseloadFilters, filters),

    setTotalInCaseloadFilters: (
        total: number
    ): ActionWithPayload<CaseloadActionTypes.SetTotalInCaseloadFilters, number> =>
        createAction(CaseloadActionTypes.SetTotalInCaseloadFilters, total),

    // clear Caseload filters
    clearCaseloadFilters: (): Action<CaseloadActionTypes.ClearCaseloadFilters> =>
        createAction(CaseloadActionTypes.ClearCaseloadFilters),

    //delete Caseload
    deleteCaseload: (id): any => createAction(CaseloadActionTypes.DeleteCaseload, id)
};

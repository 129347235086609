import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum OtherActionTypes {
    SetItem = 'Other/setItem',
    StoreItem = 'Other/storeItem',
    SetTerms = 'Other/setTerms',
    SetActiveStep = 'Other/setActiveStep',
    SetSuccess = 'Other/setSuccess',
    SetResponse = 'Other/setResponse'
}
/**
 * Actions
 */
export const OtherActions = {
    setItem: (key): ActionWithPayload<OtherActionTypes.SetItem, { key: string }> =>
        createAction(OtherActionTypes.SetItem, { key }),
    setTerms: (terms: boolean): ActionWithPayload<OtherActionTypes.SetTerms, { terms: boolean }> =>
        createAction(OtherActionTypes.SetTerms, { terms }),
    setActiveStep: (activeStep: number): ActionWithPayload<OtherActionTypes.SetActiveStep, { activeStep: number }> =>
        createAction(OtherActionTypes.SetActiveStep, { activeStep }),
    setSuccess: (success: boolean): ActionWithPayload<OtherActionTypes.SetSuccess, { success: boolean }> =>
        createAction(OtherActionTypes.SetSuccess, { success }),
    setResponse: (response: string): ActionWithPayload<OtherActionTypes.SetResponse, { response: string }> =>
        createAction(OtherActionTypes.SetResponse, { response })
};

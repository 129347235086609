import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { CasenoteActionTypes, planCasenoteActions } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';
import { getCasenoteFilters } from './selectors';

/**
 * Casenote Sagas
 */
export function* fetchCasenoteList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedCasenoteFilters> = yield select(getCasenoteFilters);
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchCasenoteList, filters, restService.client);
        // Update store
        yield put(planCasenoteActions.setCasenoteList(Array.isArray(response.items) ? response.items : []));

        yield put(planCasenoteActions.setTotalInCasenoteFilters(response.total));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchCasenote(action): any {
    const casenoteId = action.payload;
    if (casenoteId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the delevery
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchCasenote, casenoteId, restService.client);
            yield put(planCasenoteActions.setCasenote(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeCasenote(action): any {
    let response;

    const casenote: CONTRACTS.Casenote = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the delevery
    try {
        //Call update api
        response = yield call(API.storeCasenote, casenote, restService.client);
        yield put(planCasenoteActions.setCasenote(response));
        //update the list after update or create
        yield put(planCasenoteActions.updateCasenoteList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage, response.id));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteCasenote(action): any {
    const casenoteId = action.payload;
    if (casenoteId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteCasenote, casenoteId, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setCasenoteFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedCasenoteFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedCasenoteFilters> = yield select(getCasenoteFilters);

    //Update filters
    yield put(planCasenoteActions.storeCasenoteFilters({ ...existingFilters, ...filters }));

    yield* fetchCasenoteList(action);
}

export const planCasenoteSagas = [
    takeEvery(CasenoteActionTypes.FetchCasenoteList, fetchCasenoteList),
    takeEvery(CasenoteActionTypes.SetCasenoteFilters, setCasenoteFilters),
    takeEvery(CasenoteActionTypes.FetchCasenote, fetchCasenote),
    takeEvery(CasenoteActionTypes.StoreCasenote, storeCasenote),
    takeEvery(CasenoteActionTypes.DeleteCasenote, deleteCasenote)
];

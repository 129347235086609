import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

export const fetchInventoryList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/inventory?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesInventory[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchInventory = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/inventory/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesInventory>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeInventory = (item: Partial<CONTRACTS.DeliveriesInventory>, client: AxiosInstance): any => {
    if (item.id !== '0') {
        return client
            .put(`/deliveries/inventory/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesInventory>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/inventory`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesInventory>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteInventory = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/inventory/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

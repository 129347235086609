import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { State1Actions, State1ActionTypes } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';

/**
 * Nhi Sagas
 */
// export function* fetchNhiList(action): any {
//     // Set loading status & clear messages
//     const nhiData = action.payload;
//     if (nhiData) {
//         yield put(systemActions.setStatus(SystemStatus.loading));
//         yield put(systemActions.clearMessage());
//         try {
//             // set filters from selector
//             // Call API (asynchronous, so yield)
//             const response = yield call(API.fetchNhiList, nhiData, restService.client);
//             // Update store
//             yield put(nhiActions.setNhiList(Array.isArray(response.records) ? response.records : []));

//             yield put(nhiActions.setTotalInNhiFilters(response.total));
//             if (response.records.length !== 0) {
//                 yield put(NavigatorActions.setData(false));
//             } else {
//                 yield put(NavigatorActions.setData(true));
//             }
//             // Display message
//             yield put(setSuccessMessage(SystemMessageType.refresh));
//         } catch (error) {
//             // If we get an error, set the message
//             yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
//             return false;
//         } finally {
//             // Back to idle
//             yield put(systemActions.setStatus(SystemStatus.idle));
//         }
//     }
// }

// export function* fetchNhi(action): any {
//     const NhiId = action.payload;
//     if (NhiId) {
//         yield put(systemActions.setStatus(SystemStatus.loading));
//         yield put(systemActions.clearMessage());

//         // Try to delete the delevery
//         try {
//             // Call Auth API (asynchronous, so yield)
//             const response = yield call(API.fetchNhi, NhiId, restService.client);
//             yield put(nhiActions.setNhi(response.records[0]));
//         } catch (error) {
//             // If we get an error, set the message
//             yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
//             return false;
//         } finally {
//             // Back to idle
//             yield put(systemActions.setStatus(SystemStatus.idle));
//         }
//     }
// }

// export function* setState1Item(action): any {
//     // set filters from action
//     const item: Partial<CONTRACTS.Item> = action.payload;
//     //Update filters
//     yield put(State1Actions.setState1Item(item));
// }

export const State1Sagas = [
    // takeEvery(nhiActionTypes.FetchNhiList, fetchNhiList),
    // takeEvery(State1ActionTypes.SetState1Item, setState1Item)
    // takeEvery(nhiActionTypes.FetchNhi, fetchNhi)
];

// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place
import { call, fork, put, race, take } from 'redux-saga/effects';

import { Logger } from '@whanau/diagnostics';

import { configurationService, storageService } from '../../services';
import { restService } from '../../services/rest-client.service';
import { systemActions, SystemMessageSeverity, SystemMessageType, SystemStatus } from '../_system';
import { authActions, AuthActionTypes } from './auth.actions';
import * as API from './auth.api';
import {
    AUTH_USER_REFRESH_TOKEN,
    AUTH_USER_SELECTED_INSTANCE_URL,
    AUTH_USER_TOKEN,
    UserCredentials
} from './auth.contracts';

// Little helper function to abstract going to different pages
// TODO: move somewhere else, clearly reusable
export function forwardTo(history: any, location: string): void {
    Logger.info('Forwarding to', location);
    history.push(location);
}

export function persistAuth(data: any): void {
    // Add token to Storage
    data.access_token && storageService.set(AUTH_USER_TOKEN, data.access_token);
    data.refresh_token && storageService.set(AUTH_USER_REFRESH_TOKEN, data.refresh_token);
    data.instanceUrlName && storageService.set(AUTH_USER_SELECTED_INSTANCE_URL, data.instanceUrlName);
    // Setup axios default auth headers
    restService.setAuthHeaders(data.access_token, data.instanceUrlName, data.navigatorId);
}

/**
 * Effect to handle authorization
 * @param  {string} username               The username of the user
 * @param  {string} password               The password of the user
 * @param  {object} options                Options
 * @param  {boolean} options.isRegistering Is this a register request?
 */
export function* authorize(userCredentials: UserCredentials): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try log in the user
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(
            API.validateUser,
            { ...userCredentials, email: userCredentials.username } as UserCredentials,
            restService.client
        );
        return response;
    } catch (error) {
        Logger.warn('Something went wrong with the auth request', error);
        // If we get an error, set the message
        yield put(
            systemActions.setMessage({
                message: error.response,
                type: SystemMessageType.security,
                severity: SystemMessageSeverity.error
            })
        );
        return false;
    } finally {
        // No matter what, back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

/**
 * Log in saga
 */
export function* loginFlow(): any {
    while (true) {
        const request = yield take(AuthActionTypes.ValidateUser);
        // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
        // lead to a race condition. This is unlikely, but just in case, we call `race` which
        // returns the "winner", the one that finished first
        const winner = yield race({
            auth: call(authorize, request.payload.userCredentials),
            logout: take(AuthActionTypes.LogoutUser)
        });
        // If `authorize` was the winner & we have a token
        if (winner.auth && (winner.auth.data.access_token || winner.auth.data.accessToken)) {
            // Handle JaSON test framework auth
            if (winner.auth.data.accessToken) {
                winner.auth.data.access_token = winner.auth.data.accessToken;
            }
            // Send Redux appropiate actions
            yield put(authActions.setUserToken(winner.auth.data.access_token));
            // Persist to storage
            yield call(persistAuth, winner.auth.data);
            // Redirect
            yield call(forwardTo, request.payload.history, configurationService.get('auth.successRedirectPage'));
        }
    }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export function* logoutFlow(): any {
    while (true) {
        const request = yield take(AuthActionTypes.LogoutUser);
        // Clear token via Redux action
        yield put(authActions.setUserToken());
        // Clear token from Storage
        storageService.remove(AUTH_USER_TOKEN);
        storageService.remove(AUTH_USER_REFRESH_TOKEN);
        storageService.remove(AUTH_USER_SELECTED_INSTANCE_URL);
        // Redirect to App's main page
        yield call(forwardTo, request.payload.history, '/');
    }
}

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* rootAuthSaga(): any {
    yield fork(loginFlow);
    yield fork(logoutFlow);
}

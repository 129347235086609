import { AxiosInstance, AxiosResponse } from 'axios';

//import { objectToQuerystring } from '../../utils/object-to-querystring';
import { Client, ClientSummaryList, ClientSummaryListFilters } from './client.contracts';

export const searchList = (filters: Partial<ClientSummaryListFilters>, client: AxiosInstance): any => {
    const queryString = [];
    if (filters.NHINumber) {
        queryString.push(`nhiNumber_like=${filters.NHINumber}`);
    }
    if (filters.NASCID) {
        queryString.push(`organisationCode_like=${filters.NASCID}`);
    }
    if (filters.clientFirstName) {
        queryString.push(`givenName_like=${filters.clientFirstName}`);
    }
    if (filters.clientSurname) {
        queryString.push(`surname_like=${filters.clientSurname}`);
    }

    return client
        .get(`/clients?pageNumber=1`)
        .then((response: AxiosResponse<ClientSummaryList>) => response)
        .catch(error => ({ error }));
};

export const loadList = (client: AxiosInstance): any => {
    return client
        .get('/clients?pageNumber=1')
        .then((response: AxiosResponse<ClientSummaryList>) => response)
        .catch(error => ({ error }));
};

export const fetchDetail = (clientId: string | number, client: AxiosInstance): any => {
    return client
        .get(`/clients/${clientId}`)
        .then((response: AxiosResponse<Client>) => response)
        .catch(error => ({ error }));
};

export const updateClient = (clientDetail: Client, client: AxiosInstance): any => {
    const clientIdentifier = clientDetail.clientDetails.clientID;
    return client
        .put(`/clients/${clientIdentifier}`, clientDetail)
        .then((response: AxiosResponse<Client>) => response)
        .catch(error => ({ error }));
};

export const createClient = (clientDetail: Client, client: AxiosInstance): any => {
    return client
        .post(`/clients`, clientDetail)
        .then((response: AxiosResponse<Client>) => response)
        .catch(error => ({ error }));
};

import * as CONTRACTS from './contracts';

export const getTermsNTW = (state: any): boolean => {
    return state.NTWReport.Other.terms || false;
};
export const getActiveStepNTW = (state: any): number => {
    return state.NTWReport.Other.activeStep || 1;
    //return 6;
};
export const getOtherItem = (state: any): Partial<CONTRACTS.item> => {
    return state.NTWReport.Other.item || {};
};
// export const getSuccess = (state: any): boolean => {
//     return state.ChildHub.Other.success || false;
// };
// export const getResponse = (state: any): string => {
//     return state.ChildHub.Other.response || '';
// };

import produce from 'immer';

import { updateArrayObjectWithObject } from '../../utils/update-arrayObject-with-object';
import { NavigatorActionTypes } from './navigator/actions';
import { nhiActionTypes } from './nhi/actions';
import { nhiLookupActionsUnion } from './nhiLookup.actions';
import { NhiLookupState } from './nhiLookup.contracts';

/**
 * Deliveries Reducers
 */
export const INITIAL_nhiLookup_PROPERTIES: any = {
    list: [],
    item: {},
    id: '',
    filters: { limit: 5, offset: 5, total: 10 }
};
export const INITIAL_navigator_PROPERTIES: any = {
    list: [],
    item: {},
    id: '',
    data: false,
    success: false,
    filters: { limit: 5, offset: 5, total: 10 }
};

export const nhiLookup_REDUCER_DEFAULT = {
    nhi: INITIAL_nhiLookup_PROPERTIES,
    navigator: INITIAL_navigator_PROPERTIES
};

export const nhiLookupReducer = (state: NhiLookupState, action: nhiLookupActionsUnion): NhiLookupState =>
    produce(state || nhiLookup_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            //nhi
            case nhiActionTypes.SetNhiList:
                draft.nhi.list = action.payload.list;
                break;
            case nhiActionTypes.ClearNhiList:
                draft.nhi.list = [];
                break;
            case nhiActionTypes.UpdateNhiList:
                draft.nhi.list = updateArrayObjectWithObject(draft.nhi.list, action.payload) as any;
                break;
            case nhiActionTypes.SetNhi:
                draft.nhi.item = action.payload.item;
                break;
            case nhiActionTypes.StoreNhiFilters:
                draft.nhi.filters = action.payload;
                break;
            case nhiActionTypes.SetTotalInNhiFilters:
                draft.nhi.filters = { ...draft.nhi.filters, total: action.payload };
                break;
            case nhiActionTypes.ClearNhiFilters:
                draft.nhi.filters = {};
                break;
            case nhiActionTypes.SetNHIItemId:
                draft.nhi.id = action.payload.id;
                break;
            case nhiActionTypes.ClearNHIItemId:
                draft.nhi.id = '';
                break;
            case NavigatorActionTypes.SetNavigatorList:
                draft.navigator.list = action.payload.list;
                break;
            case NavigatorActionTypes.ClearNavigatorList:
                draft.navigator.list = [];
                break;
            case NavigatorActionTypes.UpdateNavigatorList:
                draft.navigator.list = updateArrayObjectWithObject(draft.navigator.list, action.payload) as any;
                break;
            case NavigatorActionTypes.SetNavigator:
                draft.navigator.item = action.payload.item;
                break;
            case NavigatorActionTypes.SetData:
                draft.navigator.data = action.payload.data;
                break;
            case NavigatorActionTypes.StoreNavigatorFilters:
                draft.navigator.filters = action.payload;
                break;
            case NavigatorActionTypes.SetTotalInNavigatorFilters:
                draft.navigator.filters = { ...draft.navigator.filters, total: action.payload };
                break;
            case NavigatorActionTypes.ClearNavigatorFilters:
                draft.navigator.filters = {};
                break;
            case NavigatorActionTypes.SetItemId:
                draft.navigator.id = action.payload.id;
                break;
            case NavigatorActionTypes.ClearItemId:
                draft.navigator.id = '';
                break;
            case NavigatorActionTypes.SetSuccess:
                draft.navigator.success = action.payload.success;
                break;
            case NavigatorActionTypes.SetNavigatorInstanceList:
                draft.navigator.instances = action.payload.list;
                break;
            case NavigatorActionTypes.SetNavigatorInstance:
                draft.navigator.instanceSelected = action.payload.item;
                break;
            case NavigatorActionTypes.SetShowModalForInstance:
                draft.navigator.showModalForInstance = action.payload.showModalForInstance;
                break;
            default:
                return draft;
        }
    });

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesProviderActionTypes {
    FetchProviderList = 'deleveries/fetchProviderList',
    SetProviderList = 'deleveries/setProviderList',
    ClearProviderList = 'deleveries/clearProviderList',
    FetchProvider = 'deleveries/fetchProvider',
    SetProvider = 'deleveries/setProvider',
    DeleteProvider = 'deliveries/deleteProvider',
    StoreProvider = 'deliveries/storeProvider',
    SetProviderFilters = 'deliveries/setProviderFilters',
    ClearProviderFilters = 'deliveries/clearProviderFilters',
    StoreProviderNavigatorInstance = 'deliveries/storeProviderNavigatorInstance',
    SetTotalInDeliveriesProviderFilters = 'deliveries/setTotalInDeliveriesProviderFilters',
    SetDeliveriesInstanceListByProvider = 'deliveries/setDeliveriesInstanceListByProvider',
    FetchNavigatorInstanceListByProvider = 'deliveries/fetchNavigatorInstanceListByProvider',
    StoreProviderFilters = 'deliveries/storeProviderFilters',
    SetSubmitSuccess = 'deliveries/setProviderSubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesProviderActions = {
    // retrieve Provider items
    fetchDeliveriesProviderList: (): Action<DeliveriesProviderActionTypes.FetchProviderList> =>
        createAction(DeliveriesProviderActionTypes.FetchProviderList),

    // populate Provider items
    setDeliveriesProviderList: (
        list: CONTRACTS.DeliveriesProvider[]
    ): ActionWithPayload<DeliveriesProviderActionTypes.SetProviderList, { list: CONTRACTS.DeliveriesProvider[] }> =>
        createAction(DeliveriesProviderActionTypes.SetProviderList, { list }),

    // retrieve Provider item
    fetchDeliveriesProvider: (id): any => createAction(DeliveriesProviderActionTypes.FetchProvider, id),

    // populate Provider item
    setDeliveriesProvider: (
        item
    ): ActionWithPayload<DeliveriesProviderActionTypes.SetProvider, { item: CONTRACTS.DeliveriesProvider }> =>
        createAction(DeliveriesProviderActionTypes.SetProvider, { item }),

    // update Provider item
    storeDeliveriesProvider: (
        item: CONTRACTS.DeliveriesProvider
    ): ActionWithPayload<DeliveriesProviderActionTypes.StoreProvider, CONTRACTS.DeliveriesProvider> =>
        createAction(DeliveriesProviderActionTypes.StoreProvider, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesProviderActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesProviderActionTypes.SetSubmitSuccess, { success }),

    // clear Provider list
    clearDeliveriesProviderList: (): Action<DeliveriesProviderActionTypes.ClearProviderList> =>
        createAction(DeliveriesProviderActionTypes.ClearProviderList),

    // populate Provider filters
    setDeliveriesProviderFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters>
    ): ActionWithPayload<
        DeliveriesProviderActionTypes.SetProviderFilters,
        CONTRACTS.AllowedDeliveriesProviderFilters
    > => createAction(DeliveriesProviderActionTypes.SetProviderFilters, filters),

    // clear Provider filters
    clearDeliveriesProviderFilters: (): Action<DeliveriesProviderActionTypes.ClearProviderFilters> =>
        createAction(DeliveriesProviderActionTypes.ClearProviderFilters),

    //delete Provider
    deleteDeliveriesProvider: (id): any => createAction(DeliveriesProviderActionTypes.DeleteProvider, id),
    storeProviderNavigatorInstances: (
        item: CONTRACTS.ProviderNavigatorInstance[]
    ): ActionWithPayload<
        DeliveriesProviderActionTypes.StoreProviderNavigatorInstance,
        CONTRACTS.ProviderNavigatorInstance[]
    > => createAction(DeliveriesProviderActionTypes.StoreProviderNavigatorInstance, item),
    setTotalInDeliveriesProviderFilters: (
        total: number
    ): ActionWithPayload<DeliveriesProviderActionTypes.SetTotalInDeliveriesProviderFilters, number> =>
        createAction(DeliveriesProviderActionTypes.SetTotalInDeliveriesProviderFilters, total),
    setDeliveriesInstanceListByProvider: (
        instances: any
    ): ActionWithPayload<DeliveriesProviderActionTypes.SetDeliveriesInstanceListByProvider, any> =>
        createAction(DeliveriesProviderActionTypes.SetDeliveriesInstanceListByProvider, instances),

    fetchDeliveriesNavigatorInstanceListByProvider: (id): any =>
        createAction(DeliveriesProviderActionTypes.FetchNavigatorInstanceListByProvider, id),

    storeDeliveriesProviderFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters>
    ): ActionWithPayload<
        DeliveriesProviderActionTypes.StoreProviderFilters,
        CONTRACTS.AllowedDeliveriesProviderFilters
    > => createAction(DeliveriesProviderActionTypes.StoreProviderFilters, filters)
};

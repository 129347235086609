import { ISagaModule } from 'redux-dynamic-modules-saga';

//import { deliveriesActions } from './deliveries.actions';
import { DeliveriesState } from './deliveries.contracts';
import { deliveriesReducer } from './deliveries.reducer';
import { rootDeliveriesSaga } from './deliveries.saga';

// depends on authModule
// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getDeliveriesModule(): [ISagaModule<DeliveriesState>] {
    return [
        {
            // Unique id of the module
            id: 'deliveries',
            // Maps the Store key to the reducer
            reducerMap: {
                deliveries: deliveriesReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootDeliveriesSaga]
        }
    ];
}

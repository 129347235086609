import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ChildHubActions } from '../';
import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { State4Actions, State4ActionTypes } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';

export function* storeReferralItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api

        response = yield call(API.storeItem, action.payload, restService.client);
        yield put(ChildHubActions.setReferralItem(response.data));
        if (response.data) {
            yield put(ChildHubActions.setSuccess(true));
            yield put(ChildHubActions.setActiveStep(7));
        } else {
            yield put(ChildHubActions.setSuccess(false));
            yield put(ChildHubActions.setActiveStep(5));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(ChildHubActions.setActiveStep(5));
        yield put(ChildHubActions.setSuccess(false));
        return false;
    }
}

export const State4Sagas = [
    // takeEvery(nhiActionTypes.FetchNhiList, fetchNhiList),
    // takeEvery(nhiActionTypes.SetNhiFilters, setNhiFilters),
    // takeEvery(nhiActionTypes.FetchNhi, fetchNhi)
    takeEvery(State4ActionTypes.StoreReferralItem, storeReferralItem)
];

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getDeliveriesHouseholdFromQueryFilters } from '@whanau/domains';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { getInstanceNameUrl } from '../../_auth/auth.selectors';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import * as HouseholdAPI from '../household/api';
import { deliveriesZoneActions } from '../zone/actions';
import { deliveriesDeliveryBulkAssignActions, DeliveriesDeliveryBulkAssignActionTypes } from './actions';
import * as API from './api';

// import { cleanDeliveryBulkAssignDTO } from './helper';

/**
 * Bulk Assign Sagas
 */

export function* storeDeliveryBulkAssign(action): any {
    // const dataRaw: CONTRACTS.DeliveriesDeliveryBulkAssignDTOVerbose = action.payload;
    // const data = cleanDeliveryBulkAssignDTO(dataRaw);

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        //Call  api
        yield call(API.storeBulkAssign, action.payload, restService.client);
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesZoneActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesZoneActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchRemoteSourceQueryList(action): any {
    const navigatorInstance = action.payload;
    yield put(systemActions.clearMessage());
    try {
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchRemoteSourceQueryList, navigatorInstance, restService.client);
        // Update store
        yield put(
            deliveriesDeliveryBulkAssignActions.setRemoteSourceQueryList(
                response
                // Array.isArray(response.items) ? response.items : []
            )
        );

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    }
}

export function* fetchContactsFromQuery(action): any {
    const savedQueryId = action.payload;
    yield put(systemActions.clearMessage());
    try {
        // Call API (asynchronous, so yield)
        const navigatorInstance = yield select(getInstanceNameUrl);
        const response = yield call(API.fetchContactsFromQuery, savedQueryId, navigatorInstance, restService.client);
        // Update store
        yield put(
            deliveriesDeliveryBulkAssignActions.setDeliveriesHouseholdFromQueryList(
                Array.isArray(response.items) ? response.items : []
            )
        );

        // yield put(deliveriesHouseholdActions.setTotalInDeliveriesHouseholdFilters(response.total));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    }
}

export function* setHouseholdFromQueryFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = yield select(
        getDeliveriesHouseholdFromQueryFilters
    );

    //Update filters
    yield put(
        deliveriesDeliveryBulkAssignActions.storeDeliveriesHouseholdFromQueryFilters({ ...existingFilters, ...filters })
    );

    yield* fetchHouseholdList(action);
}

export function* fetchHouseholdList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = yield select(
            getDeliveriesHouseholdFromQueryFilters
        );

        // Call API (asynchronous, so yield)
        const response = yield call(HouseholdAPI.fetchHouseholdList, filters, restService.client);

        // Update store
        yield put(
            deliveriesDeliveryBulkAssignActions.setDeliveriesHouseholdFromQueryList(
                Array.isArray(response.items) ? response.items : []
            )
        );

        yield put(deliveriesDeliveryBulkAssignActions.setTotalInDeliveriesHouseholdFromQueryFilters(response.total));
        // yield put(deliveriesBulk.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export const deliveriesDeliveryBulkAssignSagas = [
    takeEvery(DeliveriesDeliveryBulkAssignActionTypes.StoreDeliveryBulkAssign, storeDeliveryBulkAssign),
    takeEvery(DeliveriesDeliveryBulkAssignActionTypes.FetchRemoteSourceQueryList, fetchRemoteSourceQueryList),
    takeEvery(DeliveriesDeliveryBulkAssignActionTypes.FetchContactsFromQuery, fetchContactsFromQuery),
    takeEvery(DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdFromQueryFilters, setHouseholdFromQueryFilters)
];

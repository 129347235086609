import { format } from 'date-fns';

import { DATE_FORMAT_PATTERN } from '../log.constants';

/**
 * Builds console message prefix with application name and timestamp
 */
export function buildEntryPrefix(prefix: string, eventDate: Date): string {
    // date pattern apply
    const date = format(eventDate, DATE_FORMAT_PATTERN);
    // returns plain prefix
    return `${prefix} - ${date}`;
}

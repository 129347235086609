import { all } from 'redux-saga/effects';

import { deliveriesAttachmentSagas } from './attachment/saga';
import { deliveriesDeliverySagas } from './delivery/saga';
import { deliveriesDeliveryBulkAssignSagas } from './deliveryBulkAssign/saga';
import { deliveriesHouseholdSagas } from './household/saga';
import { deliveriesInventorySagas } from './inventory/saga';
import { deliveriesKaimahiSagas } from './kaimahi/saga';
import { deliveriesNavigatorInstanceSagas } from './navigatorInstance/saga';
import { deliveriesProviderSagas } from './provider/saga';
import { deliveriesDeliveryTypeSagas } from './types/saga';
import { deliveriesZoneSagas } from './zone/saga';

/**
 * Public Sagas
 */
export function* rootDeliveriesSaga(): any {
    yield all([
        ...deliveriesHouseholdSagas,
        ...deliveriesDeliverySagas,
        ...deliveriesDeliveryTypeSagas,
        ...deliveriesKaimahiSagas,
        ...deliveriesZoneSagas,
        ...deliveriesProviderSagas,
        ...deliveriesAttachmentSagas,
        ...deliveriesDeliveryBulkAssignSagas,
        ...deliveriesInventorySagas,
        ...deliveriesNavigatorInstanceSagas
    ]);
}

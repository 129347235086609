import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesDeliveryBulkAssignActionTypes {
    SetDeliveryTypeForBulkAssign = 'deleveries/setDeliveryTypeForBulkAssign',
    SetNoOfItemForBulkAssign = 'deleveries/setNoOfItemForBulkAssign',

    SetHouseholdForBulkAssign = 'deleveries/setHouseholdForBulkAssign',
    StoreDeliveryBulkAssign = 'deliveries/storeDeliveryBulkAssign',
    UpdateDeliveryBulkAssign = 'deliveries/updateDeliveryBulkAssign',
    FetchRemoteSourceQueryList = 'deliveries/fetchRemoteSourceQueryList',
    SetRemoteSourceQueryList = 'deliveries/setRemoteSourceQueryList',
    FetchContactsFromQuery = 'deliveries/fetchContactsFromQuery',
    SetDeliveriesDeliveryForBulkAssign = 'deleveries/setDeliveriesDeliveryForBulkAssign',
    SetDeliveriesKaimahiForBulkAssign = 'deliveries/setDeliveriesKaimahiForBulkAssign',
    ClearDeliveriesBulkAssignDeliveryTypes = 'deliveries/clearDeliveriesBulkAssignDeliveryTypes',
    ClearDeliveriesBulkAssignKaimahis = 'deliveries/clearDeliveriesBulkAssignKaimahis',
    ClearDeliveriesBulkAssignHouseholds = 'deliveries/clearDeliveriesBulkAssignHouseholds',
    SetHouseholdListfromQuery = 'deliveries/setHouseholdListfromQuery',
    SetHouseholdFromQueryFilters = 'deliveries/setHouseholdListfromQueryFilters',
    StoreHouseholdFromQueryFilters = 'deliveries/storeHouseholdFromQueryFilters',
    SetTotalInDeliveriesHouseholdFromQueryFilters = 'deliveries/setTotalInDeliveriesHouseholdFromQueryFilters'
}

/**
 * Actions
 */
export const deliveriesDeliveryBulkAssignActions = {
    // set delivery type
    setDeliveriesDeliveryTypeForBulkAssign: (
        items
    ): ActionWithPayload<DeliveriesDeliveryBulkAssignActionTypes.SetDeliveryTypeForBulkAssign, any[]> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.SetDeliveryTypeForBulkAssign, items),
    // set no of items
    setDeliveriesNoOfItemForBulkAssign: (
        num
    ): ActionWithPayload<DeliveriesDeliveryBulkAssignActionTypes.SetNoOfItemForBulkAssign, string> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.SetNoOfItemForBulkAssign, num),

    setDeliveriesDeliveryHouseholdForBulkAssign: (
        items
    ): ActionWithPayload<DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdForBulkAssign, any[]> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdForBulkAssign, items),

    setDeliveriesDeliveryForBulkAssign: (
        items
    ): ActionWithPayload<DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesDeliveryForBulkAssign, any[]> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesDeliveryForBulkAssign, items),

    setDeliveriesKaimahiForBulkAssign: (
        items
    ): ActionWithPayload<DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesKaimahiForBulkAssign, any[]> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesKaimahiForBulkAssign, items),

    storeDeliveriesDeliveryBulkAssign: (
        item
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.StoreDeliveryBulkAssign,
        CONTRACTS.DeliveriesDeliveryBulkAssignDTOVerbose
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.StoreDeliveryBulkAssign, item),

    updateDeliveriesDeliveryBulkAssign: (
        item
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.UpdateDeliveryBulkAssign,
        CONTRACTS.DeliveriesDeliveryBulkAssignDTOVerbose
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.UpdateDeliveryBulkAssign, item),

    fetchRemoteSourceQueryList: (url): Action<DeliveriesDeliveryBulkAssignActionTypes.FetchRemoteSourceQueryList> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.FetchRemoteSourceQueryList, url),

    setRemoteSourceQueryList: (
        items
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.SetRemoteSourceQueryList,
        CONTRACTS.DeliveriesRemoteSourceQueries[]
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.SetRemoteSourceQueryList, items),

    fetchContactsFromQuery: (
        navigatorInstance
    ): Action<DeliveriesDeliveryBulkAssignActionTypes.FetchContactsFromQuery> =>
        createAction(DeliveriesDeliveryBulkAssignActionTypes.FetchContactsFromQuery, navigatorInstance),
    clearDeliveriesBulkAssignDeliveryTypes: (): Action<
        DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignDeliveryTypes
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignDeliveryTypes),
    clearDeliveriesBulkAssignKaimahis: (): Action<
        DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignKaimahis
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignKaimahis),
    clearDeliveriesBulkAssignHouseholds: (): Action<
        DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignHouseholds
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignHouseholds),

    // populate Household items
    setDeliveriesHouseholdFromQueryList: (
        list
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdListfromQuery,
        { list: Partial<CONTRACTS.DeliveriesHousehold[]> }
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdListfromQuery, { list }),

    // populate Household filters
    setDeliveriesHouseholdFromQueryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdFromQueryFilters,
        CONTRACTS.AllowedDeliveriesHouseholdFilters
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdFromQueryFilters, filters),
    storeDeliveriesHouseholdFromQueryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.StoreHouseholdFromQueryFilters,
        CONTRACTS.AllowedDeliveriesHouseholdFilters
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.StoreHouseholdFromQueryFilters, filters),
    setTotalInDeliveriesHouseholdFromQueryFilters: (
        total: number
    ): ActionWithPayload<
        DeliveriesDeliveryBulkAssignActionTypes.SetTotalInDeliveriesHouseholdFromQueryFilters,
        number
    > => createAction(DeliveriesDeliveryBulkAssignActionTypes.SetTotalInDeliveriesHouseholdFromQueryFilters, total)
};

import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesKaimahi = (state: any): Partial<CONTRACTS.DeliveriesKaimahi> => {
    return state.deliveries.kaimahi.item || {};
};

export const getDeliveriesKaimahiList = (state: any): CONTRACTS.DeliveriesKaimahi[] => {
    return state.deliveries.kaimahi.list || [];
};

export const getDeliveriesKaimahiFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters> => {
    return state.deliveries.kaimahi.filters || {};
};

export const getKaimahiSuccess = (state: any): boolean => {
    return state.deliveries.kaimahi.success || false;
};

import { all } from 'redux-saga/effects';

import { OtherSagas } from './Other/saga';
import { State1Sagas } from './State-1/saga';
import { State2Sagas } from './State-2/saga';
import { State3Sagas } from './State-3/saga';
import { State4Sagas } from './State-4/saga';

/**
 * Public Sagas
 */
export function* rootChildHubSaga(): any {
    yield all([...State1Sagas, ...State2Sagas, ...State3Sagas, ...State4Sagas, ...OtherSagas]);
}

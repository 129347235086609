import { ActionsUnion } from '../../utils/action.helpers';
import { planCaseloadActions } from './caseload/actions';
import { planCasenoteActions } from './casenote/actions';

/**
 * Actions
 */
export const planActions = {
    /**
     * Caseload
     */
    ...planCaseloadActions,
    /**
     * Casenote
     */
    ...planCasenoteActions
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type PlanActionsUnion = ActionsUnion<typeof planActions>;

/**
 * Attachment DTO
 *
 * API endpoints:
 *
 * POST   /attachment      inserts a new attachment record
 * DELETE /attachment/:id   deletes attachment record for a given id.
 */
export interface Attachment {
    // identifiers
    id: string;

    // ownership
    links: AttachmentLinks[]; // Delivery.id

    // details
    fileName: string;
    format: string; // mime-type?
    size: number; // bytes
    path: string;
    file?: string; //base64

    // admin
    createdOn: string; // ISO8601 date representation
    createdBy: string; // Kaimahi.id or custom import source identifier
    modifiedOn?: string; // ISO8601 date representation
    modifiedBy?: string; // Kaimahi.id
}

export interface AttachmentLinks {
    // identifiers
    id: string;

    // details
    attachmentId: number;
    entityId: string;
    linkType: AttachmentLinkType; // enum?
}

export interface AllowedAttachmentFilters {
    keywords?: string;
    limit?: number;
    offset?: number;
}

export enum AttachmentLinkType {
    delivery = 'Delivery',
    casenote = 'Casenote'
}

export interface AttachmentState {
    item: Partial<Attachment>;
    list: Attachment[];
    filters: AllowedAttachmentFilters;
}

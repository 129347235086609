import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State7ActionTypes {
    FetchItem = 'State7/fetchItem',
    SetState7NTWItem = 'State7/setState7NTWItem'
}
/**
 * Actions
 */
export const State7Actions = {
    setState7NTWItem: (item): ActionWithPayload<State7ActionTypes.SetState7NTWItem, { item }> =>
        createAction(State7ActionTypes.SetState7NTWItem, { item })
};

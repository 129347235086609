{/* prettier-ignore */}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesHouseholdActions, DeliveriesHouseholdActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesHouseholdFilters } from './selectors';

/**
 * Household Sagas
 */
export function* fetchHouseholdList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = yield select(
            getDeliveriesHouseholdFilters
        );

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchHouseholdList, filters, restService.client);

        // Update store
        yield put(
            deliveriesHouseholdActions.setDeliveriesHouseholdList(Array.isArray(response.items) ? response.items : [])
        );

        yield put(deliveriesHouseholdActions.setTotalInDeliveriesHouseholdFilters(response.total));
        yield put(deliveriesHouseholdActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchHousehold(action): any {
    const householdId = action.payload;
    if (householdId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to fetch the household
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchHousehold, householdId, restService.client);
            yield put(deliveriesHouseholdActions.setDeliveriesHousehold(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeHousehold(action): any {
    let response;

    const household: CONTRACTS.DeliveriesHousehold = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the household
    try {
        //Call update api
        response = yield call(API.storeHousehold, household, restService.client);

        yield put(deliveriesHouseholdActions.setDeliveriesHousehold(response));

        //update the list after update or create
        yield put(deliveriesHouseholdActions.updateDeliveriesHouseholdList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesHouseholdActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesHouseholdActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteHousehold(action): any {
    const householdId = action.payload;
    if (householdId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the household
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteHousehold, householdId, restService.client);
            // Update message
            yield* fetchHouseholdList(action);
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setHouseholdFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> = yield select(
        getDeliveriesHouseholdFilters
    );

    //Update filters
    yield put(deliveriesHouseholdActions.storeDeliveriesHouseholdFilters({ ...existingFilters, ...filters }));

    yield* fetchHouseholdList(action);
}

export const deliveriesHouseholdSagas = [
    takeEvery(DeliveriesHouseholdActionTypes.FetchHouseholdList, fetchHouseholdList),
    takeEvery(DeliveriesHouseholdActionTypes.SetHouseholdFilters, setHouseholdFilters),
    takeEvery(DeliveriesHouseholdActionTypes.FetchHousehold, fetchHousehold),
    takeEvery(DeliveriesHouseholdActionTypes.StoreHousehold, storeHousehold),
    takeEvery(DeliveriesHouseholdActionTypes.DeleteHousehold, deleteHousehold)
];

export enum SystemStatus {
    idle,
    loading
}

export enum SystemMessageSeverity {
    success,
    info,
    warning,
    error,
    critical
}

export enum SystemMessageType {
    network,
    security,
    crud,
    state,
    ui,
    refresh
}

export interface SystemMessage {
    severity: SystemMessageSeverity;
    type: SystemMessageType;
    message?: string;
    body?: any;
}

export interface SystemState {
    status: SystemStatus;
    lastMessage: SystemMessage | null;
}

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesDeliveryActionTypes {
    FetchDeliveryList = 'deleveries/fetchDeliveryList',
    SetDeliveryList = 'deleveries/setDeliveryList',
    ClearDeliveryList = 'deleveries/clearDeliveryList',
    UpdateDeliveryList = 'deliveries/updateDeliveryList',
    FetchDelivery = 'deleveries/fetchDelivery',
    SetDelivery = 'deleveries/setDelivery',
    DeleteDelivery = 'deliveries/deleteDelivery',
    StoreDelivery = 'deliveries/storeDelivery',
    SetDeliveryFilters = 'deliveries/setDeliveryFilters',
    StoreDeliveryFilters = 'deliveries/storeDeliveryFilters',
    SetTotalInDeliveriesDeliveryFilters = 'deliveries/setTotalInDeliveriesDeliveryFilters',
    ClearDeliveryFilters = 'deliveries/clearDeliveryFilters',
    SyncWithRemoteSource = 'deliveries/syncWithRemoteSource',
    SetSubmitSuccess = 'deliveries/setDeliveriesDeliverySubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesDeliveryActions = {
    // retrieve Delivery items
    fetchDeliveriesDeliveryList: (): Action<DeliveriesDeliveryActionTypes.FetchDeliveryList> =>
        createAction(DeliveriesDeliveryActionTypes.FetchDeliveryList),

    // populate Delivery items
    setDeliveriesDeliveryList: (
        list
    ): ActionWithPayload<
        DeliveriesDeliveryActionTypes.SetDeliveryList,
        { list: CONTRACTS.DeliveriesDeliverySummary[] }
    > => createAction(DeliveriesDeliveryActionTypes.SetDeliveryList, { list }),

    // Update list after create and update
    updateDeliveriesDeliveryList: (
        item
    ): ActionWithPayload<DeliveriesDeliveryActionTypes.UpdateDeliveryList, CONTRACTS.DeliveriesDelivery> =>
        createAction(DeliveriesDeliveryActionTypes.UpdateDeliveryList, item),

    // retrieve Delivery item
    fetchDeliveriesDelivery: (id): any => createAction(DeliveriesDeliveryActionTypes.FetchDelivery, id),

    // populate Delivery item
    setDeliveriesDelivery: (
        item
    ): ActionWithPayload<DeliveriesDeliveryActionTypes.SetDelivery, { item: CONTRACTS.DeliveriesDelivery }> =>
        createAction(DeliveriesDeliveryActionTypes.SetDelivery, { item }),

    // update Delivery item
    storeDeliveriesDelivery: (
        item: CONTRACTS.DeliveriesDelivery
    ): ActionWithPayload<DeliveriesDeliveryActionTypes.StoreDelivery, CONTRACTS.DeliveriesDelivery> =>
        createAction(DeliveriesDeliveryActionTypes.StoreDelivery, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesDeliveryActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesDeliveryActionTypes.SetSubmitSuccess, { success }),

    // clear Delivery list
    clearDeliveriesDeliveryList: (): Action<DeliveriesDeliveryActionTypes.ClearDeliveryList> =>
        createAction(DeliveriesDeliveryActionTypes.ClearDeliveryList),

    // populate Delivery filters
    setDeliveriesDeliveryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryActionTypes.SetDeliveryFilters,
        CONTRACTS.AllowedDeliveriesDeliveryFilters
    > => createAction(DeliveriesDeliveryActionTypes.SetDeliveryFilters, filters),

    storeDeliveriesDeliveryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryActionTypes.StoreDeliveryFilters,
        CONTRACTS.AllowedDeliveriesDeliveryFilters
    > => createAction(DeliveriesDeliveryActionTypes.StoreDeliveryFilters, filters),

    setTotalInDeliveriesDeliveryFilters: (
        total: number
    ): ActionWithPayload<DeliveriesDeliveryActionTypes.SetTotalInDeliveriesDeliveryFilters, number> =>
        createAction(DeliveriesDeliveryActionTypes.SetTotalInDeliveriesDeliveryFilters, total),

    // clear Delivery filters
    clearDeliveriesDeliveryFilters: (): Action<DeliveriesDeliveryActionTypes.ClearDeliveryFilters> =>
        createAction(DeliveriesDeliveryActionTypes.ClearDeliveryFilters),

    //delete Delivery
    deleteDeliveriesDelivery: (id): any => createAction(DeliveriesDeliveryActionTypes.DeleteDelivery, id),

    syncWithRemoteSource: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters>
    ): ActionWithPayload<
        DeliveriesDeliveryActionTypes.SyncWithRemoteSource,
        CONTRACTS.AllowedDeliveriesDeliveryFilters
    > => createAction(DeliveriesDeliveryActionTypes.SyncWithRemoteSource, filters)
};

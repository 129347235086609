import { ISagaModule } from 'redux-dynamic-modules-saga';

import { ChildHubState } from './ChildHub.contracts';
import { ChildHubReducer } from './ChildHub.reducer';
import { rootChildHubSaga } from './ChildHub.saga';

// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getChildHubModule(): [ISagaModule<ChildHubState>] {
    return [
        {
            // Unique id of the module
            id: 'ChildHub',
            // Maps the Store key to the reducer
            reducerMap: {
                ChildHub: ChildHubReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootChildHubSaga]
        }
    ];
}

import produce from 'immer';

import { updateArrayObjectWithObject } from '../../utils/update-arrayObject-with-object';
import { CaseloadActionTypes } from './caseload/actions';
import { CasenoteActionTypes } from './casenote/actions';
import { PlanActionsUnion } from './plan.actions';
import { PlanState } from './plan.contracts';

/**
 * Deliveries Reducers
 */
export const INITIAL_PLAN_PROPERTIES: any = { list: [], item: {}, filters: { limit: 25, offset: 0 } };

export const PLAN_REDUCER_DEFAULT = {
    caseload: INITIAL_PLAN_PROPERTIES,
    casenote: INITIAL_PLAN_PROPERTIES
};

export const planReducer = (state: PlanState, action: PlanActionsUnion): PlanState =>
    produce(state || PLAN_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            //Caseload
            case CaseloadActionTypes.SetCaseloadList:
                draft.caseload.list = action.payload.list;
                break;
            case CaseloadActionTypes.ClearCaseloadList:
                draft.caseload.list = [];
                break;
            case CaseloadActionTypes.UpdateCaseloadList:
                draft.caseload.list = updateArrayObjectWithObject(draft.caseload.list, action.payload) as any;
                break;
            case CaseloadActionTypes.SetCaseload:
                draft.caseload.item = action.payload.item;
                break;
            case CaseloadActionTypes.StoreCaseloadFilters:
                draft.caseload.filters = action.payload;
                break;
            case CaseloadActionTypes.SetTotalInCaseloadFilters:
                draft.caseload.filters = { ...draft.caseload.filters, total: action.payload };
                break;
            case CaseloadActionTypes.ClearCaseloadFilters:
                draft.caseload.filters = {};
                break;

            //Casenote
            case CasenoteActionTypes.SetCasenoteList:
                draft.casenote.list = action.payload.list;
                break;
            case CasenoteActionTypes.ClearCasenoteList:
                draft.casenote.list = [];
                break;
            case CasenoteActionTypes.UpdateCasenoteList:
                draft.casenote.list = updateArrayObjectWithObject(draft.casenote.list, action.payload) as any;
                break;
            case CasenoteActionTypes.SetCasenote:
                draft.casenote.item = action.payload.item;
                break;
            case CasenoteActionTypes.StoreCasenoteFilters:
                draft.casenote.filters = action.payload;
                break;
            case CasenoteActionTypes.SetTotalInCasenoteFilters:
                draft.casenote.filters = { ...draft.casenote.filters, total: action.payload };
                break;
            case CasenoteActionTypes.ClearCasenoteFilters:
                draft.casenote.filters = {};
                break;

            default:
                return draft;
        }
    });

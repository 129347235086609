import { call, put, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
//import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesAttachmentActions, DeliveriesAttachmentActionTypes } from './actions';
import * as API from './api';

/**
 * Attachment Sagas
 */
// export function* fetchAttachmentList(action): any {
//     // set filters from action
//     const filters: Partial<CONTRACTS.AllowedAttachmentFilters> = action.payload;

//     // Set loading status
//     yield put(systemActions.setStatus(SystemStatus.loading));

//     try {
//         // Call API (asynchronous, so yield)
//         const response = yield call(API.fetchAttachmentList, filters, restService.client);
//         yield put(deliveriesActions.setAttachmentList(Array.isArray(response) ? response : []));
//     } catch (error) {
//         // If we get an error, set the message
//         // yield put({type: REQUEST_ERROR, error: error.message})
//         return false;
//     } finally {
//         // Back to idle
//         yield put(systemActions.setStatus(SystemStatus.idle));
//     }
// }

// export function* fetchAttachment(action): any {
//     const attachmentId = action.payload;
//     if (attachmentId) {
//         yield put(systemActions.setStatus(SystemStatus.loading));

//         // Try to delete the delevery Type
//         try {
//             // Call Auth API (asynchronous, so yield)
//             const response = yield call(API.fetchAttachment, attachmentId, restService.client);
//             yield put(deliveriesActions.SetDeliveryType(response));
//         } catch (error) {
//             // If we get an error, set the message
//             // yield put({type: REQUEST_ERROR, error: error.message})
//             return false;
//         } finally {
//             // Back to idle
//             yield put(systemActions.setStatus(SystemStatus.idle));
//         }
//     }
// }

export function* storeAttachment(action): any {
    let response;

    const attachment: CONTRACTS.DeliveriesAttachment = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try to update or create the delevery Type
    try {
        //Call update api
        response = yield call(API.storeAttachment, attachment, restService.client);
        yield put(deliveriesAttachmentActions.setDeliveriesAttachment(response));
        //  yield put(deliveriesAttachmentActions.setSubmitSuccess(false));
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        // yield put(deliveriesAttachmentActions.setSubmitSuccess(false));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
        // yield all([
        //     // Back to idle
        //     put(systemActions.setStatus(SystemStatus.idle))
        // ]);

        //TO DO
        // check if new
        //Redirect
    }
}

export function* deleteAttachment(action): any {
    const attachmentId = action.payload;
    if (attachmentId) {
        yield put(systemActions.setStatus(SystemStatus.loading));

        // Try to delete the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteAttachment, attachmentId, restService.client);
            //return type is void
        } catch (error) {
            // If we get an error, set the message
            // yield put({type: REQUEST_ERROR, error: error.message})
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export const deliveriesAttachmentSagas = [
    //  takeEvery(DeliveriesAttachmentActionTypes.FetchAttachmentList, fetchAttachmentList),
    //  takeEvery(DeliveriesAttachmentActionTypes.SetAttachmentFilters, fetchAttachmentList),
    //  takeEvery(DeliveriesAttachmentActionTypes.FetchAttachment, fetchAttachment),
    takeEvery(DeliveriesAttachmentActionTypes.StoreAttachment, storeAttachment),
    takeEvery(DeliveriesAttachmentActionTypes.DeleteAttachment, deleteAttachment)
];

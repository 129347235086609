import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesInventoryActionTypes {
    FetchInventoryList = 'deleveries/fetchInventoryList',
    SetInventoryList = 'deleveries/setInventoryList',
    ClearInventoryList = 'deleveries/clearInventoryList',
    UpdateInventoryList = 'deliveries/updateInventoryList',
    FetchInventory = 'deleveries/fetchInventory',
    SetInventory = 'deleveries/setInventory',
    DeleteInventory = 'deliveries/deleteInventory',
    StoreInventory = 'deliveries/storeInventory',
    SetInventoryFilters = 'deliveries/setInventoryFilters',
    StoreInventoryFilters = 'deliveries/storeInventoryFilters',
    SetTotalInDeliveriesInventoryFilters = 'deliveries/setTotalInDeliveriesInventoryFilters',
    ClearInventoryFilters = 'deliveries/clearInventoryFilters',
    SetSubmitSuccess = 'deliveries/setInventorySubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesInventoryActions = {
    // retrieve Inventory items
    fetchDeliveriesInventoryList: (): Action<DeliveriesInventoryActionTypes.FetchInventoryList> =>
        createAction(DeliveriesInventoryActionTypes.FetchInventoryList),

    // populate Inventory items
    setDeliveriesInventoryList: (
        list
    ): ActionWithPayload<
        DeliveriesInventoryActionTypes.SetInventoryList,
        { list: Partial<CONTRACTS.DeliveriesInventory[]> }
    > => createAction(DeliveriesInventoryActionTypes.SetInventoryList, { list }),

    // Update list after create and update
    updateDeliveriesInventoryList: (
        item
    ): ActionWithPayload<DeliveriesInventoryActionTypes.UpdateInventoryList, CONTRACTS.DeliveriesInventory> =>
        createAction(DeliveriesInventoryActionTypes.UpdateInventoryList, item),

    // retrieve Inventory item
    fetchDeliveriesInventory: (id): any => createAction(DeliveriesInventoryActionTypes.FetchInventory, id),

    // populate Inventory item
    setDeliveriesInventory: (
        item
    ): ActionWithPayload<DeliveriesInventoryActionTypes.SetInventory, { item: CONTRACTS.DeliveriesInventory }> =>
        createAction(DeliveriesInventoryActionTypes.SetInventory, { item }),

    // update Inventory item
    storeDeliveriesInventory: (
        item: CONTRACTS.DeliveriesInventory
    ): ActionWithPayload<DeliveriesInventoryActionTypes.StoreInventory, CONTRACTS.DeliveriesInventory> =>
        createAction(DeliveriesInventoryActionTypes.StoreInventory, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesInventoryActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesInventoryActionTypes.SetSubmitSuccess, { success }),

    // clear Inventory list
    clearDeliveriesInventoryList: (): Action<DeliveriesInventoryActionTypes.ClearInventoryList> =>
        createAction(DeliveriesInventoryActionTypes.ClearInventoryList),

    // populate Inventory filters
    setDeliveriesInventoryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters>
    ): ActionWithPayload<
        DeliveriesInventoryActionTypes.SetInventoryFilters,
        CONTRACTS.AllowedDeliveriesInventoryFilters
    > => createAction(DeliveriesInventoryActionTypes.SetInventoryFilters, filters),

    storeDeliveriesInventoryFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesInventoryFilters>
    ): ActionWithPayload<
        DeliveriesInventoryActionTypes.StoreInventoryFilters,
        CONTRACTS.AllowedDeliveriesInventoryFilters
    > => createAction(DeliveriesInventoryActionTypes.StoreInventoryFilters, filters),

    setTotalInDeliveriesInventoryFilters: (
        total: number
    ): ActionWithPayload<DeliveriesInventoryActionTypes.SetTotalInDeliveriesInventoryFilters, number> =>
        createAction(DeliveriesInventoryActionTypes.SetTotalInDeliveriesInventoryFilters, total),

    // clear Inventory filters
    clearDeliveriesInventoryFilters: (): Action<DeliveriesInventoryActionTypes.ClearInventoryFilters> =>
        createAction(DeliveriesInventoryActionTypes.ClearInventoryFilters),

    //delete Inventory
    deleteDeliveriesInventory: (id): any => createAction(DeliveriesInventoryActionTypes.DeleteInventory, id)
};

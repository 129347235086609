import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum nhiActionTypes {
    FetchNhiList = 'Nhi/fetchNhiList',
    SetNhiList = 'Nhi/setNhiList',
    ClearNhiList = 'Nhi/clearNhiList',
    UpdateNhiList = 'Nhi/updateNhiList',
    FetchNhi = 'Nhi/fetchNhi',
    SetNhi = 'Nhi/setNhi',
    SetNhiFilters = 'Nhi/setNhiFilters',
    StoreNhiFilters = 'Nhi/storeNhiFilters',
    SetTotalInNhiFilters = 'Nhi/setTotalInNhiFilters',
    ClearNhiFilters = 'Nhi/clearNhiFilters',
    SetNHIItemId = 'Navigator/setNHIItemId',
    ClearNHIItemId = 'Navigator/clearNHIItemId'
}

/**
 * Actions
 */
export const nhiActions = {
    // retrieve Nhi items
    fetchNhiList: (item): ActionWithPayload<nhiActionTypes.FetchNhiList, { item: CONTRACTS.nhiSearchParameters }> =>
        createAction(nhiActionTypes.FetchNhiList, item),
    // populate Nhi items
    setNhiList: (list): ActionWithPayload<nhiActionTypes.SetNhiList, { list: CONTRACTS.nhi[] }> =>
        createAction(nhiActionTypes.SetNhiList, { list }),

    // Update list after create and update
    updateNhiList: (item): ActionWithPayload<nhiActionTypes.UpdateNhiList, CONTRACTS.nhi> =>
        createAction(nhiActionTypes.UpdateNhiList, item),

    // retrieve Nhi item
    fetchNhi: (id): any => createAction(nhiActionTypes.FetchNhi, id),

    // populate Nhi item
    setNhi: (item): ActionWithPayload<nhiActionTypes.SetNhi, { item: CONTRACTS.nhi }> =>
        createAction(nhiActionTypes.SetNhi, { item }),

    // clear Nhi list
    clearNhiList: (): Action<nhiActionTypes.ClearNhiList> => createAction(nhiActionTypes.ClearNhiList),

    // populate Nhi filters
    setNhiFilters: (
        filters: Partial<CONTRACTS.nhiFilters>
    ): ActionWithPayload<nhiActionTypes.SetNhiFilters, CONTRACTS.nhiFilters> =>
        createAction(nhiActionTypes.SetNhiFilters, filters),

    storeNhiFilters: (
        filters: Partial<CONTRACTS.nhiFilters>
    ): ActionWithPayload<nhiActionTypes.StoreNhiFilters, CONTRACTS.nhiFilters> =>
        createAction(nhiActionTypes.StoreNhiFilters, filters),

    setTotalInNhiFilters: (total: number): ActionWithPayload<nhiActionTypes.SetTotalInNhiFilters, number> =>
        createAction(nhiActionTypes.SetTotalInNhiFilters, total),

    // clear Nhi filters
    clearNhiFilters: (): Action<nhiActionTypes.ClearNhiFilters> => createAction(nhiActionTypes.ClearNhiFilters),
    setNHIItemId: (id: string): ActionWithPayload<nhiActionTypes.SetNHIItemId, { id: string }> =>
        createAction(nhiActionTypes.SetNHIItemId, { id }),
    clearNHIItemId: (): Action<nhiActionTypes.ClearNHIItemId> => createAction(nhiActionTypes.ClearNHIItemId)
};

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const saveItem = (key: string, item: CONTRACTS.Item, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .post('/Save', item, {
            headers: {
                orgName: 'unq4532441b17894794a994ec64bca7e',
                combinedKey: key
            }
        })
        .then((response: AxiosResponse<CONTRACTS.Item>) => response)
        .catch(error => ({ error }));
};

export const updateItem = (key: string, item: CONTRACTS.Item, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .put(`/Update`, item, {
            headers: {
                orgName: 'unq4532441b17894794a994ec64bca7e',
                combinedKey: key
            }
        })
        .then((response: AxiosResponse<CONTRACTS.Item>) => response)
        .catch(error => ({ error }));
};

export const fetchItem = (key: string, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .get(`/Details?id=${key}`, {
            headers: {
                orgName: 'unq4532441b17894794a994ec64bca7e'
            }
        })
        .then((response: AxiosResponse<CONTRACTS.Item>) => response)
        .catch(error => ({ error }));
};

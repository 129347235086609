import { buildEntryPrefix } from '../helpers/entry-prefix';
import { ConsoleConfiguration } from '../models/configuration';
import { LogProvider } from '../models/provider';
import { ThresholdLevel } from '../models/threshold';

// must declare document to prevent document !== 'undefined' from throwing an 'expression is always true error
declare const document: any;

/**
 * Wrapper for browser console
 */
export class ConsoleLogProvider implements LogProvider {
    // browser ability to apply styles to log messages
    private _canStyleLogMessage =
        // TODO: fix the rule below, not working anymore in Chrome
        typeof document !== 'undefined' && !(navigator.userAgent && /Trident/.test(navigator.userAgent));

    public log(
        configuration: ConsoleConfiguration,
        debugByTagPool: string[],
        severity: number,
        method: string,
        message: string,
        tags: string[],
        args: any[]
    ): void {
        // Runs console method if severity threshold met or tag matches were found
        if (
            severity >= (configuration.thresholdLevel as ThresholdLevel) ||
            (tags.length > 0 && tags.some(tag => debugByTagPool.indexOf(tag) > -1))
        ) {
            // build console entry prefix - console will use browser date primary usage,
            const prefix = buildEntryPrefix(configuration.messagePrefix || '', new Date());
            const consoleArgs = this._canStyleLogMessage
                ? [
                      `%c${prefix} %c` + message,
                      `color: ${configuration.messagePrefixColor}`,
                      `color: ${configuration.messageColor}`
                  ]
                : [`[${prefix}] ` + message];
            // add arguments
            consoleArgs.push(...args);
            console[method](...consoleArgs);
        }
    }
}

/**
 * Exports instance of ConsoleLogProvider
 * to use as the singleton reference for default provider in TapDiagnosticsLog
 */
export const consoleLogProviderInstance = new ConsoleLogProvider();

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesNavigatorInstanceActions, DeliveriesNavigatorInstanceActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesNavigatorInstanceFilters } from './selectors';

/**
 * Delivery Types Sagas
 */
export function* fetchNavigatorInstanceList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedNavigatorInstanceFilters> = yield select(
            getDeliveriesNavigatorInstanceFilters
        );

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchNavigatorInstanceList, filters, restService.client);
        // Update store
        yield put(
            deliveriesNavigatorInstanceActions.setDeliveriesNavigatorInstanceList(
                Array.isArray(response.items) ? response.items : []
            )
        );

        yield put(deliveriesNavigatorInstanceActions.setTotalInDeliveriesNavigatorInstanceFilters(response.total));

        yield put(deliveriesNavigatorInstanceActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchInstanceListForKaimahi(action): any {
    const id = action.payload;
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchInstanceListForKaimahi, id, restService.client);
        // Update store
        yield put(
            deliveriesNavigatorInstanceActions.setInstanceListForKaimahi(Array.isArray(response) ? response : [])
        );

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* setNavigatorInstanceFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedNavigatorInstanceFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedNavigatorInstanceFilters> = yield select(
        getDeliveriesNavigatorInstanceFilters
    );

    //Update filters
    yield put(
        deliveriesNavigatorInstanceActions.storeDeliveriesNavigatorInstanceFilters({ ...existingFilters, ...filters })
    );

    yield* fetchNavigatorInstanceList(action);
}

export function* storeNavigatorInstance(action): any {
    let response;
    // let responseProvider;

    const navigatorInstance: CONTRACTS.DeliveriesNavigatorInstance = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the navigator Instance
    try {
        //Call update api
        response = yield call(API.storeNavigatorInstance, navigatorInstance, restService.client);
        yield put(deliveriesNavigatorInstanceActions.setDeliveriesNavigatorInstance(response));

        //update the list after update or create
        yield put(deliveriesNavigatorInstanceActions.updateDeliveriesNavigatorInstanceList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesNavigatorInstanceActions.setSubmitSuccess(true));

        // yield* fetchNavigatorInstanceList(action);
    } catch (error) {
        yield put(deliveriesNavigatorInstanceActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchNavigatorInstance(action): any {
    const id = action.payload;
    if (id) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to fetch the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchNavigatorInstance, id, restService.client);
            yield put(deliveriesNavigatorInstanceActions.setDeliveriesNavigatorInstance(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* deleteNavigatorInstance(action): any {
    const id = action.payload;
    if (id) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteNavigatorInstance, id, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));

            yield* fetchNavigatorInstanceList(action);
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export const deliveriesNavigatorInstanceSagas = [
    takeEvery(DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstanceList, fetchNavigatorInstanceList),
    takeEvery(DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceFilters, setNavigatorInstanceFilters),
    takeEvery(DeliveriesNavigatorInstanceActionTypes.StoreNavigatorInstance, storeNavigatorInstance),
    takeEvery(DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstance, fetchNavigatorInstance),
    takeEvery(DeliveriesNavigatorInstanceActionTypes.DeleteNavigatorInstance, deleteNavigatorInstance),
    takeEvery(DeliveriesNavigatorInstanceActionTypes.FetchInstanceListForKaimahi, fetchInstanceListForKaimahi)
    // takeEvery(
    //     DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstanceListByProvider,
    //     fetchNavigatorInstanceListByProvider
    // )
];

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { xdescribe } from '@jest/globals';
import { deliveriesActions, getDeliveriesProviderFilters } from '@whanau/domains';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesProviderActions, DeliveriesProviderActionTypes } from './actions';
import * as API from './api';

/**
 * Provider Sagas
 */
export function* fetchProviderList(): any {
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));

    try {
        const filters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters> = yield select(getDeliveriesProviderFilters);

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchProviderList, filters, restService.client);
        yield put(
            deliveriesProviderActions.setDeliveriesProviderList(Array.isArray(response.items) ? response.items : [])
        );

        yield put(deliveriesProviderActions.setTotalInDeliveriesProviderFilters(response.total));

        yield put(deliveriesProviderActions.setSubmitSuccess(false));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchProvider(action): any {
    const providerId = action.payload;
    if (providerId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the provider
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchProvider, providerId, restService.client);
            yield put(deliveriesProviderActions.setDeliveriesProvider(response));
            yield* fetchNavigatorInstanceListByProvider(providerId);
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeProvider(action): any {
    let response;

    const provider: CONTRACTS.DeliveriesProvider = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));

    // Try to update or create the provider
    try {
        //Call update api
        response = yield call(API.storeProvider, provider, restService.client);
        // const providerInstances = provider.instances.map(x => ({
        //     ...x,
        //     providerId: response.id
        // }));
        /// save instances for Provider
        // yield* storeProviderNavigatorInstance(providerInstances);
        const newResponse = { ...response, instances: response.instances.map(x => x.navigatorInstanceId) };
        yield put(deliveriesActions.setDeliveriesProvider(newResponse));
        yield put(deliveriesProviderActions.setSubmitSuccess(true));
        //yield* fetchProviderList();
    } catch (error) {
        yield put(deliveriesProviderActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
        // Back to idle

        //TO O
        // check if new
        //Redirect
    }
}

export function* fetchNavigatorInstanceListByProvider(action): any {
    const id = action;
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchNavigatorInstanceListByProvider, id, restService.client);
        // Update store

        yield put(
            deliveriesActions.setDeliveriesInstanceListByProvider(
                Array.isArray(response) ? response.map(x => x.navigatorInstanceId) : []
            )
        );

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* storeProviderNavigatorInstance(action): any {
    let response;

    const providerNavigatorInstance: CONTRACTS.ProviderNavigatorInstance[] = action;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the navigator Instance
    try {
        // store naviatorinstance linked to provider
        response = yield call(API.storeProviderNavigatorInstance, providerNavigatorInstance, restService.client);

        //update the list after update or create
        yield put(deliveriesActions.setDeliveriesInstanceListByProvider(response.map(x => x.navigatorInstanceId)));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));

        // yield* fetchNavigatorInstanceList(action);
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* setProviderFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesProviderFilters> = yield select(
        getDeliveriesProviderFilters
    );

    //Update filters
    yield put(deliveriesProviderActions.storeDeliveriesProviderFilters({ ...existingFilters, ...filters }));

    yield* fetchProviderList();
}

// export function* deleteProvider(action): any {
//     const providerId = action.payload;
//     if (providerId) {
//         yield put(systemActions.setStatus(SystemStatus.loading));

//         // Try to delete the provider
//         try {
//             // Call Auth API (asynchronous, so yield)
//             yield call(API.deleteProvider, providerId, restService.client);
//             //return type is void
//         } catch (error) {
//             // If we get an error, set the message
//             // yield put({type: REQUEST_ERROR, error: error.message})
//             return false;
//         } finally {
//             // Back to idle
//             yield put(systemActions.setStatus(SystemStatus.idle));
//         }
//     }
// }

export const deliveriesProviderSagas = [
    takeEvery(DeliveriesProviderActionTypes.FetchProviderList, fetchProviderList),
    takeEvery(DeliveriesProviderActionTypes.SetProviderFilters, setProviderFilters),
    takeEvery(DeliveriesProviderActionTypes.FetchProvider, fetchProvider),
    takeEvery(DeliveriesProviderActionTypes.StoreProvider, storeProvider),
    takeEvery(DeliveriesProviderActionTypes.StoreProviderNavigatorInstance, storeProviderNavigatorInstance),
    takeEvery(DeliveriesProviderActionTypes.FetchNavigatorInstanceListByProvider, fetchNavigatorInstanceListByProvider)
    //  takeEvery(DeliveriesProviderActionTypes.DeleteProvider, deleteProvider)
];

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const storeItem = (item: CONTRACTS.Referral, client: AxiosInstance): any => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const organisation = url.searchParams.get('orgname');
    return client
        .post('/Submit', item, {
            headers: {
                orgName: 'unq4532441b17894794a994ec64bca7e'
            }
        })
        .then((response: AxiosResponse<CONTRACTS.Referral>) => response)
        .catch(error => ({ error }));
};

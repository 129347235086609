import * as H from 'history';

import { ActionsUnion, ActionWithPayload, createAction } from '../../utils/action.helpers';
import { UserCredentials, UserProfile } from './auth.contracts';

/**
 * Action Types
 */
export enum AuthActionTypes {
    ValidateUser = 'auth/validateUser',
    SetUserToken = 'auth/setUserToken',
    SetUserProfile = 'auth/setUserProfile',
    LogoutUser = 'auth/logoutUser',
    SetHasDefaultInstance = 'auth/setHasDefaultInstance',
    SetInstances = 'auth/setInstances',
    SetShowModalForInstance = 'auth/setShowModalForInstance',
    SetFromModal = 'auth/setFromModal'
}

/**
 * Actions
 */
export const authActions = {
    validateUser: (
        userCredentials: UserCredentials,
        history: H.History<any>
    ): ActionWithPayload<AuthActionTypes.ValidateUser, { userCredentials: UserCredentials; history: H.History<any> }> =>
        createAction(AuthActionTypes.ValidateUser, { userCredentials, history }),
    setUserToken: (
        accessToken?: string,
        instanceNameUrl?: string,
        navigatorId?: string
    ): ActionWithPayload<
        AuthActionTypes.SetUserToken,
        { accessToken?: string; instanceNameUrl?: string; navigatorId?: string }
    > => createAction(AuthActionTypes.SetUserToken, { accessToken, instanceNameUrl, navigatorId }),
    setUserProfile: (
        profile?: UserProfile
    ): ActionWithPayload<AuthActionTypes.SetUserProfile, { profile?: UserProfile }> =>
        createAction(AuthActionTypes.SetUserProfile, { profile }),
    unsetUserToken: (
        history: H.History<any>
    ): ActionWithPayload<AuthActionTypes.LogoutUser, { history: H.History<any> }> =>
        createAction(AuthActionTypes.LogoutUser, { history }),
    setHasDefaultInstance: (
        hasDefaultInstance: boolean
    ): ActionWithPayload<AuthActionTypes.SetHasDefaultInstance, any> =>
        createAction(AuthActionTypes.SetHasDefaultInstance, hasDefaultInstance),
    setInstances: (instances: any): ActionWithPayload<AuthActionTypes.SetInstances, any> =>
        createAction(AuthActionTypes.SetInstances, instances),
    setShowModalForInstance: (
        showModelForInstance: boolean
    ): ActionWithPayload<AuthActionTypes.SetShowModalForInstance, any> =>
        createAction(AuthActionTypes.SetShowModalForInstance, showModelForInstance),
    setFromModal: (fromModal: boolean): ActionWithPayload<AuthActionTypes.SetFromModal, any> =>
        createAction(AuthActionTypes.SetFromModal, fromModal)
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type AuthActionsUnion = ActionsUnion<typeof authActions>;

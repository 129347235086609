import * as CONTRACTS from './contracts';

export const getState4Item = (state: any): Partial<CONTRACTS.Item> => {
    return state.ChildHub.State_4.item || {};
};
export const getState4Wairua = (state: any): Partial<CONTRACTS.Wairua> => {
    return state.ChildHub.State_4.WairuaItem || {};
};
export const getState4Hinengaro = (state: any): Partial<CONTRACTS.Hinengaro> => {
    return state.ChildHub.State_4.HinengaroItem || {};
};
export const getState4Tinana = (state: any): Partial<CONTRACTS.Tinana> => {
    return state.ChildHub.State_4.TinanaItem || {};
};
export const getState4Img = (state: any): CONTRACTS.Img => {
    return state.ChildHub.State_4.Img || {};
};

import { all } from 'redux-saga/effects';

import { OtherSagas } from './Other/saga';
import { State1Sagas } from './State-1/saga';
import { State2Sagas } from './State-2/saga';
import { State3Sagas } from './State-3/saga';
import { State4Sagas } from './State-4/saga';
import { State5Sagas } from './State-5/saga';
import { State6Sagas } from './State-6/saga';
import { State7Sagas } from './State-7/saga';

/**
 * Public Sagas
 */
export function* rootNTWReportSaga(): any {
    yield all([
        ...State1Sagas,
        ...State2Sagas,
        ...State3Sagas,
        ...State4Sagas,
        ...State5Sagas,
        ...State6Sagas,
        ...State7Sagas,
        ...OtherSagas
    ]);
}

import * as Yup from 'yup';

export const addressValidationSchema = (): any => {
    return Yup.object().shape({
        addressLine1: Yup.string().nullable().required('Required'),
        city: Yup.string().nullable().required('Required')
    });
};

export const clientDetailValidationSchema = (): any => {
    return Yup.object().shape({
        preferredName: Yup.string().nullable().required('Required'),
        nhiNumber: Yup.string().nullable().required('Required'),
        genderCode: Yup.string().required('Required')
    });
};

export const personNameValidationSchema = (): any => {
    return Yup.object().shape({
        givenName: Yup.string().nullable().required('Required'),
        surname: Yup.string().nullable().required('Required'),
        preferredName: Yup.string().nullable().required('Required')
    });
};

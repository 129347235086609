import * as CONTRACTS from './contracts';

export const getNavigator = (state: any): Partial<CONTRACTS.navigator> => {
    return state.nhiLookup.navigator.item || {};
};

export const getNavigatorList = (state: any): CONTRACTS.navigator[] => {
    return state.nhiLookup.navigator.list || [];
};

export const getNavigatorFilters = (state: any): Partial<CONTRACTS.navigatorFilters> => {
    return state.nhiLookup.navigator.filters || {};
};
export const getNavigatorSearchparameters = (state: any): Partial<CONTRACTS.navigatorSearchParameters> => {
    return state.nhiLookup.navigator.parameters || {};
};

export const setItemId = (state: any): string => {
    return state.nhiLookup.navigator.id || '';
};
export const setNavigatorInstance = (state: any): string => {
    return state.nhiLookup.navigator.instanceSelected || '';
};
export const getNavigatorInstance = (state: any): string => {
    return state.nhiLookup.navigator.instanceSelected || '';
};
export const setNavigatorInstanceList = (state: any): string => {
    return state.nhiLookup.navigator.instances || [];
};
export const getNavigatorInstanceList = (state: any): string => {
    return state.nhiLookup.navigator.instances || [];
};

export const getNavigatorSuccess = (state: any): boolean => {
    return state.nhiLookup.navigator.success || false;
};
export const getData = (state: any): boolean => {
    return state.nhiLookup.navigator.data || false;
};
export const getShowModalForInstanceNHI = (state: any): boolean => {
    return state.nhiLookup.navigator.showModalForInstance || false;
};

import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesHousehold = (state: any): CONTRACTS.DeliveriesHousehold => {
    return state.deliveries.household.item || {};
};

export const getHouseholdSuccess = (state: any): boolean => {
    return state.deliveries.household.success || false;
};

export const getDeliveriesHouseholdList = (state: any): CONTRACTS.DeliveriesHouseholdSummary[] => {
    return state.deliveries.household.list || [];
};

export const getDeliveriesHouseholdFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters> => {
    return state.deliveries.household.filters || {};
};

/**
 * Tap Diagnostics Log supported threshold level values
 */
export enum ThresholdLevel {
    TRACE = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
    SILENT = 5
}

import { IPalette } from '@uifabric/styling';

export interface KAPalette extends IPalette {
    neutralBackground: string;
    redLight: string;
    sandDark: string;
    sand: string;
    sandLight: string;
}

const palette: Partial<KAPalette> = {
    /**
     * Color code for themeDarker.
     */
    themeDarker: '#11324a',
    /**
     * Color code for themeDark.
     */
    themeDark: '#174465',
    /**
     * Color code for themeDarkAlt.
     */
    themeDarkAlt: '#1b5177',
    /**
     * Color code for themePrimary.
     */
    themePrimary: '#1F5A84',
    /**
     * Color code for themeSecondary.
     */
    themeSecondary: '#2f6a93',
    /**
     * Color code for themeTertiary.
     */
    themeTertiary: '#6293b6',
    /**
     * Color code for themeLight.
     */
    themeLight: '#a8c5da',
    /**
     * Color code for themeLighter.
     */
    themeLighter: '#cedfeb',
    /**
     * Color code for themeLighterAlt.
     */
    themeLighterAlt: '#f2f7fa',
    /**
     * Color code for the strongest color, which is black in the default theme. This is a very light color in inverted themes.
     */
    black: '#323530',
    /**
     * Color code for neutralDark.
     */
    neutralDark: '#4a4e47',
    /**
     * Color code for neutralPrimary.
     */
    neutralPrimary: '#1A1C19',
    /**
     * Color code for neutralPrimaryAlt.
     */
    neutralPrimaryAlt: '#7b8078',
    /**
     * Color code for neutralSecondary.
     */
    neutralSecondary: '#949991',
    /**
     * Color code for neutralSecondaryAlt.
     */
    neutralSecondaryAlt: '#999999',
    /**
     * Color code for neutralTertiary.
     */
    neutralTertiary: '#adb2ab',
    /**
     * Color code for neutralTertiaryAlt.
     */
    neutralTertiaryAlt: '#c4c4c4',
    /**
     * Color code for neutralQuaternary.
     */
    neutralQuaternary: '#cacaca',
    /**
     * Color code for neutralQuaternaryAlt.
     */
    neutralQuaternaryAlt: '#d6d6d6',
    /**
     * Color code for neutralLight.
     */
    neutralLight: '#e5e5e5',
    /**
     * Color code for neutralLighter.
     */
    neutralLighter: '#efefef',
    /**
     * Color code for neutralLighterAlt.
     */
    neutralLighterAlt: '#f3f3f3',
    /**
     * Color code for neutralBackground
     */
    neutralBackground: '#F4F5F6',
    /**
     * Color code for the accent.
     */
    accent: '#ff00fb',
    /**
     * Color code for the softest color, which is white in the default theme. This is a very dark color in dark themes.
     * This is the page background.
     */
    white: '#FAFAFB',
    /**
     * Color code for yellowDark.
     */
    yellowDark: '#EBB806',
    /**
     * Color code for yellow.
     */
    yellow: '#FBE309',
    /**
     * Color code for yellowLight.
     */
    yellowLight: '#FBF08F',
    /**
     * Color code for redDark.
     */
    redDark: '#A5290C',
    /**
     * Color code for red.
     */
    red: '#ED4D27',
    /**
     * Color code for red.
     */
    redLight: '#F9797A',
    /**
     * Color code for magentaDark.
     */
    sandDark: '#c0c2b6',
    /**
     * Color code for magenta.
     */
    sand: '#E0E1D9',
    /**
     * Color code for magentaLight.
     */
    sandLight: '#EFF0EB',
    /**
     * Color code for purpleLight.
     */
    purpleLight: '#8a5d7c',
    /**
     * Color code for tealDark.
     */
    tealDark: '#7494a1',
    /**
     * Color code for teal.
     */
    teal: '#AAC3CD',
    /**
     * Color code for tealLight.
     */
    tealLight: '#D0E3E9',
    /**
     * Color code for greenDark.
     */
    greenDark: '#758B08',
    /**
     * Color code for green.
     */
    green: '#9BBB19',
    /**
     * Color code for greenLight.
     */
    greenLight: '#CEE24F'
};

export default palette;

// docs picker export here
export const pickerColorList = [];
Object.keys(palette).forEach(key => {
    pickerColorList.push({
        id: key,
        label: key,
        color: palette[key]
    });
});

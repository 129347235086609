import { ISagaModule } from 'redux-dynamic-modules-saga';

import { NhiLookupState } from './nhiLookup.contracts';
import { nhiLookupReducer } from './nhiLookup.reducer';
import { rootNhiLookupSaga } from './nhiLookup.saga';

// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getNhiLookupModule(): [ISagaModule<NhiLookupState>] {
    return [
        {
            // Unique id of the module
            id: 'nhiLookup',
            // Maps the Store key to the reducer
            reducerMap: {
                nhiLookup: nhiLookupReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootNhiLookupSaga]
        }
    ];
}

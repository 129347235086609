import { ISagaModule } from 'redux-dynamic-modules-saga';

import { PlanState } from './plan.contracts';
import { planReducer } from './plan.reducer';
import { rootPlanSaga } from './plan.saga';

// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getPlanModule(): [ISagaModule<PlanState>] {
    return [
        {
            // Unique id of the module
            id: 'plan',
            // Maps the Store key to the reducer
            reducerMap: {
                plan: planReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootPlanSaga]
        }
    ];
}

import * as CONTRACTS from './contracts';

export const getState3NTWItem = (state: any) => {
    return (
        state.NTWReport.State_3.item || [
            {
                activityNumber: 1,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            }
        ]
    );
};
export const getId = (state: any) => {
    return state.NTWReport.State_3.id || '';
};
export const getDescription = (state: any) => {
    return state.NTWReport.State_3.description || '';
};
export const getParticipating = (state: any) => {
    return state.NTWReport.State_3.Participating || '';
};
export const getFacilitated = (state: any) => {
    return state.NTWReport.State_3.Facilitated || '';
};
export const getImpact = (state: any) => {
    return state.NTWReport.State_3.Impact || '';
};
// export const getState3Img = (state: any): CONTRACTS.Img => {
//     return state.ChildHub.State_3.Img || {};
// };
export const getState3_1NTWItem = (state: any) => {
    return (
        state.NTWReport.State_3.item2 || [
            {
                activityNumber: 1,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 2,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 3,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 4,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 5,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 6,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            },
            {
                activityNumber: 7,
                activityDescription: '',
                activityParticipating: '',
                activityFacilitated: '',
                activityImpact: ''
            }
        ]
    );
};

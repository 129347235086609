import { AllowedDeliveryFilters, Delivery, Kaimahi, Zone } from './delivery.contracts';

export const getDeliveryList = (state: any): Delivery[] => {
    return state.delivery.list || [];
};

export const getDeliveryFilters = (state: any): AllowedDeliveryFilters => {
    return state.delivery.filters || {};
};

export const getDeliveryItem = (state: any): Delivery => {
    return state.delivery.item || {};
};

export const getKaimahiList = (state: any): Kaimahi[] => {
    return state.delivery.kaimahi || [];
};

export const getZonesList = (state: any): Zone[] => {
    return state.delivery.zones || [];
};

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

// Delivery type
export const fetchDeliveryTypeList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/deliveryType?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesDeliveryType[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchDeliveryType = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/deliveryType/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesDeliveryType>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeDeliveryType = (item: Partial<CONTRACTS.DeliveriesDeliveryType>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/deliveryType/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesDeliveryType>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/deliveryType`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesDeliveryType>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteDeliveryType = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/deliveryType/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { authActions, AuthSelectors, persistAuth } from '../../_auth';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesKaimahiActions, DeliveriesKaimahiActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesKaimahiFilters } from './selectors';

/**
 * Kaimahi Sagas
 */
export function* fetchKaimahiList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters> = yield select(getDeliveriesKaimahiFilters);
        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchKaimahiList, filters, restService.client);
        // Update store
        yield put(
            deliveriesKaimahiActions.setDeliveriesKaimahiList(Array.isArray(response.items) ? response.items : [])
        );

        yield put(deliveriesKaimahiActions.setTotalInDeliveriesKaimahiFilters(response.total));

        yield put(deliveriesKaimahiActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchKaimahi(action): any {
    const kaimahiId = action.payload;
    if (kaimahiId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the Kaimahi
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchKaimahi, kaimahiId, restService.client);
            // const instance = response.instances.filter(x => x.default == true);
            // const modified_response = {
            //     ...response,
            //     default: instance.length > 0 ? instance[0].navigatorInstanceId : 0
            // };
            yield put(deliveriesKaimahiActions.setDeliveriesKaimahi(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* fetchKaimahiAsUser(_action): any {
    //const fromInstanceModal = yield select(AuthSelectors.getModal);
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to delete the Kaimahi
    try {
        // Call Auth API (asynchronous, so yield)
        const response = yield call(API.FetchKaimahiAsUser, restService.client);
        // write into user
        yield put(authActions.setUserProfile(response));
        // let hasDefaultInstance = false;
        if (response.instances) {
            const instancesWithAccess = response.instances.filter(x => x.kaimahiAccess == true);
            if (instancesWithAccess != undefined) {
                if (instancesWithAccess.length === 1) {
                    // write into local storage
                    yield call(persistAuth, {
                        instanceUrlName: instancesWithAccess[0].url
                    });
                    // update state
                    yield put(authActions.setUserToken(null, instancesWithAccess[0].url));
                    yield put(authActions.setShowModalForInstance(false));
                } else if (instancesWithAccess.length > 1) {
                    const defaultObj = instancesWithAccess.filter(x => x.default == true);
                    yield put(authActions.setShowModalForInstance(true));
                    yield put(authActions.setUserToken(null, defaultObj[0].url));
                } else {
                    yield put(authActions.setShowModalForInstance(false));
                }
            }
        }
        // yield put(authActions.setHasDefaultInstance(hasDefaultInstance));
        yield put(authActions.setInstances(response.instances));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* storeKaimahi(action): any {
    let response;

    const kaimahi: CONTRACTS.DeliveriesKaimahi = action.payload;
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the Kaimahi
    try {
        //Call update api
        response = yield call(API.storeKaimahi, kaimahi, restService.client);
        yield put(deliveriesKaimahiActions.setDeliveriesKaimahi(response));
        //update the list after update or create
        yield put(deliveriesKaimahiActions.updateDeliveriesKaimahiList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        yield put(deliveriesKaimahiActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesKaimahiActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteKaimahi(action): any {
    const kaimahiId = action.payload;
    if (kaimahiId) {
        // Set loading status & clear messages
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the kaimahi
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteKaimahi, kaimahiId, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setKaimahiFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters> = yield select(
        getDeliveriesKaimahiFilters
    );

    //Update filters
    yield put(deliveriesKaimahiActions.storeDeliveriesKaimahiFilters({ ...existingFilters, ...filters }));

    yield* fetchKaimahiList(action);
}

export function* setSelectedInstance(action): any {
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());
    // Try to update store and headers
    try {
        console.log('storing instance Name', action.payload);
        // write into local storage
        yield call(persistAuth, { instanceUrlName: action.payload });
        // update state
        yield put(authActions.setUserToken(null, action.payload));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* storeDefaultInstance(action): any {
    let response;

    const instance: CONTRACTS.DeliveriesInstance = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the Kaimahi
    try {
        //Call update api
        response = yield call(API.storeDefaultInstance, instance[0].kaimahiId, instance, restService.client);

        yield* fetchKaimahiAsUser(action);
        // yield put(deliveriesKaimahiActions.setDeliveriesKaimahi(response));
        // Update message
        // yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export const deliveriesKaimahiSagas = [
    takeEvery(DeliveriesKaimahiActionTypes.FetchKaimahiList, fetchKaimahiList),
    takeEvery(DeliveriesKaimahiActionTypes.SetKaimahiFilters, setKaimahiFilters),
    takeEvery(DeliveriesKaimahiActionTypes.FetchKaimahi, fetchKaimahi),
    takeEvery(DeliveriesKaimahiActionTypes.FetchKaimahiAsUser, fetchKaimahiAsUser),
    takeEvery(DeliveriesKaimahiActionTypes.StoreKaimahi, storeKaimahi),
    takeEvery(DeliveriesKaimahiActionTypes.DeleteKaimahi, deleteKaimahi),
    takeEvery(DeliveriesKaimahiActionTypes.SetKaimahiSelectedInstance, setSelectedInstance),
    takeEvery(DeliveriesKaimahiActionTypes.StoreDefaultInstance, storeDefaultInstance)
];

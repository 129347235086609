import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesNavigatorInstanceActionTypes {
    FetchNavigatorInstanceList = 'deliveries/fetchDeliveriesNavigatorInstanceList',
    SetTotalInDeliveriesNavigatorInstanceFilters = 'deliveries/setTotalInDeliveriesNavigatorInstanceFilters',
    SetNavigatorInstanceFilters = 'deliveries/setNavigatorInstanceFilters',
    SetNavigatorInstanceList = 'deliveries/setNavigatorInstanceList',
    StoreNavigatorInstance = 'deliveries/storeNavigatorInstance',
    StoreNavigatorInstanceFilters = 'deliveries/storeNavigatorInstanceFilters',
    SetNavigatorInstance = 'deliveries/setNavigatorInstance',
    FetchNavigatorInstance = 'deliveries/fetchNavigatorInstance',
    UpdateNavigatorInstanceList = 'deliveries/updateNavigatorInstance',
    DeleteNavigatorInstance = 'deliveries/deleteNavigatorInstance',
    SetInstanceListForKaimahi = 'deliveries/setInstanceListForKaimahi',
    FetchInstanceListForKaimahi = 'deliveries/fetchInstanceListForKaimahi',
    SetSubmitSuccess = 'deliveries/setNavigatorInstanceSubmitSuccess'
}

/**
 * NavigatorInstance Actions
 */
export const deliveriesNavigatorInstanceActions = {
    fetchDeliveriesNavigatorInstanceList: (): Action<
        DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstanceList
    > => createAction(DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstanceList),
    // populate navigator Instance items
    setDeliveriesNavigatorInstanceList: (
        list
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceList,
        { list: CONTRACTS.DeliveriesNavigatorInstance[] }
    > => createAction(DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceList, { list }),
    setTotalInDeliveriesNavigatorInstanceFilters: (
        total: number
    ): ActionWithPayload<DeliveriesNavigatorInstanceActionTypes.SetTotalInDeliveriesNavigatorInstanceFilters, number> =>
        createAction(DeliveriesNavigatorInstanceActionTypes.SetTotalInDeliveriesNavigatorInstanceFilters, total),
    // populate DeliveryType filters
    setDeliveriesNavigatorInstanceFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters>
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceFilters,
        CONTRACTS.AllowedDeliveriesDeliveryTypeFilters
    > => createAction(DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceFilters, filters),
    // update DeliveryType item
    storeDeliveriesNavigatorInstance: (
        item: CONTRACTS.DeliveriesNavigatorInstance
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.StoreNavigatorInstance,
        CONTRACTS.DeliveriesNavigatorInstance
    > => createAction(DeliveriesNavigatorInstanceActionTypes.StoreNavigatorInstance, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesNavigatorInstanceActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesNavigatorInstanceActionTypes.SetSubmitSuccess, { success }),
    storeDeliveriesNavigatorInstanceFilters: (
        filters: Partial<CONTRACTS.AllowedNavigatorInstanceFilters>
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.StoreNavigatorInstanceFilters,
        CONTRACTS.AllowedNavigatorInstanceFilters
    > => createAction(DeliveriesNavigatorInstanceActionTypes.StoreNavigatorInstanceFilters, filters),

    // populate DeliveryType item
    setDeliveriesNavigatorInstance: (
        item
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstance,
        { item: CONTRACTS.DeliveriesNavigatorInstance }
    > => createAction(DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstance, { item }),
    fetchDeliveriesNavigatorInstance: (id): any =>
        createAction(DeliveriesNavigatorInstanceActionTypes.FetchNavigatorInstance, id),
    fetchInstanceListForKaimahi: (id): any =>
        createAction(DeliveriesNavigatorInstanceActionTypes.FetchInstanceListForKaimahi, id),
    // Update DeliveryType list after create and update
    updateDeliveriesNavigatorInstanceList: (
        item
    ): ActionWithPayload<
        DeliveriesNavigatorInstanceActionTypes.UpdateNavigatorInstanceList,
        CONTRACTS.DeliveriesNavigatorInstance
    > => createAction(DeliveriesNavigatorInstanceActionTypes.UpdateNavigatorInstanceList, item),
    //delete DeliveryType
    deleteDeliveriesNavigatorInstance: (id): any =>
        createAction(DeliveriesNavigatorInstanceActionTypes.DeleteNavigatorInstance, id),

    setInstanceListForKaimahi: (
        list
    ): ActionWithPayload<DeliveriesNavigatorInstanceActionTypes.SetInstanceListForKaimahi, { list: any }> =>
        createAction(DeliveriesNavigatorInstanceActionTypes.SetInstanceListForKaimahi, { list })
};

import { AxiosInstance, AxiosResponse } from 'axios';

import * as CONTRACTS from '../deliveries.contracts';

export const storeAttachment = (item: Partial<CONTRACTS.DeliveriesAttachment>, client: AxiosInstance): any => {
    return client
        .post(`/deliveries/attachment`, item)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesAttachment>) => response.data)
        .catch(error => {
            throw error.response.data ? error.response.data.title : error.response.statusText;
        });
};

export const deleteAttachment = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/attachment/${id}`).catch(error => {
        throw error.response.data ? error.response.data.title : error.response.statusText;
    });

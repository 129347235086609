import * as H from 'history';

import { Action, ActionsUnion, ActionWithPayload, createAction } from '../../utils/action.helpers';
import { AllowedDeliveryFilters, Delivery, Kaimahi } from './delivery.contracts';

/**
 * Action Types
 */
export enum DeliveryActionTypes {
    FetchDeliveryList = 'delivery/fetchDeliveryList',
    SetDeliveryList = 'delivery/setDeliveryList',
    ClearDeliveryList = 'delivery/setDeliveryList',
    FetchDeliveryItem = 'delivery/fetchDeliveryItem',
    SetDeliveryItem = 'delivery/setDeliveryItem',
    StoreDeliveryItem = 'delivery/storeDeliveryItem',
    SetDeliveryFilters = 'delivery/setDeliveryFilters',
    ClearDeliveryFilters = 'delivery/setDeliveryFilters',
    // temp
    FetchZoneListByProvider = 'kaimahi/fetchZoneListByProvider',
    SetZoneList = 'kaimahi/setZoneList',
    FetchKaimahiListByProvider = 'kaimahi/fetchKaimahiListByProvider',
    FetchKaimahiById = 'kaimahi/fetchKaimahiById',
    SetKaimahiList = 'kaimahi/setKaimahiList'
}

/**
 * Actions
 */
export const deliveryActions = {
    // retrieve delivery item
    fetchDeliveryItem: (id): any => createAction(DeliveryActionTypes.FetchDeliveryItem, id),

    // update delivery item
    storeDeliveryItem: (
        item: Delivery,
        history: H.History<any>
    ): ActionWithPayload<DeliveryActionTypes.StoreDeliveryItem, { item: Delivery; history: H.History<any> }> =>
        createAction(DeliveryActionTypes.StoreDeliveryItem, { item, history }),

    // populate item
    setDeliveryItem: (item: Delivery): ActionWithPayload<DeliveryActionTypes.SetDeliveryItem, { item: Delivery }> =>
        createAction(DeliveryActionTypes.SetDeliveryItem, { item }),

    // retrieve delivery item
    fetchDeliveryList: (): Action<DeliveryActionTypes.FetchDeliveryList> =>
        createAction(DeliveryActionTypes.FetchDeliveryList),

    // populate list
    setDeliveryList: (list: Delivery[]): ActionWithPayload<DeliveryActionTypes.SetDeliveryList, { list: Delivery[] }> =>
        createAction(DeliveryActionTypes.SetDeliveryList, { list }),

    // clear list
    clearDeliveryList: (): Action<DeliveryActionTypes.ClearDeliveryList> =>
        createAction(DeliveryActionTypes.ClearDeliveryList),

    // populate filters
    setDeliveryFilters: (
        filters: Partial<AllowedDeliveryFilters>
    ): ActionWithPayload<DeliveryActionTypes.SetDeliveryFilters, { filters: AllowedDeliveryFilters }> =>
        createAction(DeliveryActionTypes.SetDeliveryFilters, { filters }),

    // clear filters
    clearDeliveryFilters: (): Action<DeliveryActionTypes.ClearDeliveryFilters> =>
        createAction(DeliveryActionTypes.ClearDeliveryFilters),

    // retrieve zone list by provider id item
    fetchZoneListByProvider: (providerId): any => createAction(DeliveryActionTypes.FetchZoneListByProvider, providerId),

    // populate zone list
    setZoneList: (list: Kaimahi[]): ActionWithPayload<DeliveryActionTypes.SetZoneList, { list: Kaimahi[] }> =>
        createAction(DeliveryActionTypes.SetZoneList, { list }),

    // retrieve kaimahi list by provider id item
    fetchKaimahiListByProvider: (providerId): any =>
        createAction(DeliveryActionTypes.FetchKaimahiListByProvider, providerId),

    // retrieve kaimahi list by id
    fetchKaimahiById: (id): any => createAction(DeliveryActionTypes.FetchKaimahiById, id),

    // populate kaimahi list
    setKaimahiList: (list: Kaimahi[]): ActionWithPayload<DeliveryActionTypes.SetKaimahiList, { list: Kaimahi[] }> =>
        createAction(DeliveryActionTypes.SetKaimahiList, { list })
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type DeliveryActionsUnion = ActionsUnion<typeof deliveryActions>;

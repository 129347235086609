import { container, singleton } from 'tsyringe';

import { Logger } from '@whanau/diagnostics';

import { deepMerge } from '../utils/deep-merge';
import { restService } from './rest-client.service';

export interface BaseConfiguration {
    rest: RestConfiguration;
    auth: {
        loginPage: string;
        successRedirectPage: string;
    };
}

export interface RestConfiguration {
    baseURL: string;
    timeout: number;
}

const DEFAULT: BaseConfiguration = {
    rest: {
        baseURL: 'http://localhost:3333',
        timeout: 0
    },
    auth: {
        loginPage: '/login',
        successRedirectPage: '/patients'
    }
};

/**
 * ConfigurationService class
 *
 * hold configuration objeject in key-value pair
 */
@singleton()
export class ConfigurationService {
    private _config: BaseConfiguration & any = DEFAULT;

    public merge(value: Partial<BaseConfiguration> & any): void {
        this._config = deepMerge(this._config, value);
        Logger.info('Configuration service updated', this._config);
        // update rest service configuration
        restService.configure(this._config.rest);
    }

    /**
     * Add (or override) a configuration item with given key and value
     */
    public extend(key: string, value: any): void {
        this.extentionHelper(this._config, key, value);
        Logger.info('Configuration service updated', this._config);
        // update rest service configuration as well
        restService.configure(this._config.rest);
    }

    /**
     * Retrieves configuration item by key
     */
    public get(key: string): any {
        return this.getHelper(key);
    }

    /**
     * Remove configuration item by key
     */
    public remove(key: string): void {
        this.removeHelper(this._config, key);
    }

    private extentionHelper(obj: any, path: string, value: any): void {
        const keys = path.split('.');
        if (keys.length === 0) {
            Logger.warn(`Configuration service: invalid key "${path}" used while extending configuration`);
        } else {
            const lastKey = keys.pop();
            const lastObj = keys.reduce((obj: any, key: string) => (obj[key] = obj[key] || {}), obj);
            lastObj[lastKey] = value;
        }
    }

    private getHelper(key: string): any {
        const configKeys: string[] = key.split('.');
        let value: any = null;
        for (let i = 0; i < configKeys.length; i++) {
            if (value === null) {
                //Initialize value to access hirarchy.
                value = this._config[configKeys[i]];
            } else {
                //Get value from hirarchy
                value = value[configKeys[i]];
            }
        }
        return value;
    }

    private removeHelper(obj: any, key: string): any {
        const keys: string[] = key.split('.');
        if (keys.length === 0) {
            Logger.warn(`Configuration service: invalid key "${key}" used while removing configuration`);
        } else {
            const lastKey = keys.pop();
            const lastObj = keys.reduce((obj: any, key: string) => (obj[key] = obj[key] || {}), obj);
            if (Object.prototype.hasOwnProperty.call(lastObj, lastKey)) {
                delete lastObj[lastKey];
            }
        }
    }
}

/**
 * Global container hosted, singleton instance of ConfigurationService
 */
export const configurationService = container.resolve(ConfigurationService);

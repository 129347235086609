import * as CONTRACTS from './contracts';

export const getNhi = (state: any): Partial<CONTRACTS.nhi> => {
    return state.nhiLookup.nhi.item || {};
};

export const getNhiList = (state: any): CONTRACTS.nhi[] => {
    return state.nhiLookup.nhi.list || [];
};

export const getNhiFilters = (state: any): Partial<CONTRACTS.nhiFilters> => {
    return state.nhiLookup.nhi.filters || {};
};
export const getNhiSearchparameters = (state: any): Partial<CONTRACTS.nhiSearchParameters> => {
    return state.nhiLookup.nhi.parameters || {};
};
export const setNHIItemId = (state: any): string => {
    return state.nhiLookup.nhi.id || '';
};

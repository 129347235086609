import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesAttachmentActionTypes {
    FetchAttachmentList = 'deleveries/fetchAttachmentList',
    SetAttachmentList = 'deleveries/setAttachmentList',
    ClearAttachmentList = 'deleveries/clearAttachmentList',
    FetchAttachment = 'deleveries/fetchAttachment',
    SetAttachment = 'deleveries/setAttachment',
    DeleteAttachment = 'deliveries/deleteAttachment',
    StoreAttachment = 'deliveries/storeAttachment',
    SetAttachmentFilters = 'deliveries/setAttachmentFilters',
    StoreAttachmentFilters = 'deliveries/storeAttachmentFilters',
    ClearAttachmentFilters = 'deliveries/clearAttachmentFilters',
    SetSubmitSuccess = 'deliveries/setAttachmentSubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesAttachmentActions = {
    // retrieve Attachment items
    fetchDeliveriesAttachmentList: (): Action<DeliveriesAttachmentActionTypes.FetchAttachmentList> =>
        createAction(DeliveriesAttachmentActionTypes.FetchAttachmentList),

    // populate Attachment items
    setDeliveriesAttachmentList: (
        list: CONTRACTS.DeliveriesAttachment[]
    ): ActionWithPayload<
        DeliveriesAttachmentActionTypes.SetAttachmentList,
        { list: CONTRACTS.DeliveriesAttachment[] }
    > => createAction(DeliveriesAttachmentActionTypes.SetAttachmentList, { list }),

    // retrieve Attachment item
    fetchDeliveriesAttachment: (id): any => createAction(DeliveriesAttachmentActionTypes.FetchAttachment, id),

    // populate Attachment item
    setDeliveriesAttachment: (
        item
    ): ActionWithPayload<DeliveriesAttachmentActionTypes.SetAttachment, { item: CONTRACTS.DeliveriesAttachment }> =>
        createAction(DeliveriesAttachmentActionTypes.SetAttachment, { item }),

    // update Attachment item
    storeDeliveriesAttachment: (
        item: CONTRACTS.DeliveriesAttachment
    ): ActionWithPayload<DeliveriesAttachmentActionTypes.StoreAttachment, CONTRACTS.DeliveriesAttachment> =>
        createAction(DeliveriesAttachmentActionTypes.StoreAttachment, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesAttachmentActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesAttachmentActionTypes.SetSubmitSuccess, { success }),

    // clear Attachment list
    clearDeliveriesAttachmentList: (): Action<DeliveriesAttachmentActionTypes.ClearAttachmentList> =>
        createAction(DeliveriesAttachmentActionTypes.ClearAttachmentList),

    // populate Attachment filters
    setDeliveriesAttachmentFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesAttachmentFilters>
    ): ActionWithPayload<
        DeliveriesAttachmentActionTypes.SetAttachmentFilters,
        CONTRACTS.AllowedDeliveriesAttachmentFilters
    > => createAction(DeliveriesAttachmentActionTypes.SetAttachmentFilters, filters),

    storeDeliveriesAttachmentFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesAttachmentFilters>
    ): ActionWithPayload<
        DeliveriesAttachmentActionTypes.StoreAttachmentFilters,
        CONTRACTS.AllowedDeliveriesAttachmentFilters
    > => createAction(DeliveriesAttachmentActionTypes.StoreAttachmentFilters, filters),

    // clear Attachment filters
    clearDeliveriesAttachmentFilters: (): Action<DeliveriesAttachmentActionTypes.ClearAttachmentFilters> =>
        createAction(DeliveriesAttachmentActionTypes.ClearAttachmentFilters),

    //delete Attachment
    deleteDeliveriesAttachment: (id): any => createAction(DeliveriesAttachmentActionTypes.DeleteAttachment, id)
};

import { call, put, select, takeEvery } from 'redux-saga/effects';

import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import * as CONTRACTS from '../deliveries.contracts';
import { deliveriesDeliveryTypeActions, DeliveriesDeliveryTypeActionTypes } from './actions';
import * as API from './api';
import { getDeliveriesDeliveryTypeFilters } from './selectors';

/**
 * Delivery Types Sagas
 */
export function* fetchDeliveryTypeList(action): any {
    // Set loading status & clear messages
    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    try {
        // set filters from selector
        const filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters> = yield select(
            getDeliveriesDeliveryTypeFilters
        );

        // Call API (asynchronous, so yield)
        const response = yield call(API.fetchDeliveryTypeList, filters, restService.client);
        // Update store
        yield put(
            deliveriesDeliveryTypeActions.setDeliveriesDeliveryTypeList(
                Array.isArray(response.items) ? response.items : []
            )
        );

        yield put(deliveriesDeliveryTypeActions.setTotalInDeliveriesDeliveryTypeFilters(response.total));
        yield put(deliveriesDeliveryTypeActions.setSubmitSuccess(false));

        // Display message
        yield put(setSuccessMessage(SystemMessageType.refresh));
    } catch (error) {
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        // Back to idle
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* fetchDeliveryType(action): any {
    const deliveryTypeId = action.payload;
    if (deliveryTypeId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to fetch the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            const response = yield call(API.fetchDeliveryType, deliveryTypeId, restService.client);
            yield put(deliveriesDeliveryTypeActions.setDeliveriesDeliveryType(response));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* storeDeliveryType(action): any {
    let response;

    const deliverytype: CONTRACTS.DeliveriesDeliveryType = action.payload;

    yield put(systemActions.setStatus(SystemStatus.loading));
    yield put(systemActions.clearMessage());

    // Try to update or create the delevery Type
    try {
        //Call update api
        response = yield call(API.storeDeliveryType, deliverytype, restService.client);
        yield put(deliveriesDeliveryTypeActions.setDeliveriesDeliveryType(response));
        //update the list after update or create
        yield put(deliveriesDeliveryTypeActions.updateDeliveriesDeliveryTypeList(response));
        // Update message
        yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));

        yield put(deliveriesDeliveryTypeActions.setSubmitSuccess(true));
    } catch (error) {
        yield put(deliveriesDeliveryTypeActions.setSubmitSuccess(false));
        // If we get an error, set the message
        yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
        return false;
    } finally {
        yield put(systemActions.setStatus(SystemStatus.idle));
    }
}

export function* deleteDeliveryType(action): any {
    const deliveryTypeId = action.payload;
    if (deliveryTypeId) {
        yield put(systemActions.setStatus(SystemStatus.loading));
        yield put(systemActions.clearMessage());

        // Try to delete the delevery Type
        try {
            // Call Auth API (asynchronous, so yield)
            yield call(API.deleteDeliveryType, deliveryTypeId, restService.client);
            // Update message
            yield put(setSuccessMessage(SystemMessageType.crud, defaultSuccessMessage));
        } catch (error) {
            // If we get an error, set the message
            yield put(setErrorMessage(SystemMessageType.crud, error.title || error.statusText || error.message, error));
            return false;
        } finally {
            // Back to idle
            yield put(systemActions.setStatus(SystemStatus.idle));
        }
    }
}

export function* setDeliveryTypeFilters(action): any {
    // set filters from action
    const filters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters> = action.payload;

    const existingFilters: Partial<CONTRACTS.AllowedDeliveriesDeliveryTypeFilters> = yield select(
        getDeliveriesDeliveryTypeFilters
    );

    //Update filters
    yield put(deliveriesDeliveryTypeActions.storeDeliveriesDeliveryTypeFilters({ ...existingFilters, ...filters }));

    yield* fetchDeliveryTypeList(action);
}

export const deliveriesDeliveryTypeSagas = [
    //DeliveryTypes
    takeEvery(DeliveriesDeliveryTypeActionTypes.FetchDeliveryTypeList, fetchDeliveryTypeList),
    takeEvery(DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeFilters, setDeliveryTypeFilters),
    takeEvery(DeliveriesDeliveryTypeActionTypes.FetchDeliveryType, fetchDeliveryType),
    takeEvery(DeliveriesDeliveryTypeActionTypes.StoreDeliveryType, storeDeliveryType),
    takeEvery(DeliveriesDeliveryTypeActionTypes.DeleteDeliveryType, deleteDeliveryType)
];

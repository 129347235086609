import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State3ActionTypes {
    FetchItem = 'State3/fetchItem',
    SetState3Item = 'State3/setState3Item',
    StoreItem = 'State3/storeItem',
    SetState3Image = 'State3/setState3Image',
    SaveReferralItem = 'State3/saveReferralItem',
    UpdateReferralItem = 'State3/updateReferralItem',
    FetchReferralItem = 'State3/fetchReferralItem',
    ClearState3Item = 'State3/clearState3Item',
    ClearState3Image = 'State3/clearState3Image'
}
/**
 * Actions
 */
export const State3Actions = {
    setState3Image: (Img): ActionWithPayload<State3ActionTypes.SetState3Image, { Img }> =>
        createAction(State3ActionTypes.SetState3Image, { Img }),
    setState3Item: (item): ActionWithPayload<State3ActionTypes.SetState3Item, { item: CONTRACTS.Item }> =>
        createAction(State3ActionTypes.SetState3Item, { item }),
    clearState3Image: (): Action<State3ActionTypes.ClearState3Image> =>
        createAction(State3ActionTypes.ClearState3Image),
    clearState3Item: (): Action<State3ActionTypes.ClearState3Item> => createAction(State3ActionTypes.ClearState3Item),
    storeItem: (item): ActionWithPayload<State3ActionTypes.StoreItem, CONTRACTS.Item> =>
        createAction(State3ActionTypes.StoreItem, item),
    saveReferralItem: (
        item,
        params
    ): ActionWithPayload<State3ActionTypes.SaveReferralItem, { item: CONTRACTS.Item; params }> =>
        createAction(State3ActionTypes.SaveReferralItem, { item, params }),
    updateReferralItem: (
        item,
        params
    ): ActionWithPayload<State3ActionTypes.UpdateReferralItem, { item: CONTRACTS.Item; params }> =>
        createAction(State3ActionTypes.UpdateReferralItem, { item, params }),
    fetchReferralItem: (key): ActionWithPayload<State3ActionTypes.FetchReferralItem, { key: string }> =>
        createAction(State3ActionTypes.FetchReferralItem, key)
};

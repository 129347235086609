import emailjs from 'emailjs-com';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ChildHubActions } from '../';
import { restService } from '../../../services';
import { defaultSuccessMessage } from '../../../shared';
import { setErrorMessage, setSuccessMessage } from '../../../utils/messages';
import { systemActions, SystemMessageType, SystemStatus } from '../../_system';
import { State3Actions, State3ActionTypes } from './actions';
import * as API from './api';
import * as CONTRACTS from './contracts';

export function* saveReferralItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const key = action.payload.item.combinedKey;
        const item = action.payload.item;
        const dataForm = action.payload.params;
        response = yield call(API.saveItem, key, item, restService.client);
        yield put(ChildHubActions.setReferralItem(response.data));
        if (response.data) {
            yield put(ChildHubActions.setSuccess(true));
            emailjs.send('sengrid_service', 'template_u1c1eyi', dataForm, 'mSgkJWlPJ7rgNWwfs');
            yield put(ChildHubActions.setActiveStep(8));
        } else {
            yield put(ChildHubActions.setSuccess(false));
            yield put(ChildHubActions.setActiveStep(5));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(ChildHubActions.setActiveStep(5));
        yield put(ChildHubActions.setSuccess(false));
        return false;
    }
}

export function* updateReferralItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api
        const key = action.payload.item.combinedKey;
        const item = action.payload.item;
        const dataForm = action.payload.params;
        response = yield call(API.updateItem, key, item, restService.client);
        yield put(ChildHubActions.setReferralItem(response.data));
        if (response.data) {
            yield put(ChildHubActions.setSuccess(true));
            emailjs.send('sengrid_service', 'template_u1c1eyi', dataForm, 'mSgkJWlPJ7rgNWwfs');
            yield put(ChildHubActions.setActiveStep(8));
        } else {
            yield put(ChildHubActions.setSuccess(false));
            yield put(ChildHubActions.setActiveStep(5));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        yield put(ChildHubActions.setActiveStep(5));
        yield put(ChildHubActions.setSuccess(false));
        return false;
    }
}

export function* fetchReferralItem(action): any {
    let response;
    // Set loading status
    yield put(systemActions.setStatus(SystemStatus.loading));
    // Try updating client
    try {
        //Call update api

        response = yield call(API.fetchItem, action.payload, restService.client);
        yield put(ChildHubActions.setReferralItem(response.data));
        if (response.data) {
            const result = response.data;
            yield put(ChildHubActions.setState1Item(result[0]));
            yield put(ChildHubActions.setState2Item(result[1]));
            yield put(ChildHubActions.setState2_2Item(result[2]));
            yield put(ChildHubActions.setState2_21Item(result[3]));
            yield put(ChildHubActions.setState2_22Item(result[4]));
            yield put(ChildHubActions.setState3Item(result[5]));
            yield put(ChildHubActions.setItem(action.payload));
            yield put(ChildHubActions.setState4Item(result[6]));
        }
    } catch (error) {
        // If we get an error, set the message
        // yield put({type: REQUEST_ERROR, error: error.message})
        // yield put(ChildHubActions.setActiveStep(5));
        // yield put(ChildHubActions.setSuccess(false));
        // return false;
    }
}

export const State3Sagas = [
    takeEvery(State3ActionTypes.SaveReferralItem, saveReferralItem),
    takeEvery(State3ActionTypes.UpdateReferralItem, updateReferralItem),
    takeEvery(State3ActionTypes.FetchReferralItem, fetchReferralItem)
];

import * as CONTRACTS from '../deliveries.contracts';

/**
 * Zone Selectors
 */
export const getDeliveriesZone = (state: any): Partial<CONTRACTS.ZoneBulkAssign> => {
    return state.deliveries.zone.item || {};
};

export const getDeliveriesZoneList = (state: any): CONTRACTS.DeliveriesZone[] => {
    return state.deliveries.zone.list || [];
};

export const getDeliveriesZoneFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesZoneFilters> => {
    return state.deliveries.zone.filters || {};
};

export const getZoneSuccess = (state: any): boolean => {
    return state.deliveries.zone.success || false;
};

import produce from 'immer';

import { AuthActionsUnion, AuthActionTypes } from './auth.actions';
import { AuthState } from './auth.contracts';

/**
 * Reducer
 */
export const AUTH_REDUCER_DEFAULT: AuthState = {
    accessToken: null,
    instanceNameUrl: null,
    hasDefaultInstance: false,
    navigatorId: null,
    instances: [],
    showModalForInstance: false,
    fromModal: false
};

export const authReducer = (state: AuthState, action: AuthActionsUnion): AuthState =>
    produce(state || AUTH_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case AuthActionTypes.SetUserToken:
                draft.accessToken = action.payload.accessToken || null;
                draft.instanceNameUrl = action.payload.instanceNameUrl || null;
                draft.navigatorId = action.payload.navigatorId || null;
                break;
            case AuthActionTypes.SetUserProfile:
                draft.user = action.payload.profile || null;
                break;
            case AuthActionTypes.SetHasDefaultInstance:
                draft.hasDefaultInstance = action.payload;
                break;
            case AuthActionTypes.SetShowModalForInstance:
                draft.showModalForInstance = action.payload;
                break;
            case AuthActionTypes.SetFromModal:
                draft.fromModal = action.payload;
                break;

            case AuthActionTypes.SetInstances:
                draft.instances = action.payload;
                break;
            default:
                return draft;
        }
    });

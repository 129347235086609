import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesHouseholdActionTypes {
    FetchHouseholdList = 'deleveries/fetchHouseholdList',
    SetHouseholdList = 'deleveries/setHouseholdList',
    ClearHouseholdList = 'deleveries/clearHouseholdList',
    UpdateHouseholdList = 'deliveries/updateHouseholdList',
    FetchHousehold = 'deleveries/fetchHousehold',
    SetHousehold = 'deleveries/setHousehold',
    DeleteHousehold = 'deliveries/deleteHousehold',
    StoreHousehold = 'deliveries/storeHousehold',
    SetHouseholdFilters = 'deliveries/setHouseholdFilters',
    StoreHouseholdFilters = 'deliveries/storeHouseholdFilters',
    ClearHouseholdFilters = 'deliveries/clearHouseholdFilters',
    ClearHouseholdItem = 'deliveries/clearHouseholdItem',
    SetTotalInDeliveriesHouseholdFilters = 'deliveries/setTotalInDeliveriesHouseholdFilters',
    SetSubmitSuccess = 'deliveries/setHouseholdSubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesHouseholdActions = {
    // retrieve Household items
    fetchDeliveriesHouseholdList: (): Action<DeliveriesHouseholdActionTypes.FetchHouseholdList> =>
        createAction(DeliveriesHouseholdActionTypes.FetchHouseholdList),

    // populate Household items
    setDeliveriesHouseholdList: (
        list
    ): ActionWithPayload<
        DeliveriesHouseholdActionTypes.SetHouseholdList,
        { list: Partial<CONTRACTS.DeliveriesHousehold[]> }
    > => createAction(DeliveriesHouseholdActionTypes.SetHouseholdList, { list }),

    // Update list after create and update
    updateDeliveriesHouseholdList: (
        item
    ): ActionWithPayload<DeliveriesHouseholdActionTypes.UpdateHouseholdList, CONTRACTS.DeliveriesHousehold> =>
        createAction(DeliveriesHouseholdActionTypes.UpdateHouseholdList, item),

    // retrieve Household item
    fetchDeliveriesHousehold: (id): any => createAction(DeliveriesHouseholdActionTypes.FetchHousehold, id),

    // populate Household item
    setDeliveriesHousehold: (
        item
    ): ActionWithPayload<DeliveriesHouseholdActionTypes.SetHousehold, { item: CONTRACTS.DeliveriesHousehold }> =>
        createAction(DeliveriesHouseholdActionTypes.SetHousehold, { item }),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesHouseholdActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesHouseholdActionTypes.SetSubmitSuccess, { success }),

    // update Household item
    storeDeliveriesHousehold: (
        item: CONTRACTS.DeliveriesHousehold
    ): ActionWithPayload<DeliveriesHouseholdActionTypes.StoreHousehold, CONTRACTS.DeliveriesHousehold> =>
        createAction(DeliveriesHouseholdActionTypes.StoreHousehold, item),

    // clear Household list
    clearDeliveriesHouseholdList: (): Action<DeliveriesHouseholdActionTypes.ClearHouseholdList> =>
        createAction(DeliveriesHouseholdActionTypes.ClearHouseholdList),

    // populate Household filters
    setDeliveriesHouseholdFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters>
    ): ActionWithPayload<
        DeliveriesHouseholdActionTypes.SetHouseholdFilters,
        CONTRACTS.AllowedDeliveriesHouseholdFilters
    > => createAction(DeliveriesHouseholdActionTypes.SetHouseholdFilters, filters),

    storeDeliveriesHouseholdFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters>
    ): ActionWithPayload<
        DeliveriesHouseholdActionTypes.StoreHouseholdFilters,
        CONTRACTS.AllowedDeliveriesHouseholdFilters
    > => createAction(DeliveriesHouseholdActionTypes.StoreHouseholdFilters, filters),

    setTotalInDeliveriesHouseholdFilters: (
        total: number
    ): ActionWithPayload<DeliveriesHouseholdActionTypes.SetTotalInDeliveriesHouseholdFilters, number> =>
        createAction(DeliveriesHouseholdActionTypes.SetTotalInDeliveriesHouseholdFilters, total),

    // clear Household filters
    clearDeliveriesHouseholdFilters: (): Action<DeliveriesHouseholdActionTypes.ClearHouseholdFilters> =>
        createAction(DeliveriesHouseholdActionTypes.ClearHouseholdFilters),
    clearDeliveriesHouseholdItem: (): Action<DeliveriesHouseholdActionTypes.ClearHouseholdItem> =>
        createAction(DeliveriesHouseholdActionTypes.ClearHouseholdItem),

    //delete Household
    deleteDeliveriesHousehold: (id): any => createAction(DeliveriesHouseholdActionTypes.DeleteHousehold, id)
};

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from './contracts';

export const fetchCaseloadList = (filters: Partial<CONTRACTS.AllowedCaseloadFilters>, client: AxiosInstance): any =>
    client
        .get(`/caseload?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.Caseload[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchCaseload = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/caseload/${id}`)
        .then((response: AxiosResponse<CONTRACTS.Caseload>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeCaseload = (item: Partial<CONTRACTS.Caseload>, client: AxiosInstance): any => {
    if (item.individualId) {
        return client
            .put(`/caseload/${item.individualId}`, item)
            .then((response: AxiosResponse<CONTRACTS.Caseload>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/caseload`, item)
            .then((response: AxiosResponse<CONTRACTS.Caseload>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteCaseload = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/caseload/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

import * as CONTRACTS from './contracts';

export const getState2Item = (state: any): Partial<CONTRACTS.Item> => {
    return state.ChildHub.State_2.item || {};
};
export const getState2_2Item = (state: any): Partial<CONTRACTS.Wairua> => {
    return state.ChildHub.State_2.Wairua || {};
};
export const getState2_21Item = (state: any): Partial<CONTRACTS.Hinengaro> => {
    return state.ChildHub.State_2.Hinengaro || {};
};
export const getState2_22Item = (state: any): Partial<CONTRACTS.Tinana> => {
    return state.ChildHub.State_2.Tinana || {};
};
export const getState2Img = (state: any): CONTRACTS.Img => {
    return state.ChildHub.State_2.Img || {};
};
export const getState22Img = (state: any): CONTRACTS.Img => {
    return state.ChildHub.State_2.Img2 || {};
};
export const getState221Img = (state: any): CONTRACTS.Img => {
    return state.ChildHub.State_2.Img21 || {};
};
export const getState222Img = (state: any): CONTRACTS.Img => {
    return state.ChildHub.State_2.Img22 || {};
};

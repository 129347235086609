import { ISagaModule } from 'redux-dynamic-modules-saga';

import { deliveryActions } from './delivery.actions';
import { AllowedDeliveryFilters, DeliveryState } from './delivery.contracts';
import { deliveryReducer } from './delivery.reducer';
import { rootDeliverySaga } from './delivery.saga';

// depends on authModule
// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getDeliveryModule(filters: AllowedDeliveryFilters): [ISagaModule<DeliveryState>] {
    return [
        {
            // Unique id of the module
            id: 'delivery',
            // Maps the Store key to the reducer
            reducerMap: {
                delivery: deliveryReducer
            } as any,
            // Initial actions array for this module
            initialActions: [
                deliveryActions.fetchKaimahiById(filters.kaimahiId)
                // deliveryActions.setDeliveryFilters(filters)
            ],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootDeliverySaga]
        }
    ];
}

import React, { ReactElement } from 'react';

import { ITheme, loadTheme } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';

// import { Logger } from '@whanau/diagnostics';
import defaultTheme from './takahe';

/**
 * Bootstraps Fabric UI theme with a provided one or takahe's defaults
 * @param theme
 */
const bootstrapTheme = (theme?: Partial<ITheme>): void => {
    const loadedTheme = loadTheme(theme || defaultTheme);
    initializeIcons();
    // Logger.info('UI components and theme initialized with', loadedTheme || 'defaults');
};

export const StorybookThemeProvider = (): ReactElement => {
    bootstrapTheme();
    return <div></div>;
};

export const withTheme = (ThemedApp, theme?): any => {
    bootstrapTheme(theme);
    // eslint-disable-next-line
    return class extends React.Component {
        render(): ReactElement {
            return <ThemedApp />;
        }
    };
};

import * as CONTRACTS from './contracts';

export const getState6NTWItem = (state: any): any => {
    return (
        state.NTWReport.State_6.item || [
            {
                id: 1,
                description: ''
            }
        ]
    );
};
// export const getState5Wairua = (state: any): Partial<CONTRACTS.Wairua> => {
//     return state.ChildHub.State_5.WairuaItem || {};
// };
// export const getState5Hinengaro = (state: any): Partial<CONTRACTS.Hinengaro> => {
//     return state.ChildHub.State_5.HinengaroItem || {};
// };
// export const getState5Tinana = (state: any): Partial<CONTRACTS.Tinana> => {
//     return state.ChildHub.State_5.TinanaItem || {};
// };
// export const getState5Img = (state: any): CONTRACTS.Img => {
//     return state.ChildHub.State_5.Img || {};
// };

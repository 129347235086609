import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum CasenoteActionTypes {
    FetchCasenoteList = 'casenote/fetchCasenoteList',
    SetCasenoteList = 'casenote/setCasenoteList',
    ClearCasenoteList = 'casenote/clearCasenoteList',
    UpdateCasenoteList = 'casenote/updateCasenoteList',
    FetchCasenote = 'casenote/fetchCasenote',
    SetCasenote = 'casenote/setCasenote',
    DeleteCasenote = 'casenote/deleteCasenote',
    StoreCasenote = 'casenote/storeCasenote',
    SetCasenoteFilters = 'casenote/setCasenoteFilters',
    StoreCasenoteFilters = 'casenote/storeCasenoteFilters',
    SetTotalInCasenoteFilters = 'casenote/setTotalInCasenoteFilters',
    ClearCasenoteFilters = 'casenote/clearCasenoteFilters'
}

/**
 * Actions
 */
export const planCasenoteActions = {
    // retrieve Casenote items
    fetchCasenoteList: (): Action<CasenoteActionTypes.FetchCasenoteList> =>
        createAction(CasenoteActionTypes.FetchCasenoteList),

    // populate Casenote items
    setCasenoteList: (list): ActionWithPayload<CasenoteActionTypes.SetCasenoteList, { list: CONTRACTS.Casenote[] }> =>
        createAction(CasenoteActionTypes.SetCasenoteList, { list }),

    // Update list after create and update
    updateCasenoteList: (item): ActionWithPayload<CasenoteActionTypes.UpdateCasenoteList, CONTRACTS.Casenote> =>
        createAction(CasenoteActionTypes.UpdateCasenoteList, item),

    // retrieve Casenote item
    fetchCasenote: (id): any => createAction(CasenoteActionTypes.FetchCasenote, id),

    // populate Casenote item
    setCasenote: (item): ActionWithPayload<CasenoteActionTypes.SetCasenote, { item: CONTRACTS.Casenote }> =>
        createAction(CasenoteActionTypes.SetCasenote, { item }),

    // update Casenote item
    storeCasenote: (
        item: CONTRACTS.Casenote
    ): ActionWithPayload<CasenoteActionTypes.StoreCasenote, CONTRACTS.Casenote> =>
        createAction(CasenoteActionTypes.StoreCasenote, item),

    // clear Casenote list
    clearCasenoteList: (): Action<CasenoteActionTypes.ClearCasenoteList> =>
        createAction(CasenoteActionTypes.ClearCasenoteList),

    // populate Casenote filters
    setCasenoteFilters: (
        filters: Partial<CONTRACTS.AllowedCasenoteFilters>
    ): ActionWithPayload<CasenoteActionTypes.SetCasenoteFilters, CONTRACTS.AllowedCasenoteFilters> =>
        createAction(CasenoteActionTypes.SetCasenoteFilters, filters),

    storeCasenoteFilters: (
        filters: Partial<CONTRACTS.AllowedCasenoteFilters>
    ): ActionWithPayload<CasenoteActionTypes.StoreCasenoteFilters, CONTRACTS.AllowedCasenoteFilters> =>
        createAction(CasenoteActionTypes.StoreCasenoteFilters, filters),

    setTotalInCasenoteFilters: (
        total: number
    ): ActionWithPayload<CasenoteActionTypes.SetTotalInCasenoteFilters, number> =>
        createAction(CasenoteActionTypes.SetTotalInCasenoteFilters, total),

    // clear Casenote filters
    clearCasenoteFilters: (): Action<CasenoteActionTypes.ClearCasenoteFilters> =>
        createAction(CasenoteActionTypes.ClearCasenoteFilters),

    //delete Casenote
    deleteCasenote: (id): any => createAction(CasenoteActionTypes.DeleteCasenote, id)
};

import { Client, ClientSummary, ClientSummaryListFilters } from './client.contracts';

export const getClientSummaryList = (state: any): ClientSummary[] => {
    return state.client.summaryList.clientsFound || [];
};

export const getClientSummaryListFilters = (state: any): ClientSummaryListFilters => {
    return state.client.summaryListFilters || {};
};

export const getClientSummary = (state: any, clientID: number | string): ClientSummary => {
    return getClientSummaryList(state).find(c => c.clientID.toString() === clientID);
};

export const getClientDetail = (state: any): Client => {
    return state.client.detail || {};
};

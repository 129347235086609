import { ISagaModule } from 'redux-dynamic-modules-saga';

import { AttachmentState } from './attachment.contracts';
import { attachmentReducer } from './attachment.reducer';
import AttachmentSagas from './attachment.saga';

export function getAttachmentModule(): [ISagaModule<AttachmentState>] {
    return [
        {
            // Unique id of the module
            id: '_attachment',
            // Maps the Store key to the reducer
            reducerMap: {
                _attachment: attachmentReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [AttachmentSagas]
        }
    ];
}

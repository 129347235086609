import * as CONTRACTS from './contracts';

export const getCaseload = (state: any): Partial<CONTRACTS.Caseload> => {
    return state.caseload.item || {};
};

export const getCaseloadList = (state: any): CONTRACTS.Caseload[] => {
    return state.caseload.list || [];
};

export const getCaseloadFilters = (state: any): Partial<CONTRACTS.AllowedCaseloadFilters> => {
    return state.caseload.filters || {};
};

import produce from 'immer';

import { updateArrayObjectWithObject } from '../../utils/update-arrayObject-with-object';
import { NTWReportState } from './';
import { NTWReportActionsUnion } from './NTWReport.actions';
import { OtherActionTypes } from './Other/actions';
import { State1ActionTypes } from './State-1/actions';
import { State2ActionTypes } from './State-2/actions';
import { State3ActionTypes } from './State-3/actions';
import { State4ActionTypes } from './State-4/actions';
import { State5ActionTypes } from './State-5/actions';
import { State6ActionTypes } from './State-6/actions';
import { State7ActionTypes } from './State-7/actions';

export const INITIAL_State1_PROPERTIES: any = {
    item: {
        highlights: '',
        challenges: '',
        cohort: '',
        impact: ''
    }
};
export const INITIAL_State2_PROPERTIES: any = {
    item: {
        KaiPacksNumber: '',
        KaiPacksValue: '',
        KaiPacksInvestment: '',
        HygienePacksNumber: '',
        HygienePacksValue: '',
        HygienePacksInvestment: '',
        SupermarketVouchersNumber: '',
        SupermarketVouchersValue: '',
        SupermarketVouchersInvestment: '',
        PetrolVouchersNumber: '',
        PetrolVouchersValue: '',
        PetrolVouchersInvestment: '',
        EducationNumber: '',
        EducationValue: '',
        EducationInvestment: '',
        KaimahiNumber: '',
        KaimahiValue: '',
        KaimahiInvestment: '',
        TechnologyNumber: '',
        TechnologyValue: '',
        TechnologyInvestment: '',
        TrainingNumber: '',
        TrainingValue: '',
        TrainingInvestment: '',
        ResourceNumber: '',
        ResourceValue: '',
        ResourceInvestment: ''
    }
};
export const INITIAL_State3_PROPERTIES: any = {
    item: {}
};
export const INITIAL_State4_PROPERTIES: any = {
    item: {}
};
export const INITIAL_State5_PROPERTIES: any = {
    item: {}
};
export const INITIAL_State7_PROPERTIES: any = {
    item: {}
};
export const INITIAL_State6_PROPERTIES: any = {
    item: {}
};
export const INITIAL_Other_PROPERTIES: any = {
    item: {},
    terms: false,
    activeStep: null,
    success: false
};

export const NTWReport_REDUCER_DEFAULT = {
    State_1: INITIAL_State1_PROPERTIES,
    State_2: INITIAL_State2_PROPERTIES,
    State_3: INITIAL_State3_PROPERTIES,
    State_4: INITIAL_State4_PROPERTIES,
    State_5: INITIAL_State5_PROPERTIES,
    State_6: INITIAL_State6_PROPERTIES,
    State_7: INITIAL_State7_PROPERTIES,
    Other: INITIAL_Other_PROPERTIES
};

export const NTWReportReducer = (state: NTWReportState, action: NTWReportActionsUnion): NTWReportState =>
    produce(state || NTWReport_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            case State1ActionTypes.SetState1NTWItem:
                draft.State_1.item = action.payload.item;
                break;
            case State1ActionTypes.SetState1Image:
                draft.State_1.Img = action.payload;
                break;
            case State2ActionTypes.SetState2NTWItem:
                draft.State_2.item = action.payload.item;
                break;

            case State3ActionTypes.SetState3Item:
                draft.State_3.item = action.payload;
                break;
            case State3ActionTypes.SetState3_1Item:
                draft.State_3.item2 = action.payload;
                break;
            case State3ActionTypes.StoreItem:
                draft.State_3.item = action.payload;
                break;
            case State4ActionTypes.SetState4NTWItem:
                draft.State_4.item = action.payload.item;
                break;
            case State5ActionTypes.SetState5NTWItem:
                draft.State_5.item = action.payload.item;
                break;
            case State6ActionTypes.SetState6NTWItem:
                draft.State_6.item = action.payload.item;
                break;
            case State7ActionTypes.SetState7NTWItem:
                draft.State_7.item = action.payload.item;
                break;
            case OtherActionTypes.SetOtherItem:
                draft.Other.item = action.payload.item;
                break;

            case OtherActionTypes.SetTerms:
                draft.Other.terms = action.payload.terms;
                break;
            case OtherActionTypes.SetActiveStep:
                draft.Other.activeStep = action.payload.activeStep;
                break;
            case OtherActionTypes.SetSuccess:
                draft.Other.success = action.payload.success;
                break;
            default:
                return draft;
        }
    });

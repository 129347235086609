import './app.css';

import React, { Component, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { createStore, IModuleStore } from 'redux-dynamic-modules-core';
import { getSagaExtension } from 'redux-dynamic-modules-saga';

import { getTheme, IStackItemStyles, ITheme, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Logger } from '@whanau/diagnostics';
import {
    authActions,
    BaseConfiguration,
    configurationService,
    getAuthModule,
    restService,
    storageService
} from '@whanau/domains';
import { NgatiToaTheme, withTheme } from '@whanau/ui-core';

import { environment } from '../../environments/environment';
import { AppInsightsContextProvider } from '../shared/AppInsights/AppInsightsContext';
import { BKGD } from '../shared/palette';

// Lazy load entry component for patient module
const ModuleIndex: any = lazy(() => import('../features/pages/landing'));

const backgroundStyles: IStackItemStyles = {
    root: {
        width: '100%',
        //TODO: Test heignt in iframe. Changing to auto for development
        height: 'auto',
        //overflow: 'hidden',
        backgroundColor: BKGD,
        padding: 32
    }
};

// TODO: prepare complete configuration object when merge method is available
const appConfiguration: BaseConfiguration & any = {
    rest: {
        baseURL: environment.api.baseUrl
    },
    auth: {
        tokenEndpoint: '/login',
        loginPage: '/login',
        successRedirectPage: '/client'
    }
};

class App extends Component {
    private _store: IModuleStore<any>;
    private _theme: ITheme;
    private _token: string;

    constructor(props: any, context: any) {
        super(props, context);
        Logger.info('Application bootstrapping...');
        // init Redux modules
        this._store = createStore({ extensions: [getSagaExtension()] }, ...getAuthModule());
        // init configuration service
        configurationService.merge(appConfiguration);
        // check if we have a token in Storage
        this._token = storageService.get('AuthUserToken') || null;
        // if we do, set Redux and Rest service accordingly
        if (this._token) {
            this._store.dispatch(authActions.setUserToken(this._token));
            restService.setAuthHeaders(this._token);
        }
        // retrieve theme
        this._theme = getTheme();
        console.log('THEME', this._theme);
    }

    render(): React.ReactNode {
        return (
            <Stack styles={backgroundStyles}>
                {/* Providing store access to the whole app */}
                <Provider store={this._store}>
                    <Suspense fallback={<Spinner size={SpinnerSize.large} />}>
                        {/* AppInsights context provider */}
                        {/* <AppInsightsContextProvider> */}
                        <ModuleIndex />
                        {/* </AppInsightsContextProvider> */}
                    </Suspense>
                </Provider>
            </Stack>
        );
    }
}

export default withTheme(App, NgatiToaTheme);

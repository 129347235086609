import { AxiosInstance } from 'axios';

import { objectToQuerystring } from '../../utils/object-to-querystring';
import { AuthResponse, UserCredentials } from './auth.contracts';

export const validateUser = (userCredentials: UserCredentials, client: AxiosInstance): Promise<AuthResponse> =>
    client.post('/token', objectToQuerystring(userCredentials), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

export const refreshToken = (token: string, client: AxiosInstance): Promise<any> => {
    const encodedPayload = `grant_type=refresh_token&refresh_token=${token}&client_id=Socrates`;
    // return Axios promise
    return client.post('/token#refreshtoken', encodedPayload, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
};

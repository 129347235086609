import { format } from 'date-fns';
import { container, singleton } from 'tsyringe';

import { Dictionary } from '../types/dictionary';
import { CONFIGURATION_DEFAULTS, DATE_FORMAT_PATTERN, SUPPORTED_METHODS } from './log.constants';
import { Configuration } from './models/configuration';
import { LogProvider, LogProvidersList } from './models/provider';
import { consoleLogProviderInstance } from './providers/console';

/**
 * Diagnostics Log
 * Singleton class that provides a wrapper for console.log with support for:
 * - configurable threshold level to contain console pollution in different environments
 * - tagged entries for discrete debug, regardless of the above
 * - remote persistence via configurable XHR end point and payload mapping configuration
 *
 * Example #1 - import singleton in your code:
 * ```
 * import { Logger } from '@whanau/diagnostics';
 * ```
 *
 * Example #2 - use threshold:
 * ```
 * // configure log to only display errors
 * Logger.configure('console', {
 *     thresholdLevel: ThresholdLevel.ERROR // (4)
 * });
 *
 * // log info
 * Logger.info('Test message with arguments', {one: 1});
 *
 * // no console output
 * ```
 *
 * Example #3 - use discrete debug with tags:
 * ```
 * // change debug
 * Logger.debugByTag('security');
 *
 * // first approach, multiple entries in a class or file
 * const securityLog = Logger.tag(['security']);
 * // call one
 * securityLog.info('Test tagged message');
 * // call two
 * sceurityLog.warn('Oops! Something is not completely right');
 *
 * // second approach - one off chained version
 * Logger.tag(['security', 'login'])
 *    .info('Test message with arguments', {one: 1});
 *
 * // both will output to console, because tag match
 * ```
 */
@singleton()
export class DiagnosticsLog {
    // service configuration object
    private _configuration: Configuration = { ...CONFIGURATION_DEFAULTS };
    // log providers
    private _providers: LogProvidersList = {
        // `console` registered by default
        console: consoleLogProviderInstance
    };
    // debug tags pool
    private _debugByTagPool: string[] = [];

    /**
     * Creates a new entry (or extends default values) configuration section
     * matching provided key
     */
    public configure(name: string, newConfigurationSection: Dictionary): Configuration {
        this._configuration[name] = {
            ...this._configuration[name],
            ...newConfigurationSection,
            lastUpdatedOn: format(new Date(), DATE_FORMAT_PATTERN)
        };
        // return updated configuration
        return this._configuration;
    }

    /**
     * After any error gets thrown, this provides forwarding of the message and context to log.error
     * By defaults system errors will be suppressed.
     */
    public includeGlobalErrors(suppressSystemError?: boolean): void {
        // set exceptions interceptor
        window.onerror = (message: string, url: string, _lineNo: number, _columnNo: number, error: Error): boolean => {
            // run tap log.error()
            this.error(message, url, error);
            // suppres system error
            return suppressSystemError || true;
        };
    }

    /**
     * Allows for registration of a new log provider that will be added to the stack
     * and invoked when new entries will be logged.
     */
    public registerProvider(name: string, provider: LogProvider, configuration: Dictionary): Configuration | void {
        // validating inputs
        if (name === '') {
            console.warn('You must provide a valid name to register a provider');
            return undefined;
        }
        // assign or override
        this._providers[name] = provider;
        // return new configuration
        return this.configure(name, configuration);
    }

    /**
     * Turns on discrete tag based debugging
     * by adding actively filtered tags for log entries to come.
     * An empty payload will turn the tag filtering off.
     * @param tag
     */
    public debugByTag(tags?: string | string[]): string[] {
        // if empty clear pool
        if (typeof tags === 'undefined') {
            this._debugByTagPool = [];
        } else {
            // if not array, array-ize
            const newTags = Array.isArray(tags) ? tags : [tags];
            // iterate array
            newTags.map(tag => {
                // add to debug by tag pool if not already there
                if (tag && this._debugByTagPool.indexOf(tag) === -1) {
                    this._debugByTagPool.push(tag);
                }
            });
        }
        // return tag pool
        return this._debugByTagPool;
    }

    /**
     * Adds one or more tags to next log entry's context,
     * returns a context-aware list of console wrappers for main methods:
     * `trace`, `debug`, `info`, `warn`, `error`.
     *
     * Example with multiple calls:
     * ```
     * const taggedLog = log.tag('example');
     * taggedLog.info('Test message');
     * taggedLog.warn('Test warning');
     * ```
     *
     * Example with chaining:
     * ```
     * log.tag('example')
     *    .info('Test message');
     * ```
     *
     * @param tags
     */
    public tag(tags: string | string[]): any {
        // array-ize input type, if needed
        const contextTags = Array.isArray(tags) ? tags : [tags];
        // tag context rich methods
        const taggedMethods = {};
        // build wrappers
        SUPPORTED_METHODS.forEach((m, idx) => {
            taggedMethods[m] = (message: string, ...args: any[]): any => {
                this.logEntry(idx, m, message, contextTags, args);
            };
        });
        // return context rich methods to allow chaining
        return taggedMethods;
    }

    /**
     * Trace wrapper
     *
     * @param message
     * @param args
     */
    public trace(message: string, ...args: any[]): void {
        this.logEntry(0, 'trace', message, [], args);
    }

    /**
     * Debug wrapper
     *
     * @param message
     * @param args
     */
    public debug(message: string, ...args: any[]): void {
        // todo: Starting with Chromium 58 this method only appears in
        // Chromium browser consoles when level "Verbose" is selected.
        // see https://developer.mozilla.org/en-US/docs/Web/API/console
        this.logEntry(1, 'debug', message, [], args);
    }

    /**
     * Info wrapper
     *
     * @param message
     * @param args
     */
    public info(message: string, ...args: any[]): void {
        // console.info('message');
        // this._console['info']('message');
        this.logEntry(2, 'info', message, [], args);
    }

    /**
     * Warn wrapper
     *
     * @param message
     * @param args
     */
    public warn(message: string, ...args: any[]): void {
        this.logEntry(3, 'warn', message, [], args);
    }

    /**
     * Error wrapper
     *
     * @param message
     * @param args
     */
    public error(message: string, ...args: any[]): void {
        this.logEntry(4, 'error', message, [], args);
    }

    /**
     * Logs and optionally persist remotely entry depending on threshold and tag matching
     */
    private logEntry(severity: number, method: string, message: string, tags: string[], args: any[]): void {
        // iterate to each registered provider and
        Object.keys(this._providers).forEach((key: string) => {
            this._providers[key].log(
                this._configuration[key],
                this._debugByTagPool,
                severity,
                method,
                message,
                tags,
                args
            );
        });
    }
}

/**
 * Global container hosted, singleton instance of Logger
 * Use this preferably, unless specific need to create a secondary logger arises
 */
export const Logger = container.resolve(DiagnosticsLog);

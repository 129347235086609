import { container, singleton } from 'tsyringe';

import { Logger } from '@whanau/diagnostics';

import { memoryStorage } from '../utils/memory-storage';
import { serializer } from '../utils/serializer';

/**
 * NOTICE
 * localStorage and sessionStorage should never be used to store sensitive or personally identifiable data.
 */

// Key string separator
const keySeparator = '::';
// Prefix for all LSCacheService keys
const defaultCachePrefix = 'LSCache' + keySeparator;
// Default storage engine
const defaultStorageEngine = typeof window !== 'undefined' ? window.localStorage : memoryStorage;
// Suffix for the key name on the expiration items in selected storage engine
const cacheSuffix = keySeparator + 'cache-expiration';
// Expiration date radix (set to Base-36 for most space savings)
const expiryRadix = 10;
// Time resolution in milliseconds
const expiryUnits = 1;
// ECMAScript max Date (epoch + 1e8 days)
const maxDate = Math.floor(8.64e15 / expiryUnits);

/**
 * StorageService class
 *
 * Persists data using session or local storage.
 * Based on Pamela Fox's LSCache library (https://github.com/pamelafox/LSCache)
 */
@singleton()
export class StorageService {
    private cachedStorage: boolean;
    private cacheBucket = '';
    private warnings = false;
    private hasRun = false;

    constructor(private cachePrefix: string = defaultCachePrefix, private storageEngine: any = defaultStorageEngine) {
        //
    }

    /**
     * Allows usage of a different storage engine, if no data has already been stored
     * localStorage and sessionStorage supported
     * Note: no tests available as no Storage primitive can be used with jest so far
     * @param {Storage} engine
     */
    public setStorageEngine(engine: Storage): void {
        if (!(engine instanceof Storage)) {
            throw new Error(`Provided storage engine is not matching type 'Storage', defaults will be applied`);
        }
        if (!this.hasRun) {
            throw new Error(`Storage service has already been used, it cannot be changed unless it's flushed.`);
        }
        this.storageEngine = engine;
    }

    /**
     * Stores the value in selected storage engine.
     * Expires after specified number of milliseconds.
     * @param {string} key
     * @param {Object|string} value
     * @param {number} time
     */
    public set(key: string, value: any, time?: number): void {
        if (!this.supportsStorage()) {
            return;
        }

        const serialisedValue = serializer.stringify(value);

        try {
            this.setItem(key, serialisedValue);
        } catch (e) {
            if (!this.isOutOfSpace(e)) {
                // If it was some other error, just give up.
                if (this.warnings) {
                    Logger.warn(`Could not add item with key ${key}`, e);
                }
                return;
            }
            // If we exceeded the quota, then we will sort
            // By the expire time, and then remove the N oldest
            const storedKeys: any[] = [];
            let storedKey;
            this.eachKey((thisKey, exprKey) => {
                let expiration: number | string | null = this.getItem(exprKey);
                if (expiration) {
                    expiration = parseInt(expiration as string, expiryRadix);
                } else {
                    // MAX_DATE default added for non-expiring items
                    expiration = maxDate;
                }
                storedKeys.push({
                    key: thisKey,
                    size: (this.getItem(thisKey) || '').length,
                    expiration: expiration
                });
            });
            // Sorts the keys with oldest expiration time last
            storedKeys.sort((a, b) => b.expiration - a.expiration);

            let targetSize = (serialisedValue || '').length;
            while (storedKeys.length && targetSize > 0) {
                storedKey = storedKeys.pop();
                if (this.warnings) {
                    Logger.warn(`Cache is full, removing item with key ${key}`);
                }
                this.flushItem(storedKey.key);
                targetSize -= storedKey.size;
            }
            try {
                this.setItem(key, serialisedValue);
            } catch (e) {
                // Value may be larger than total quota
                if (this.warnings) {
                    Logger.warn(`Could not add item with key ${key}, perhaps it's too big?`, e);
                }
                return;
            }
        }

        // If a time is specified, store expiration info in selected storage engine
        if (time) {
            this.setItem(this.expirationKey(key), (this.currentTime() + time).toString(expiryRadix));
        } else {
            // In case they previously set a time, remove that info from selected storage engine.
            this.removeItem(this.expirationKey(key));
        }
    }

    /**
     * Retrieves specified value from selected storage engine, if not expired.
     * @param {string} key
     * @return {string|Object}
     */
    public get<T>(key: string): T {
        if (!this.supportsStorage()) {
            return null;
        }

        // Return the de-serialized item if not expired
        if (this.flushExpiredItem(key)) {
            return null;
        }

        const valueRaw = this.getItem(key);
        return serializer.parse(valueRaw);
    }

    /**
     * Removes a value from selected storage engine.
     * Equivalent to 'delete' in memcache, but that's a keyword in JS.
     * @param {string} key
     */
    public remove(key: string): void {
        if (!this.supportsStorage()) {
            return;
        }

        this.flushItem(key);
    }

    /**
     * Flushes all LSCacheService items and expiry markers without affecting rest of selected storage engine
     */
    public flush(): void {
        if (!this.supportsStorage()) {
            return;
        }
        this.eachKey(key => {
            this.flushItem(key);
        });
        this.hasRun = false;
    }

    /**
     * Flushes expired LSCacheService items and expiry markers without affecting rest of selected storage engine
     */
    public flushExpired(): void {
        if (!this.supportsStorage()) {
            return;
        }
        this.eachKey(key => {
            this.flushExpiredItem(key);
        });
        this.hasRun = true;
    }

    /**
     * Appends CACHE_PREFIX so LSCacheService will partition data in to different buckets.
     * @param {string} bucket
     */
    public setBucket(bucket): void {
        this.cacheBucket = bucket + keySeparator;
    }

    /**
     * Resets the string being appended to CACHE_PREFIX so LSCacheService will use the default storage behavior.
     */
    public resetBucket(): void {
        this.cacheBucket = '';
    }

    /**
     * Sets whether to display warnings when an item is removed from the cache or not.
     */
    public enableWarnings(enabled: boolean): void {
        this.warnings = enabled;
    }

    /**
     * Determines if selected storage engine is supported in the browser.
     * Result is cached for better performance instead of being run each time.
     * Feature detection is based on how Modernizr does it;
     * Todo: even if the returned result lives in memory once run for the first time, this is quite a slow method!
     * Todo: we might want to suppress or exclude by configuration at some point.
     * @returns {boolean}
     */
    private supportsStorage(): boolean {
        const key = '__LSCacheServicetest__';
        const value = key;

        /* tslint:disable */
        if (typeof this.cachedStorage !== 'undefined') {
            return this.cachedStorage;
        }
        /* tslint:enable */

        /*
         * Some browsers will throw an error if you try to access local storage (e.g. brave browser)
         * hence check is inside a try/catch
         */
        try {
            if (!this.storageEngine) {
                return false;
            }
        } catch (ex) {
            return false;
        }

        try {
            this.setItem(key, value);
            this.removeItem(key);
            this.cachedStorage = true;
        } catch (e) {
            // If we hit the limit, and we don't have an empty selected storage engine then it means we have support
            this.cachedStorage = this.isOutOfSpace(e) && this.storageEngine.length > 0;
        }
        return this.cachedStorage;
    }

    /**
     * Check to set if the error is us dealing with being out of space
     * @param e
     * @returns {boolean}
     */
    private isOutOfSpace(e): boolean {
        return (
            (e && e.name === 'QUOTA_EXCEEDED_ERR') ||
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED' ||
            e.name === 'QuotaExceededError'
        );
    }

    /**
     * Returns a string where all RegExp special characters are escaped with a \.
     * @param {String} text
     * @return {string}
     */
    private escapeRegExpSpecialCharacters(text: string): string {
        return text.replace(/[[\]{}()*+?.\\^$|]/g, '\\$&');
    }

    /**
     * Returns the full string for the selected storage engine expiration item.
     * @param {String} key
     * @return {string}
     */
    private expirationKey(key: string): string {
        return key + cacheSuffix;
    }

    /**
     * Returns the number of expiry units (ms) since the epoch.
     * @return {number}
     */
    private currentTime(): number {
        return Math.floor(Date.now() / expiryUnits);
    }

    /**
     * Wrapper functions for selected storage engine methods
     */
    private getItem(key: string): string {
        return this.storageEngine.getItem(this.cachePrefix + this.cacheBucket + key);
    }

    private setItem(key: string, value): void {
        // Fix for iPad issue - sometimes throws QUOTA_EXCEEDED_ERR on setItem.
        this.storageEngine.removeItem(this.cachePrefix + this.cacheBucket + key);
        this.storageEngine.setItem(this.cachePrefix + this.cacheBucket + key, value);
        this.hasRun = true;
    }

    private removeItem(key: string): void {
        this.storageEngine.removeItem(this.cachePrefix + this.cacheBucket + key);
        this.hasRun = true;
    }

    private eachKey(fn): void {
        const prefixRegExp = new RegExp(
            '^' + this.cachePrefix + this.escapeRegExpSpecialCharacters(this.cacheBucket) + '(.*)'
        );
        // Loop in reverse as removing items will change indices of tail
        for (let i = this.storageEngine.length - 1; i >= 0; --i) {
            let key: any = this.storageEngine.key(i);
            key = key && key.match(prefixRegExp);
            key = key && key[1];
            if (key && key.indexOf(cacheSuffix) < 0) {
                fn(key, this.expirationKey(key));
            }
        }
    }

    private flushItem(key): void {
        const exprKey = this.expirationKey(key);

        this.removeItem(key);
        this.removeItem(exprKey);
        this.hasRun = true;
    }

    private flushExpiredItem(key): boolean {
        const exprKey = this.expirationKey(key);
        const expr = this.getItem(exprKey);

        if (expr) {
            const expirationTime = parseInt(expr, expiryRadix);
            // Check if we should actually kick item out of storage
            if (this.currentTime() >= expirationTime) {
                this.removeItem(key);
                this.removeItem(exprKey);
                return true;
            }
        }
        return false;
    }
}

/**
 * Global container hosted, singleton instance of StorageService
 * Use this preferably, unless specific need to create a secondary logger arises
 */
export const storageService = container.resolve(StorageService);

import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

export const fetchHouseholdList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesHouseholdFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/household?${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesHouseholdSummary[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchHousehold = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/household/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesHousehold>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeHousehold = (item: Partial<CONTRACTS.DeliveriesHousehold>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/household/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesHousehold>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/household`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesHousehold>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const deleteHousehold = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/household/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

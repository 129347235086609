import { Delivery } from './delivery.contracts';

export const fixNullable = (item: Delivery): Delivery => {
    const notNullable = [
        'firstName',
        'lastName',
        'birthDate',
        'phone',
        'email',
        'facebook',
        'instagram',
        'street',
        'suburb',
        'city',
        'postalCode',
        'country',
        'notes'
    ];
    notNullable.map(nn => {
        if (item[nn] === null) {
            item[nn] = '';
        }
    });
    return item;
};

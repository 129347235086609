import { ActionsUnion } from '../../utils/action.helpers';
import { deliveriesAttachmentActions } from './attachment/actions';
import { deliveriesDeliveryActions } from './delivery/actions';
import { deliveriesDeliveryBulkAssignActions } from './deliveryBulkAssign/actions';
import { deliveriesHouseholdActions } from './household/actions';
import { deliveriesInventoryActions } from './inventory/actions';
import { deliveriesKaimahiActions } from './kaimahi/actions';
import { deliveriesNavigatorInstanceActions } from './navigatorInstance/actions';
import { deliveriesProviderActions } from './provider/actions';
import { deliveriesDeliveryTypeActions } from './types/actions';
import { deliveriesZoneActions } from './zone/actions';

/**
 * Actions
 */
export const deliveriesActions = {
    /**
     * Household
     */
    ...deliveriesHouseholdActions,

    /**
     * Delivery
     */
    ...deliveriesDeliveryActions,

    /**
     * DeliveryType
     */
    ...deliveriesDeliveryTypeActions,

    /**
     * Zone
     */
    ...deliveriesZoneActions,

    /**
     * Kaimahi
     */
    ...deliveriesKaimahiActions,

    /**
     * Provider
     */
    ...deliveriesProviderActions,

    /**
     * Attachment
     */
    ...deliveriesAttachmentActions,

    /**
     * Bulk Assign
     */
    ...deliveriesDeliveryBulkAssignActions,

    /**
     * Inventory
     */
    ...deliveriesInventoryActions,

    /**
     * Inventory
     */
    ...deliveriesNavigatorInstanceActions
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type DeliveriesActionsUnion = ActionsUnion<typeof deliveriesActions>;

import { ActionsUnion } from '../../utils/action.helpers';
import { NavigatorActions } from './navigator/actions';
import { nhiActions } from './nhi/actions';

/**
 * Actions
 */
export const nhiLookupActions = {
    /**
     * Caseload
     */
    ...nhiActions,
    /**
     * Casenote
     */
    ...NavigatorActions
};

// we leverage TypeScript token merging, so our consumer can use `Actions` for both runtime and compile time types
export type nhiLookupActionsUnion = ActionsUnion<typeof nhiLookupActions>;

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State2ActionTypes {
    FetchItem = 'State2/fetchItem',
    SetState2Item = 'State2/setState2Item',
    SetState2_2Item = 'State2/setState2_2Item',
    SetState2_21Item = 'State2/setState2_21Item',
    SetState2_22Item = 'State2/setState2_22Item',
    ClearState2Item = 'State2/clearState2Item',
    ClearState2_2Item = 'State2/clearState2_2Item',
    ClearState2_21Item = 'State2/clearState2_21Item',
    ClearState2_22Item = 'State2/clearState2_22Item',
    StoreItem = 'State2/storeItem',
    SetState2Image = 'State2/setState2Image',
    SetState2_2Image = 'State2/setState2_2Image',
    SetState2_21Image = 'State2/setState2_21Image',
    SetState2_22Image = 'State2/setState2_22Image',
    ClearState2Image = 'State2/clearState2Image',
    ClearState2_2Image = 'State2/clearState2_2Image',
    ClearState2_21Image = 'State2/clearState2_21Image',
    ClearState2_22Image = 'State2/clearState2_22Image'
}
/**
 * Actions
 */
export const State2Actions = {
    setState2Image: (Img): ActionWithPayload<State2ActionTypes.SetState2Image, { Img }> =>
        createAction(State2ActionTypes.SetState2Image, { Img }),
    setState2_2Image: (Img): ActionWithPayload<State2ActionTypes.SetState2_2Image, { Img }> =>
        createAction(State2ActionTypes.SetState2_2Image, { Img }),
    setState2_21Image: (Img): ActionWithPayload<State2ActionTypes.SetState2_21Image, { Img }> =>
        createAction(State2ActionTypes.SetState2_21Image, { Img }),
    setState2_22Image: (Img): ActionWithPayload<State2ActionTypes.SetState2_22Image, { Img }> =>
        createAction(State2ActionTypes.SetState2_22Image, { Img }),
    setState2Item: (item): ActionWithPayload<State2ActionTypes.SetState2Item, { item: CONTRACTS.Item }> =>
        createAction(State2ActionTypes.SetState2Item, { item }),
    setState2_2Item: (item): ActionWithPayload<State2ActionTypes.SetState2_2Item, { item: CONTRACTS.Wairua }> =>
        createAction(State2ActionTypes.SetState2_2Item, { item }),
    setState2_21Item: (item): ActionWithPayload<State2ActionTypes.SetState2_21Item, { item: CONTRACTS.Hinengaro }> =>
        createAction(State2ActionTypes.SetState2_21Item, { item }),
    setState2_22Item: (item): ActionWithPayload<State2ActionTypes.SetState2_22Item, { item: CONTRACTS.Tinana }> =>
        createAction(State2ActionTypes.SetState2_22Item, { item }),
    clearState2Image: (): Action<State2ActionTypes.ClearState2Image> =>
        createAction(State2ActionTypes.ClearState2Image),
    clearState2_2Image: (): Action<State2ActionTypes.ClearState2_2Image> =>
        createAction(State2ActionTypes.ClearState2_2Image),
    clearState2_21Image: (): Action<State2ActionTypes.ClearState2_21Image> =>
        createAction(State2ActionTypes.ClearState2_21Image),
    clearState2_22Image: (): Action<State2ActionTypes.ClearState2_22Image> =>
        createAction(State2ActionTypes.ClearState2_22Image),
    clearState2Item: (): Action<State2ActionTypes.ClearState2Item> => createAction(State2ActionTypes.ClearState2Item),
    clearState2_2Item: (): Action<State2ActionTypes.ClearState2_2Item> =>
        createAction(State2ActionTypes.ClearState2_2Item),
    clearState2_21Item: (): Action<State2ActionTypes.ClearState2_21Item> =>
        createAction(State2ActionTypes.ClearState2_21Item),
    clearState2_22Item: (): Action<State2ActionTypes.ClearState2_22Item> =>
        createAction(State2ActionTypes.ClearState2_22Item),
    storeItem: (item): ActionWithPayload<State2ActionTypes.StoreItem, CONTRACTS.Item> =>
        createAction(State2ActionTypes.StoreItem, item)
};

/**
 * Creates an object with the same keys as the given object and values generated by running each of the original values
 * through the given transformer function.
 */
export const mapValues = (
    object: Record<string, unknown>,
    valueTransformer: (value: any) => any
): Record<string, unknown> => {
    return Object.entries(object).reduce((acc, [key, value]) => {
        acc[key] = valueTransformer(value);
        return acc;
    }, {});
};

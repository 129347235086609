import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import * as CONTRACTS from '../deliveries.contracts';

// /api/deliveries/kaimahi?IncludeArchived=true&IncludeDeleted=true for fetching archived kaimahis
export const fetchKaimahiList = (
    filters: Partial<CONTRACTS.AllowedDeliveriesKaimahiFilters>,
    client: AxiosInstance
): any =>
    client
        .get(`/deliveries/kaimahi?IncludeDeleted=true&${objectToQuerystring(filters)}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesKaimahi[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const fetchKaimahi = (id: string | number, client: AxiosInstance): any =>
    client
        .get(`/deliveries/kaimahi/${id}`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesKaimahi>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const FetchKaimahiAsUser = (client: AxiosInstance): any =>
    client
        .get(`/deliveries/kaimahi/fromtoken`)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesKaimahi>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });

export const storeKaimahi = (item: Partial<CONTRACTS.DeliveriesKaimahi>, client: AxiosInstance): any => {
    if (item.id) {
        return client
            .put(`/deliveries/kaimahi/${item.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesKaimahi>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/kaimahi`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesKaimahi>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const storeDefaultInstance = (
    id: string | number,
    item: Partial<CONTRACTS.DeliveriesInstance>,
    client: AxiosInstance
): any => {
    return client
        .post(`/deliveries/navigatorInstance/kaimahi/${id}`, item)
        .then((response: AxiosResponse<CONTRACTS.DeliveriesInstance>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });
};

export const deleteKaimahi = (id: string | number, client: AxiosInstance): any =>
    client.delete(`/deliveries/kaimahi/${id}`).catch(error => {
        throw error.response.data || error.response;
    });

import { setItemId } from '@whanau/domains';

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum NavigatorActionTypes {
    FetchNavigatorList = 'Navigator/fetchNavigatorList',
    SetNavigatorList = 'Navigator/setNavigatorList',
    ClearNavigatorList = 'Navigator/clearNavigatorList',
    UpdateNavigatorList = 'Navigator/updateNavigatorList',
    FetchNavigatorbyNHI = 'Navigator/fetchNavigatorbyNHI',
    StoreNavigator = 'Navigator/storeNavigator',
    SetNavigator = 'Navigator/setNavigator',
    SearchNavigator = 'Navigator/searchNavigator',
    SetNavigatorFilters = 'Navigator/setNavigatorFilters',
    StoreNavigatorFilters = 'Navigator/storeNavigatorFilters',
    SetTotalInNavigatorFilters = 'Navigator/setTotalInNavigatorFilters',
    ClearNavigatorFilters = 'Navigator/clearNavigatorFilters',
    SetItemId = 'Navigator/setItemId',
    ClearItemId = 'Navigator/clearItemId',
    SetSuccess = 'Navigator/setSuccess',
    SetData = 'Navigator/setData',
    SetNavigatorInstanceList = 'Navigator/setNavigatorInstanceList',
    GetNavigatorInstanceList = 'Navigator/getNavigatorInstanceList',
    SetNavigatorInstance = 'Navigator/setNavigatorInstance',
    SetShowModalForInstance = 'Navigator/setShowModalForInstance'
}

/**
 * Actions
 */
export const NavigatorActions = {
    // retrieve Navigator items
    fetchNavigatorList: (): Action<NavigatorActionTypes.FetchNavigatorList> =>
        createAction(NavigatorActionTypes.FetchNavigatorList),

    // populate Navigator items
    setNavigatorList: (
        list
    ): ActionWithPayload<NavigatorActionTypes.SetNavigatorList, { list: CONTRACTS.navigator[] }> =>
        createAction(NavigatorActionTypes.SetNavigatorList, { list }),

    // Update list after create and update
    updateNavigatorList: (item): ActionWithPayload<NavigatorActionTypes.UpdateNavigatorList, CONTRACTS.navigator> =>
        createAction(NavigatorActionTypes.UpdateNavigatorList, item),

    // retrieve Navigator item
    fetchNavigatorbyNHI: (id): any => createAction(NavigatorActionTypes.FetchNavigatorbyNHI, id),

    searchNavigator: (
        item
    ): ActionWithPayload<NavigatorActionTypes.SearchNavigator, { item: CONTRACTS.navigatorSearchParameters }> =>
        createAction(NavigatorActionTypes.SearchNavigator, item),

    // populate Navigator item
    setNavigator: (item): ActionWithPayload<NavigatorActionTypes.SetNavigator, { item: CONTRACTS.navigator }> =>
        createAction(NavigatorActionTypes.SetNavigator, { item }),
    setData: (data: boolean): ActionWithPayload<NavigatorActionTypes.SetData, { data: boolean }> =>
        createAction(NavigatorActionTypes.SetData, { data }),

    storeNavigator: (item): ActionWithPayload<NavigatorActionTypes.StoreNavigator, { item: CONTRACTS.navigator }> =>
        createAction(NavigatorActionTypes.StoreNavigator, item),

    // clear Navigator list
    clearNavigatorList: (): Action<NavigatorActionTypes.ClearNavigatorList> =>
        createAction(NavigatorActionTypes.ClearNavigatorList),

    // populate Navigator filters
    setNavigatorFilters: (
        filters: Partial<CONTRACTS.navigatorFilters>
    ): ActionWithPayload<NavigatorActionTypes.SetNavigatorFilters, CONTRACTS.navigatorFilters> =>
        createAction(NavigatorActionTypes.SetNavigatorFilters, filters),

    storeNavigatorFilters: (
        filters: Partial<CONTRACTS.navigatorFilters>
    ): ActionWithPayload<NavigatorActionTypes.StoreNavigatorFilters, CONTRACTS.navigatorFilters> =>
        createAction(NavigatorActionTypes.StoreNavigatorFilters, filters),

    setTotalInNavigatorFilters: (
        total: number
    ): ActionWithPayload<NavigatorActionTypes.SetTotalInNavigatorFilters, number> =>
        createAction(NavigatorActionTypes.SetTotalInNavigatorFilters, total),
    setSuccess: (success: boolean): ActionWithPayload<NavigatorActionTypes.SetSuccess, { success: boolean }> =>
        createAction(NavigatorActionTypes.SetSuccess, { success }),
    setShowModalForInstance: (
        showModalForInstance: boolean
    ): ActionWithPayload<NavigatorActionTypes.SetShowModalForInstance, { showModalForInstance: boolean }> =>
        createAction(NavigatorActionTypes.SetShowModalForInstance, { showModalForInstance }),
    // clear Navigator filters
    clearNavigatorFilters: (): Action<NavigatorActionTypes.ClearNavigatorFilters> =>
        createAction(NavigatorActionTypes.ClearNavigatorFilters),
    setItemId: (id: string): ActionWithPayload<NavigatorActionTypes.SetItemId, { id: string }> =>
        createAction(NavigatorActionTypes.SetItemId, { id }),
    clearItemId: (): Action<NavigatorActionTypes.ClearItemId> => createAction(NavigatorActionTypes.ClearItemId),
    setNavigatorInstanceList: (list): ActionWithPayload<NavigatorActionTypes.SetNavigatorInstanceList, { list: [] }> =>
        createAction(NavigatorActionTypes.SetNavigatorInstanceList, { list }),
    setNavigatorInstance: (item): ActionWithPayload<NavigatorActionTypes.SetNavigatorInstance, { item }> =>
        createAction(NavigatorActionTypes.SetNavigatorInstance, { item }),
    getNavigatorInstanceList: (list): ActionWithPayload<NavigatorActionTypes.GetNavigatorInstanceList, { list: [] }> =>
        createAction(NavigatorActionTypes.GetNavigatorInstanceList, { list })
};

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State2ActionTypes {
    FetchItem = 'State2/fetchItem',
    SetState2NTWItem = 'State2/setState2NTWItem',

    ClearState2Item = 'State2/clearState2Item',

    StoreItem = 'State2/storeItem'
}
/**
 * Actions
 */
export const State2Actions = {
    setState2NTWItem: (item): ActionWithPayload<State2ActionTypes.SetState2NTWItem, { item: CONTRACTS.Item }> =>
        createAction(State2ActionTypes.SetState2NTWItem, { item }),

    clearState2Item: (): Action<State2ActionTypes.ClearState2Item> => createAction(State2ActionTypes.ClearState2Item),

    storeItem: (item): ActionWithPayload<State2ActionTypes.StoreItem, CONTRACTS.Item> =>
        createAction(State2ActionTypes.StoreItem, item)
};

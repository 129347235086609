import { all } from 'redux-saga/effects';

import { nhiLookupNavigatorSagas } from './navigator/saga';
import { nhiLookupNhiSagas } from './nhi/saga';

/**
 * Public Sagas
 */
export function* rootNhiLookupSaga(): any {
    yield all([...nhiLookupNhiSagas, ...nhiLookupNavigatorSagas]);
}

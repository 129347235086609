import { AxiosInstance, AxiosResponse } from 'axios';

import { objectToQuerystring } from '../../../utils/object-to-querystring';
import { AuthSelectors } from '../../_auth';
import * as CONTRACTS from '../deliveries.contracts';
import { fetchHouseholdList } from '../household/api';

export const storeBulkAssign = (item: Partial<CONTRACTS.ZoneBulkAssign>, client: AxiosInstance): any => {
    if (item.deliveryZone.id) {
        return client
            .put(`/deliveries/Actions/bulk-assign-zone/${item.deliveryZone.id}`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesHousehold>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    } else {
        return client
            .post(`/deliveries/Actions/bulk-assign-zone`, item)
            .then((response: AxiosResponse<CONTRACTS.DeliveriesHousehold>) => response.data)
            .catch(error => {
                throw error.response.data || error.response;
            });
    }
};

export const fetchRemoteSourceQueryList = (navigatorInstance: string, client: AxiosInstance): any => {
    return client
        .get(`/deliveries/actions/NavigatorQueries?limit=100`, {
            headers: { navigatorInstance: navigatorInstance }
        })
        .then((response: AxiosResponse<CONTRACTS.DeliveriesRemoteSourceQueries>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });
};

export const fetchContactsFromQuery = (savedQueryId: string, navigatorInstance: string, client: AxiosInstance): any => {
    return client
        .get(`/deliveries/actions/GetContactsFromQuery?savedQueryId=${savedQueryId}`, {
            headers: { navigatorInstance: navigatorInstance }
        })
        .then((response: AxiosResponse<CONTRACTS.DeliveriesContacts[]>) => response.data)
        .catch(error => {
            throw error.response.data || error.response;
        });
};

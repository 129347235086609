import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State1ActionTypes {
    FetchItem = 'State1/fetchItem',
    SetState1Item = 'State1/setState1Item',
    StoreItem = 'State1/storeItem',
    SetState1Image = 'State1/setState1Image',
    ClearState1Item = 'State1/clearState1Item',
    ClearState1Image = 'State1/clearState1Image'
}
/**
 * Actions
 */
export const State1Actions = {
    setState1Image: (Img): ActionWithPayload<State1ActionTypes.SetState1Image, { Img }> =>
        createAction(State1ActionTypes.SetState1Image, { Img }),
    setState1Item: (item): ActionWithPayload<State1ActionTypes.SetState1Item, { item: CONTRACTS.Item }> =>
        createAction(State1ActionTypes.SetState1Item, { item }),
    clearState1Image: (): Action<State1ActionTypes.ClearState1Image> =>
        createAction(State1ActionTypes.ClearState1Image),
    clearState1Item: (): Action<State1ActionTypes.ClearState1Item> => createAction(State1ActionTypes.ClearState1Item),
    storeItem: (item): ActionWithPayload<State1ActionTypes.StoreItem, CONTRACTS.Item> =>
        createAction(State1ActionTypes.StoreItem, item)
};

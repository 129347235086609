import * as CONTRACTS from './attachment.contracts';

export const getAttachment = (state: any): Partial<CONTRACTS.Attachment> => {
    return state.attachment.item || {};
};

export const getAttachmentList = (state: any): CONTRACTS.Attachment[] => {
    return state.attachment.list || [];
};

export const getAttachmentFilters = (state: any): Partial<CONTRACTS.AllowedAttachmentFilters> => {
    return state.attachment.filters || {};
};

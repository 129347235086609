import { ISagaModule } from 'redux-dynamic-modules-saga';

import { NTWReportState } from './NTWReport.contracts';
import { NTWReportReducer } from './NTWReport.reducer';
import { rootNTWReportSaga } from './NTWReport.saga';

// https://github.com/microsoft/redux-dynamic-modules/issues/36
export function getNTWReportModule(): [ISagaModule<NTWReportState>] {
    return [
        {
            // Unique id of the module
            id: 'NTWReport',
            // Maps the Store key to the reducer
            reducerMap: {
                NTWReport: NTWReportReducer
            } as any,
            // Initial actions array for this module
            initialActions: [],
            // Sagas for this module
            // if no sagas are used, omit the parameter and the function type can be simplified to IModule
            sagas: [rootNTWReportSaga]
        }
    ];
}

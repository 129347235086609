import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import * as CONTRACTS from '../deliveries.contracts';

/**
 * Actions Types
 */
export enum DeliveriesZoneActionTypes {
    FetchZoneList = 'deleveries/fetchZoneList',
    SetZoneList = 'deleveries/setZoneList',
    ClearZoneList = 'deleveries/clearZoneList',
    UpdateZoneList = 'deliveries/updateZoneList',
    FetchZone = 'deleveries/fetchZone',
    SetZone = 'deleveries/setZone',
    DeleteZone = 'deliveries/deleteZone',
    StoreZone = 'deliveries/storeZone',
    SetZoneFilters = 'deliveries/setZoneFilters',
    StoreZoneFilters = 'deliveries/storeZoneFilters',
    SetTotalInDeliveriesZoneFilters = 'deliveries/setTotalInDeliveriesZoneFilters',
    ClearZoneFilters = 'deliveries/clearZoneFilters',
    ClearZone = 'deliveries/clearZone',
    SetSubmitSuccess = 'deliveries/setZoneSubmitSuccess'
}

/**
 * Actions
 */
export const deliveriesZoneActions = {
    // retrieve Zone items
    fetchDeliveriesZoneList: (
        filter: CONTRACTS.AllowedDeliveriesZoneFilters
    ): Action<DeliveriesZoneActionTypes.FetchZoneList> => createAction(DeliveriesZoneActionTypes.FetchZoneList, filter),

    // populate Zone items
    setDeliveriesZoneList: (
        list
    ): ActionWithPayload<DeliveriesZoneActionTypes.SetZoneList, { list: CONTRACTS.DeliveriesZone[] }> =>
        createAction(DeliveriesZoneActionTypes.SetZoneList, { list }),

    // Update Zone list after create and update
    updateDeliveriesZoneList: (
        item
    ): ActionWithPayload<DeliveriesZoneActionTypes.UpdateZoneList, CONTRACTS.DeliveriesZone> =>
        createAction(DeliveriesZoneActionTypes.UpdateZoneList, item),

    // retrieve Zone item
    fetchDeliveriesZone: (id): any => createAction(DeliveriesZoneActionTypes.FetchZone, id),

    // populate Zone item
    setDeliveriesZone: (
        item
    ): ActionWithPayload<DeliveriesZoneActionTypes.SetZone, { item: CONTRACTS.DeliveriesZone }> =>
        createAction(DeliveriesZoneActionTypes.SetZone, { item }),

    // update Zone item
    storeDeliveriesZone: (
        item: CONTRACTS.DeliveriesZone
    ): ActionWithPayload<DeliveriesZoneActionTypes.StoreZone, CONTRACTS.DeliveriesZone> =>
        createAction(DeliveriesZoneActionTypes.StoreZone, item),

    setSubmitSuccess: (
        success: boolean
    ): ActionWithPayload<DeliveriesZoneActionTypes.SetSubmitSuccess, { success: boolean }> =>
        createAction(DeliveriesZoneActionTypes.SetSubmitSuccess, { success }),

    // clear Zone list
    clearDeliveriesZoneList: (): Action<DeliveriesZoneActionTypes.ClearZoneList> =>
        createAction(DeliveriesZoneActionTypes.ClearZoneList),

    // populate Zone filters
    setDeliveriesZoneFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters>
    ): ActionWithPayload<DeliveriesZoneActionTypes.SetZoneFilters, CONTRACTS.AllowedDeliveriesZoneFilters> =>
        createAction(DeliveriesZoneActionTypes.SetZoneFilters, filters),

    storeDeliveriesZoneFilters: (
        filters: Partial<CONTRACTS.AllowedDeliveriesZoneFilters>
    ): ActionWithPayload<DeliveriesZoneActionTypes.StoreZoneFilters, CONTRACTS.AllowedDeliveriesZoneFilters> =>
        createAction(DeliveriesZoneActionTypes.StoreZoneFilters, filters),

    setTotalInDeliveriesZoneFilters: (
        total: number
    ): ActionWithPayload<DeliveriesZoneActionTypes.SetTotalInDeliveriesZoneFilters, number> =>
        createAction(DeliveriesZoneActionTypes.SetTotalInDeliveriesZoneFilters, total),

    // clear Zone filters
    clearDeliveriesZoneFilters: (): Action<DeliveriesZoneActionTypes.ClearZoneFilters> =>
        createAction(DeliveriesZoneActionTypes.ClearZoneFilters),

    clearZone: (): Action<DeliveriesZoneActionTypes.ClearZone> => createAction(DeliveriesZoneActionTypes.ClearZone),

    //delete Zone
    deleteDeliveriesZone: (id): any => createAction(DeliveriesZoneActionTypes.DeleteZone, id)
};

import * as CONTRACTS from './contracts';

export const getState5NTWItem = (state: any): any => {
    return state.NTWReport.State_5.item || {};
};
export const getState5_1NTWItem = (state: any): any => {
    return state.NTWReport.State_5.item1 || {};
};
export const getState5_2NTWItem = (state: any): any => {
    return state.NTWReport.State_5.item2 || {};
};
export const getState5_3NTWItem = (state: any): any => {
    return state.NTWReport.State_5.item3 || {};
};
// export const getState5Wairua = (state: any): Partial<CONTRACTS.Wairua> => {
//     return state.ChildHub.State_5.WairuaItem || {};
// };
// export const getState5Hinengaro = (state: any): Partial<CONTRACTS.Hinengaro> => {
//     return state.ChildHub.State_5.HinengaroItem || {};
// };
// export const getState5Tinana = (state: any): Partial<CONTRACTS.Tinana> => {
//     return state.ChildHub.State_5.TinanaItem || {};
// };
// export const getState5Img = (state: any): CONTRACTS.Img => {
//     return state.ChildHub.State_5.Img || {};
// };

import * as CONTRACTS from '../deliveries.contracts';

export const getDeliveriesDelivery = (state: any): Partial<CONTRACTS.DeliveriesDelivery> => {
    return state.deliveries.delivery.item || {};
};

export const getDeliveriesDeliveryList = (state: any): CONTRACTS.DeliveriesDeliverySummary[] => {
    return state.deliveries.delivery.list || [];
};

export const getDeliveriesDeliveryFilters = (state: any): Partial<CONTRACTS.AllowedDeliveriesDeliveryFilters> => {
    return state.deliveries.delivery.filters || {};
};

export const getDeliveriesDeliverySuccess = (state: any): boolean => {
    return state.deliveries.delivery.success || false;
};

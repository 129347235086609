/**
 * Delivery DTO
 *
 * API Endpoints:
 *
 * GET    /delivery       returns an array of Delivery
 *                        will accept querystring to filter by: kaimahiId, providerId and a freeform of (firstname, lastname, city, suburb, street)
 * GET   /delivery/:id    returns Delivery record for a given id
 * PUT   /delivery/:id    updates a Delivery record for a given id, returns updated record
 * POST  /delivery        inserts a new Delivery record, return updated record and its assigned Id
 */
export interface Delivery {
    // identifiers
    id: string;
    systemId: string;

    // ownership
    providerId: string;
    zoneId: string;

    // main contact details
    firstName: string;
    lastName: string;
    birthDate: string; //date
    phone: string;
    email: string;
    instagram: string;
    facebook: string;

    // address
    street: string;
    suburb: string;
    city: string;
    postalCode: string;
    country: string;
    latitude: number; //double
    longitude: number; //double

    // household composition
    occupants: {
        infants: number;
        teens: number;
        adults: number;
        elderly: number;
    };

    // delivery details
    status: DeliveryStatus;
    notes: string;
    rapidResponse: boolean;
    imageUrl: any;
    deliveredKits: number;
    deliveredFoodbank: number;
    deliveredMedicine: number;
    deliveredVoucher: number;
    deliveredVisionWest: number;
    deliveredShopping: number;
    deliveredOn: string | Date; //datetime
    deliveredBy: string; // kaimahiId? duplicate?

    // admin
    createdOn: string | Date; //datetime
    createdBy: string; // kaimahiId or import source Id
}

export enum DeliveryStatus {
    pending = 'Pending',
    delivered = 'Delivered',
    cancelled = 'Cancelled'
}

export interface Provider {
    id: string;
    systemId: string;
    name: string;
}

/**
 * Kaimahi DTO
 *
 * API endpoints:
 *
 * GET    /kaimahi/:providerId    returns list of kaimahi belonging to one organisation
 */
export interface Kaimahi {
    id: string;
    kaimahiId: string;
    systemId: string;
    providerId: string;
    firstName: string;
    lastName: string;
    zoneIds: string[];
}

/**
 * Zone DTO
 *
 * API endpoints:
 *
 * GET    /zone/:providerId    returns list of zones belonging to one organisation
 */

export interface Zone {
    id: string;
    providerId: string;
    label: string;
}

export interface DeliveryState {
    list: Delivery[];
    item: Partial<Delivery>;
    filters: AllowedDeliveryFilters;
    zones: Zone[];
}

export interface AllowedDeliveryFilters {
    kaimahiId?: string;
    providerId?: string;
    zoneId?: string;
    keywords?: string;
    pending?: boolean;
}

export type DeliveryResponse = Delivery[];

import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State5ActionTypes {
    FetchItem = 'State5/fetchItem',
    SetState5NTWItem = 'State5/setState5NTWItem'
}
/**
 * Actions
 */
export const State5Actions = {
    setState5NTWItem: (item): ActionWithPayload<State5ActionTypes.SetState5NTWItem, { item }> =>
        createAction(State5ActionTypes.SetState5NTWItem, { item })
};

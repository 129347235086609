import { Action, ActionWithPayload, createAction } from '../../../utils/action.helpers';
import { storeItem } from '../../delivery/delivery.api';
import * as CONTRACTS from './contracts';

/**
 * Actions Types
 */
export enum State4ActionTypes {
    FetchItem = 'State4/fetchItem',
    SetState4Item = 'State4/setState4Item',
    SetState22ItemInState4 = 'State4/setState22ItemInState4',
    SetState221ItemInState4 = 'State4/setState221ItemInState4',
    SetState222ItemInState4 = 'State4/setState222ItemInState4',
    StoreReferralItem = 'State4/storeReferralItem',
    SetReferralItem = 'State4/setReferralItem',
    SetImage = 'State4/setImage',
    ClearState4Item = 'State4/clearState4Item',
    ClearReferralItem = 'State4/clearReferralItem',
    ClearImage = 'State4/clearImage'
}
/**
 * Actions
 */
export const State4Actions = {
    setImage: (Img): ActionWithPayload<State4ActionTypes.SetImage, { Img }> =>
        createAction(State4ActionTypes.SetImage, { Img }),
    setReferralItem: (item): any => createAction(State4ActionTypes.SetReferralItem, { item }),
    setState4Item: (item): ActionWithPayload<State4ActionTypes.SetState4Item, { item: CONTRACTS.Item }> =>
        createAction(State4ActionTypes.SetState4Item, { item }),
    setState22ItemInState4: (
        item
    ): ActionWithPayload<State4ActionTypes.SetState22ItemInState4, { item: CONTRACTS.Wairua }> =>
        createAction(State4ActionTypes.SetState22ItemInState4, { item }),
    setState221ItemInState4: (
        item
    ): ActionWithPayload<State4ActionTypes.SetState221ItemInState4, { item: CONTRACTS.Hinengaro }> =>
        createAction(State4ActionTypes.SetState221ItemInState4, { item }),
    setState222ItemInState4: (
        item
    ): ActionWithPayload<State4ActionTypes.SetState222ItemInState4, { item: CONTRACTS.Tinana }> =>
        createAction(State4ActionTypes.SetState222ItemInState4, { item }),
    clearImage: (): Action<State4ActionTypes.ClearImage> => createAction(State4ActionTypes.ClearImage),
    clearReferralItem: (): any => createAction(State4ActionTypes.ClearReferralItem),
    clearState4Item: (): Action<State4ActionTypes.ClearState4Item> => createAction(State4ActionTypes.ClearState4Item),
    storeReferralItem: (item): ActionWithPayload<State4ActionTypes.StoreReferralItem, CONTRACTS.Referral> =>
        createAction(State4ActionTypes.StoreReferralItem, item)
};

import produce from 'immer';

import { updateArrayObjectWithObject } from '../../utils/update-arrayObject-with-object';
import { getCaseloadFilters } from '../plan';
import { DeliveriesAttachmentActionTypes } from './attachment/actions';
import { DeliveriesActionsUnion } from './deliveries.actions';
import * as CONTRACTS from './deliveries.contracts';
import { DeliveriesDeliveryActionTypes } from './delivery/actions';
import { DeliveriesDeliveryBulkAssignActionTypes } from './deliveryBulkAssign/actions';
import { DeliveriesHouseholdActionTypes } from './household/actions';
import { DeliveriesInventoryActionTypes } from './inventory/actions';
import { DeliveriesKaimahiActionTypes } from './kaimahi/actions';
import { DeliveriesNavigatorInstanceActionTypes } from './navigatorInstance/actions';
import { DeliveriesProviderActionTypes } from './provider/actions';
import { DeliveriesDeliveryTypeActionTypes } from './types/actions';
import { DeliveriesZoneActionTypes } from './zone/actions';

/**
 * Deliveries Reducers
 */
export const INITIAL_DELIVERIES_PROPERTIES: any = {
    list: [],
    item: {},
    success: false,
    filters: { limit: 100, offset: 0 }
};

export const INITIAL_BULK_ASSIGN_PROPERTIES: CONTRACTS.DeliveriesDeliveryBulkAssignDTOVerbose = {
    deliveryType: null,
    numberOfItems: null,
    household: [],
    remoteSourceQueryList: [],
    deliveryTypeIds: [],
    kaimahi: null
};

export const DELIVERIES_REDUCER_DEFAULT: CONTRACTS.DeliveriesState = {
    household: { ...INITIAL_DELIVERIES_PROPERTIES, filters: { ...INITIAL_DELIVERIES_PROPERTIES.filters, queryId: '' } },
    householdFromQuery: {
        ...INITIAL_DELIVERIES_PROPERTIES,
        filters: { ...INITIAL_DELIVERIES_PROPERTIES.filters, queryId: '' }
    },
    delivery: INITIAL_DELIVERIES_PROPERTIES,
    deliveryType: INITIAL_DELIVERIES_PROPERTIES,
    zone: INITIAL_DELIVERIES_PROPERTIES,
    kaimahi: INITIAL_DELIVERIES_PROPERTIES,
    provider: INITIAL_DELIVERIES_PROPERTIES,
    attachment: INITIAL_DELIVERIES_PROPERTIES,
    deliveryBulkAssign: INITIAL_BULK_ASSIGN_PROPERTIES,
    inventory: INITIAL_DELIVERIES_PROPERTIES,
    navigatorInstance: INITIAL_DELIVERIES_PROPERTIES,
    kaimahiNavigatorInstance: INITIAL_DELIVERIES_PROPERTIES,
    providerNavigatorInstance: INITIAL_DELIVERIES_PROPERTIES
};

export const deliveriesReducer = (
    state: CONTRACTS.DeliveriesState,
    action: DeliveriesActionsUnion
): CONTRACTS.DeliveriesState =>
    produce(state || DELIVERIES_REDUCER_DEFAULT, draft => {
        switch (action.type) {
            //Household
            case DeliveriesHouseholdActionTypes.SetHouseholdList:
                draft.household.list = action.payload.list;
                break;
            case DeliveriesHouseholdActionTypes.ClearHouseholdList:
                draft.household.list = [];
                break;
            case DeliveriesHouseholdActionTypes.UpdateHouseholdList:
                draft.household.list = updateArrayObjectWithObject(draft.household.list, action.payload) as any;
                break;
            case DeliveriesHouseholdActionTypes.SetHousehold:
                draft.household.item = action.payload.item;
                break;
            case DeliveriesHouseholdActionTypes.SetSubmitSuccess:
                draft.household.success = action.payload.success;
                break;

            case DeliveriesHouseholdActionTypes.StoreHouseholdFilters:
                // clean null values
                // Object.keys(action.payload.filters).forEach(
                //     key => action.payload.filters[key] == null && delete action.payload.filters[key]
                // );
                draft.household.filters = action.payload;
                break;
            case DeliveriesHouseholdActionTypes.SetTotalInDeliveriesHouseholdFilters:
                draft.household.filters = { ...draft.household.filters, total: action.payload };
                break;
            case DeliveriesHouseholdActionTypes.ClearHouseholdFilters:
                draft.household.filters = {};
                break;
            case DeliveriesHouseholdActionTypes.ClearHouseholdItem:
                draft.household.item = {};
                break;

            //Delivery
            case DeliveriesDeliveryActionTypes.SetDeliveryList:
                draft.delivery.list = action.payload.list;
                break;
            case DeliveriesDeliveryActionTypes.ClearDeliveryList:
                draft.delivery.list = [];
                break;
            case DeliveriesDeliveryActionTypes.UpdateDeliveryList:
                draft.delivery.list = updateArrayObjectWithObject(draft.delivery.list, action.payload) as any;
                break;
            case DeliveriesDeliveryActionTypes.SetDelivery:
                draft.delivery.item = action.payload.item;
                break;
            case DeliveriesDeliveryActionTypes.StoreDeliveryFilters:
                draft.delivery.filters = action.payload;
                break;
            case DeliveriesDeliveryActionTypes.SetTotalInDeliveriesDeliveryFilters:
                draft.delivery.filters = { ...draft.delivery.filters, total: action.payload };
                break;
            case DeliveriesDeliveryActionTypes.ClearDeliveryFilters:
                draft.delivery.filters = {};
                break;
            case DeliveriesDeliveryActionTypes.SetSubmitSuccess:
                draft.delivery.success = action.payload.success;
                break;

            //DeliveryType
            case DeliveriesDeliveryTypeActionTypes.SetDeliveryTypeList:
                draft.deliveryType.list = action.payload.list;
                break;
            case DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeList:
                draft.deliveryType.list = [];
                break;
            case DeliveriesDeliveryTypeActionTypes.UpdateDeliveryTypeList:
                draft.deliveryType.list = updateArrayObjectWithObject(draft.deliveryType.list, action.payload) as any;
                break;
            case DeliveriesDeliveryTypeActionTypes.SetDeliveryType:
                draft.deliveryType.item = action.payload.item;
                break;
            case DeliveriesDeliveryTypeActionTypes.StoreDeliveryTypeFilters:
                draft.deliveryType.filters = action.payload;
                break;
            case DeliveriesDeliveryTypeActionTypes.SetSubmitSuccess:
                draft.deliveryType.success = action.payload.success;
                break;
            case DeliveriesDeliveryTypeActionTypes.SetTotalInDeliveriesDeliveryTypeFilters:
                draft.deliveryType.filters = { ...draft.deliveryType.filters, total: action.payload };
                break;
            case DeliveriesDeliveryTypeActionTypes.ClearDeliveryTypeFilters:
                draft.deliveryType.filters = {};
                break;

            //Zone
            case DeliveriesZoneActionTypes.SetZoneList:
                draft.zone.list = action.payload.list;
                break;
            case DeliveriesZoneActionTypes.ClearZoneList:
                draft.zone.list = [];
                break;
            case DeliveriesZoneActionTypes.UpdateZoneList:
                draft.zone.list = updateArrayObjectWithObject(draft.zone.list, action.payload) as any;
                break;
            case DeliveriesZoneActionTypes.SetZone:
                draft.zone.item = action.payload.item;
                break;
            case DeliveriesZoneActionTypes.StoreZoneFilters:
                draft.zone.filters = action.payload;
                break;
            case DeliveriesZoneActionTypes.SetSubmitSuccess:
                draft.zone.success = action.payload.success;
                break;
            case DeliveriesZoneActionTypes.SetTotalInDeliveriesZoneFilters:
                draft.zone.filters = { ...draft.zone.filters, total: action.payload };
                break;
            case DeliveriesZoneActionTypes.ClearZoneFilters:
                draft.zone.filters = {};
                break;
            case DeliveriesZoneActionTypes.ClearZone:
                draft.zone.item = {};
                break;

            //Kaimahi
            case DeliveriesKaimahiActionTypes.SetKaimahiList:
                draft.kaimahi.list = action.payload.list;
                break;
            case DeliveriesKaimahiActionTypes.ClearKaimahiList:
                draft.kaimahi.list = [];
                break;
            case DeliveriesKaimahiActionTypes.UpdateKaimahiList:
                draft.kaimahi.list = updateArrayObjectWithObject(draft.kaimahi.list, action.payload) as any;
                break;
            case DeliveriesKaimahiActionTypes.SetKaimahi:
                draft.kaimahi.item = action.payload.item;
                break;
            case DeliveriesKaimahiActionTypes.StoreKaimahiFilters:
                draft.kaimahi.filters = action.payload;
                break;
            case DeliveriesKaimahiActionTypes.SetSubmitSuccess:
                draft.kaimahi.success = action.payload.success;
                break;
            case DeliveriesKaimahiActionTypes.SetTotalInDeliveriesKaimahiFilters:
                draft.kaimahi.filters = { ...draft.kaimahi.filters, total: action.payload };
                break;
            case DeliveriesKaimahiActionTypes.ClearKaimahiFilters:
                draft.kaimahi.filters = {};
                break;
            case DeliveriesKaimahiActionTypes.StoreDefaultInstance:
                draft.kaimahi.item = action.payload;
                break;

            //Provider
            case DeliveriesProviderActionTypes.SetProviderList:
                draft.provider.list = action.payload.list;
                break;
            case DeliveriesProviderActionTypes.ClearProviderList:
                draft.provider.list = [];
                break;
            case DeliveriesProviderActionTypes.SetProvider:
                draft.provider.item = action.payload.item;
                break;
            case DeliveriesProviderActionTypes.StoreProviderFilters:
                draft.provider.filters = action.payload;
                break;
            case DeliveriesProviderActionTypes.SetSubmitSuccess:
                draft.provider.success = action.payload.success;
                break;
            case DeliveriesProviderActionTypes.ClearProviderFilters:
                draft.provider.filters = {};
                break;
            case DeliveriesProviderActionTypes.SetTotalInDeliveriesProviderFilters:
                draft.provider.filters = { ...draft.provider.filters, total: action.payload };
                break;
            case DeliveriesProviderActionTypes.SetDeliveriesInstanceListByProvider:
                draft.provider.item = { ...draft.provider.item, instances: action.payload };
                break;

            //Attachment
            case DeliveriesAttachmentActionTypes.SetAttachmentList:
                draft.attachment.list = action.payload.list;
                break;
            case DeliveriesAttachmentActionTypes.ClearAttachmentList:
                draft.attachment.list = [];
                break;
            case DeliveriesAttachmentActionTypes.SetAttachment:
                draft.attachment.item = action.payload.item;
                break;
            case DeliveriesAttachmentActionTypes.StoreAttachmentFilters:
                draft.attachment.filters = action.payload;
                break;
            case DeliveriesAttachmentActionTypes.SetSubmitSuccess:
                draft.attachment.success = action.payload.success;
                break;
            case DeliveriesAttachmentActionTypes.ClearAttachmentFilters:
                draft.attachment.filters = {};
                break;
            //Bulk Assign
            case DeliveriesDeliveryBulkAssignActionTypes.SetDeliveryTypeForBulkAssign:
                draft.deliveryBulkAssign.deliveryType = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetNoOfItemForBulkAssign:
                draft.deliveryBulkAssign.numberOfItems = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdForBulkAssign:
                draft.deliveryBulkAssign.household = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesKaimahiForBulkAssign:
                draft.deliveryBulkAssign.kaimahi = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetDeliveriesDeliveryForBulkAssign:
                draft.deliveryBulkAssign = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.UpdateDeliveryBulkAssign:
                draft.deliveryBulkAssign = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetRemoteSourceQueryList:
                draft.deliveryBulkAssign.remoteSourceQueryList = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignDeliveryTypes:
                draft.deliveryBulkAssign.deliveryType = [];
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignKaimahis:
                draft.deliveryBulkAssign.kaimahi = [];
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.ClearDeliveriesBulkAssignHouseholds:
                draft.deliveryBulkAssign.household = [];
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetHouseholdListfromQuery:
                draft.householdFromQuery.list = action.payload.list;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.StoreHouseholdFromQueryFilters:
                draft.householdFromQuery.filters = action.payload;
                break;
            case DeliveriesDeliveryBulkAssignActionTypes.SetTotalInDeliveriesHouseholdFromQueryFilters:
                draft.householdFromQuery.filters = { ...draft.household.filters, total: action.payload };
                break;

            //Inventory
            case DeliveriesInventoryActionTypes.SetInventoryList:
                draft.inventory.list = action.payload.list;
                break;
            case DeliveriesInventoryActionTypes.ClearInventoryList:
                draft.inventory.list = [];
                break;
            case DeliveriesInventoryActionTypes.UpdateInventoryList:
                draft.inventory.list = updateArrayObjectWithObject(draft.inventory.list, action.payload) as any;
                break;
            case DeliveriesInventoryActionTypes.SetInventory:
                draft.inventory.item = action.payload.item;
                break;
            case DeliveriesInventoryActionTypes.StoreInventoryFilters:
                draft.inventory.filters = action.payload;
                break;
            case DeliveriesInventoryActionTypes.SetSubmitSuccess:
                draft.inventory.success = action.payload.success;
                break;
            case DeliveriesInventoryActionTypes.SetTotalInDeliveriesInventoryFilters:
                draft.inventory.filters = { ...draft.inventory.filters, total: action.payload };
                break;
            case DeliveriesInventoryActionTypes.ClearInventoryFilters:
                draft.inventory.filters = {};
                break;

            //Navigator Instance
            case DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceList:
                draft.navigatorInstance.list = action.payload.list;
                break;
            case DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstanceFilters:
                draft.navigatorInstance.filters = action.payload;
                break;
            case DeliveriesNavigatorInstanceActionTypes.SetNavigatorInstance:
                draft.navigatorInstance.item = action.payload.item;
                break;
            case DeliveriesNavigatorInstanceActionTypes.SetTotalInDeliveriesNavigatorInstanceFilters:
                draft.navigatorInstance.filters = { ...draft.navigatorInstance.filters, total: action.payload };
                break;
            case DeliveriesNavigatorInstanceActionTypes.SetInstanceListForKaimahi:
                draft.kaimahiNavigatorInstance.list = action.payload.list;
                break;
            case DeliveriesNavigatorInstanceActionTypes.SetSubmitSuccess:
                draft.navigatorInstance.success = action.payload.success;
                break;

            default:
                return draft;
        }
    });
